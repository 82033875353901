import React, { useContext, useEffect, useRef, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { Button, message, Select, Tooltip } from 'antd';
import axios from 'axios';

const AccountingAccountSelectBank = (props) => {
  // THIS COMPONENT IS ONLY USED IN BANK TABLE and AccountingInboxTable. USE THE EQUIVALENT ONE FROM /accounting/AccountingAccountSelect.js
  const auth = useContext(authContext);
  const [value, setValue] = useState(props.record.account_id);
  const selectRef = useRef(null);

  // Determine confidence value
  let confidence = props.account_id_confidence;

  if (confidence === undefined || confidence === null) {
    confidence = 0;
  }

  // Generate a unique class name to avoid conflicts
  const selectClassName =
    'accounting-account-select-' + Math.random().toString(36).substr(2, 9);

  // Create styles based on confidence
  const styles = `
  .${selectClassName} .ant-select-selector {
    ${
      confidence == 1
        ? 'border-image: linear-gradient(to right, var(--green-dark), var(--green-light)) 1 !important; border-width: 2.2px !important;'
        : ''
    }
    ${
      confidence > 0 && confidence < 1
        ? `
      border-image: linear-gradient(
        to left,
        var(--yellow-dark) ${((1 - confidence) * 100).toFixed(0)}%,
        var(--green-light) ${((1 - confidence) * 100).toFixed(0)}%
      ) 1 !important;
      border-style: dashed !important;
      border-width: 1.8px !important;
    `
        : ''
    }
  }
`;

  const calculateDropdownWidth = () => {
    if (selectRef.current) {
      const selectWidth = selectRef.current.offsetWidth;
      return selectWidth * 1.5; // Set to 1.5 times the select width, for example
    }
    return 300; // Fallback width
  };

  useEffect(() => {
    setValue(props.record.account_id);
  }, [props.record]);

  const updateSotTxnAccount = (newValue) => {
    console.log('updateSotTxnAccount', newValue);
    axios({
      method: 'post',
      url: `${props.API_domain}updateSotTxnAccount`,
      auth: {
        username: auth.email, // Ensure 'auth' object is available in this context
        password: auth.token,
      },
      data: {
        sot_txn_id: props.record.id,
        new_account: newValue,
        create_flagged_comment: !!props.createFlaggedComment,
        admin: !!auth.adminToken,
        account_id_confidence: props.account_id_confidence,
      },
    })
      .then((response) => {
        console.log('updateSotTxnAccount response:', response);
        message.success('Cuenta contable actualizada');
        const relatedMovements =
          response.data.amount_of_related_movements_reclassified;
        if (relatedMovements > 0) {
          message.info(
            `Se sincronizó${
              relatedMovements > 1 ? 'n' : ''
            } ${relatedMovements} movimiento${
              relatedMovements > 1 ? 's' : ''
            } relacionado${relatedMovements > 1 ? 's.' : '.'}`
          );
        }
        const warning = response.data.warning;
        if (warning) {
          message.warning(warning);
        }
        if (props.onChange) {
          props.onChange(newValue, props.record);
        }
      })
      .catch((error) => {
        console.error('Error updating account:', error);
        message.error('Error al actualizar la cuenta contable');
      });
  };

  const handleChange = (newValue) => {
    console.log('handleChange', newValue);
    setValue(newValue);
    updateSotTxnAccount(newValue);
  };

  if (props.displayMode === 'text') {
    let accountName = '';

    if (props.accountOptions && Array.isArray(props.accountOptions)) {
      const account = props.accountOptions.find((acc) => acc.id === value);
      if (account) {
        accountName = account.name;
      }
    }

    return <span style={{ fontWeight: 'bold' }}>{accountName}</span>;
  }

  // Tooltip content based on confidence
  const tooltipContent = (() => {
    if (confidence == 1) return 'Confianza 100%';
    if (confidence > 0 && confidence < 1)
      return '🪄 Confianza ' + (confidence * 100).toFixed(0) + '%';
    return 'No se ha seleccionado una cuenta.';
  })();

  return (
    <>
      <style>{styles}</style>
      <div style={{ display: 'flex', width: '100%' }}>
        <Tooltip title={tooltipContent} placement='left'>
          <Select
            ref={selectRef}
            className={selectClassName}
            {...props} // Spread other props first
            onChange={handleChange} // Override onChange
            value={value} // Override value
            showSearch
            style={{ flex: props.showThumbUp ? '1' : '1 1 100%' }}
            placeholder='Elegir cuenta contable'
            optionFilterProp='children'
            allowClear={props.allowClear}
            dropdownStyle={{ width: `${calculateDropdownWidth()}px` }}
            dropdownMatchSelectWidth={false}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {props.accountOptions.map((account) => (
              <Select.Option key={account.id} value={account.id}>
                {account.name}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>
        {props.showThumbUp && (
          <Tooltip title='Subir confianza a 100%'>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleChange(value);
              }}
              style={{ marginLeft: 8 }}
            >
              💯
            </Button>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export { AccountingAccountSelectBank };
