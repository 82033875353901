import React, { useContext, useEffect, useState } from 'react';
import { Affix, Col, Layout, message, Row, Button } from 'antd';
import styled from 'styled-components/macro';
import { BankView } from './BankView.js';
import { authContext } from '../ProvideAuth.js';
import axios from 'axios';

const { Content } = Layout;

function MoneyMovementsView(props) {
  const [activeComponent, setActiveComponent] = useState('bank');
  const auth = useContext(authContext);
  const [clientId, setClientId] = useState(null);
  const [clientAccounts, setClientAccounts] = useState([]);

  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        // First, get the client info
        const clientInfoResponse = await axios({
          method: 'post',
          url: `${props.API_domain}getUserAccountingClientCompanyInfo`,
          auth: {
            username: auth.email,
            password: auth.token,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (clientInfoResponse.status === 200) {
          const data = clientInfoResponse.data;
          const accountingClientId = data.accounting_client_id;
          setClientId(accountingClientId);

          // Now fetch the accounts
          const accountsResponse = await axios({
            method: 'post',
            url: `${props.API_domain}getClientAccounts`,
            auth: {
              username: auth.email,
              password: auth.token,
            },
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              client_id: accountingClientId,
            },
          });

          if (accountsResponse.status === 200) {
            const accountsData = accountsResponse.data;
            setClientAccounts(accountsData.accounts);
          } else {
            console.error('Failed to fetch client accounts');
          }
        } else {
          console.error('Failed to fetch client info');
        }
      } catch (error) {
        console.error('Error fetching client info or accounts:', error);
      }
    };

    fetchClientInfo();
  }, [auth.email, auth.token, props.API_domain]);

  // Common props to pass to the BankView component
  const commonProps = {
    API_domain: props.API_domain,
    clientId: clientId,
    clientAccounts: clientAccounts,
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'bank':
        return <BankView {...commonProps} type_of_mm='bank' />;
      case 'card':
        return <BankView {...commonProps} type_of_mm='card' />;
      default:
        return <BankView {...commonProps} />;
    }
  };

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderRight: 'solid rgb(235,235,235) 1px',
      }}
    >
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('bank')}
                isActive={activeComponent === 'bank'}
              >
                🏦 Banco
              </NavButton>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('card')}
                isActive={activeComponent === 'card'}
              >
                💳 Tarjetas
              </NavButton>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {/* You can add other elements or controls here */}
            </Col>
          </Row>
        </AffixDiv>
      </Affix>

      <div>{renderComponent()}</div>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

// Styled button component to reflect the active state
const NavButton = styled(Button)`
  color: ${({ isActive }) =>
    isActive ? 'rgb(116, 116, 205)' : '#000'}; // Active button has blue color
  font-weight: ${({ isActive }) =>
    isActive ? '600' : 'normal'}; // Active button has bold text
  font-size: 16px;
`;

export { MoneyMovementsView };
