import React, { forwardRef } from 'react';
import { Table, Modal, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { FormattedUSD } from '../FormattedUSD';
import { PayrollSlip } from './PayrollSlip';

const ExistingPayrollTableTable = forwardRef(
  (
    {
      payrollData,
      isCondensed,
      handleRowClick,
      isModalVisible,
      handleModalClose,
      selectedEmployee,
      getSlipPDF,
      decimoTercerMesBoolean,
      logoSrc,
      payrollWithHoursBrokenDown,
    },
    ref
  ) => {
    console.log('payrollData', payrollData);
    const columns = [
      {
        dataIndex: 'index',
        key: 'index',
        align: 'right',
        render: (text) => <>{text}</>,
      },
      {
        title: <div>Colaborador</div>,
        dataIndex: 'name',
        key: 'name',
        align: 'right',
        render: (text, record) => (
          <>
            <div>
              <b>{record.name}</b>
            </div>
            <div>{record.gov_id}</div>
          </>
        ),
      },
      {
        title: <div>Salario Quincenal</div>,
        dataIndex: 'biweekly_salary',
        key: 'biweekly_salary',
        align: 'right',
        render: (text) => (
          <b>
            <div style={{ fontSize: 16 }}>
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            </div>
          </b>
        ),
      },
      {
        title: <div>Prima de Prod.</div>,
        dataIndex: 'benefit_production',
        key: 'benefit_production',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>Prima de Comb.</div>,
        dataIndex: 'benefit_fuel',
        key: 'benefit_fuel',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>Gastos de Rep.</div>,
        dataIndex: 'benefit_representation_expenses',
        key: 'benefit_representation_expenses',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      // {
      //   title: <div>Tiempo Extra</div>,
      //   dataIndex: 'overtime',
      //   key: 'overtime',
      //    align:'right',
      //   render: (text) => (
      //     <div style={{ fontSize: 16 }}>
      //       <FormattedUSD total={parseFloat(text).toFixed(2)} />
      //     </div>
      //   ),
      // },
      ...(payrollWithHoursBrokenDown
        ? [
            {
              title: 'Salario por Hora',
              dataIndex: 'salarioporhora',
              key: 'salarioporhora',
              align: 'right',
              editable: true,
              render: (text) => (
                <div style={{ fontSize: 16 }}>
                  <FormattedUSD total={parseFloat(text).toFixed(2)} />
                </div>
              ),
            },
            {
              title: 'Horas Extra Diurnas',
              dataIndex: 'horas_extradiurnas',
              key: 'horas_extradiurnas',
              align: 'right',
              editable: true,
            },
            {
              title: 'Pago Extra Diurnas',
              dataIndex: 'pago_extradiurnas',
              key: 'pago_extradiurnas',
              align: 'right',
              render: (text) => (
                <div style={{ fontSize: 16 }}>
                  <FormattedUSD total={parseFloat(text).toFixed(2)} />
                </div>
              ),
            },
            {
              title: 'Horas Extra Nocturnas',
              dataIndex: 'horas_extranocturnas',
              key: 'horas_extranocturnas',
              align: 'right',
              editable: true,
            },
            {
              title: 'Pago Extra Nocturnas',
              dataIndex: 'pago_extranocturnas',
              key: 'pago_extranocturnas',
              align: 'right',
              render: (text) => (
                <div style={{ fontSize: 16 }}>
                  <FormattedUSD total={parseFloat(text).toFixed(2)} />
                </div>
              ),
            },
            {
              title: 'Horas Domingos',
              dataIndex: 'horas_domingos',
              key: 'horas_domingos',
              align: 'right',
              editable: true,
            },
            {
              title: 'Pago Domingos',
              dataIndex: 'pago_domingos',
              key: 'pago_domingos',
              align: 'right',
              render: (text) => (
                <div style={{ fontSize: 16 }}>
                  <FormattedUSD total={parseFloat(text).toFixed(2)} />
                </div>
              ),
            },
            {
              title: 'Horas Día Libre',
              dataIndex: 'horas_dialibre',
              key: 'horas_dialibre',
              align: 'right',
              editable: true,
            },
            {
              title: 'Pago Día Libre',
              dataIndex: 'pago_dialibre',
              key: 'pago_dialibre',
              align: 'right',
              render: (text) => (
                <div style={{ fontSize: 16 }}>
                  <FormattedUSD total={parseFloat(text).toFixed(2)} />
                </div>
              ),
            },
            {
              title: 'Horas Recargo',
              dataIndex: 'horas_recargo',
              key: 'horas_recargo',
              align: 'right',
              editable: true,
            },
            {
              title: 'Pago Recargo',
              dataIndex: 'pago_recargo',
              key: 'pago_recargo',
              align: 'right',
              render: (text) => (
                <div style={{ fontSize: 16 }}>
                  <FormattedUSD total={parseFloat(text).toFixed(2)} />
                </div>
              ),
            },
            {
              title: 'Adicionales',
              dataIndex: 'propinas',
              key: 'propinas',
              align: 'right',
              render: (text) => (
                <div style={{ fontSize: 16 }}>
                  <FormattedUSD total={parseFloat(text).toFixed(2)} />
                </div>
              ),
            },
            {
              title: 'Total de Extras',
              dataIndex: 'pago_total_extras',
              key: 'pago_total_extras',
              align: 'right',
              render: (text) => (
                <div style={{ fontSize: 16 }}>
                  <FormattedUSD total={parseFloat(text).toFixed(2)} />
                </div>
              ),
            },
            {
              title: 'Horas no trabajadas',
              dataIndex: 'horas_no_trabajadas',
              key: 'horas_no_trabajadas',
              align: 'right',
              editable: true,
            },
          ]
        : [
            {
              title: 'Tiempo Extra',
              dataIndex: 'overtime',
              key: 'overtime',
              align: 'right',
              editable: true,
              render: (text) => (
                <div style={{ fontSize: 16 }}>
                  <FormattedUSD total={parseFloat(text).toFixed(2)} />
                </div>
              ),
            },
          ]),
      {
        title: <div>Total de Ingresos</div>,
        dataIndex: 'payroll_gross',
        key: 'payroll_gross',
        align: 'right',
        render: (text) => (
          <b style={{ color: 'green' }}>
            <div style={{ fontSize: 16 }}>
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            </div>
          </b>
        ),
      },
      {
        title: <div>Prima de Prod. sujeta a SS</div>,
        dataIndex: 'benefit_production_subject_to_social_security',
        key: 'benefit_production_subject_to_social_security',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>Prima de Comb. sujeta a SS</div>,
        dataIndex: 'benefit_fuel_subject_to_social_security',
        key: 'benefit_fuel_subject_to_social_security',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>Menos Ausencias</div>,
        dataIndex: 'deduction_attendance',
        key: 'deduction_attendance',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>Ingresos sujetos a SS</div>,
        dataIndex: 'payroll_subject_to_ss',
        key: 'payroll_subject_to_ss',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: (
          <div>
            {decimoTercerMesBoolean
              ? 'Seguro Social 7.25%'
              : 'Seguro Social 9.75%'}
          </div>
        ),
        dataIndex: 'deduction_ss',
        key: 'deduction_ss',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>Ingresos sujetos a SE</div>,
        dataIndex: 'payroll_subject_to_se',
        key: 'payroll_subject_to_se',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>Seguro Educativo 1.25%</div>,
        dataIndex: 'deduction_se',
        key: 'deduction_se',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>Descuento Directo</div>,
        dataIndex: 'deduction_direct_discount',
        key: 'deduction_direct_discount',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>ISR</div>,
        dataIndex: 'deduction_isr',
        key: 'deduction_isr',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>Descuento de Adelanto</div>,
        dataIndex: 'advance_deductions',
        key: 'advance_deductions',
        align: 'right',
        render: (text) => (
          <div style={{ fontSize: 16 }}>
            <FormattedUSD total={parseFloat(text).toFixed(2)} />
          </div>
        ),
      },
      {
        title: <div>Total de Deducciones</div>,
        key: 'deduction_total',
        align: 'right',
        render: (_, record) => (
          <b style={{ color: 'red' }}>
            <div style={{ fontSize: 16 }}>
              <FormattedUSD
                total={parseFloat(record.deduction_total).toFixed(2)}
              />
            </div>
          </b>
        ),
      },
      {
        title: <div>Salario Neto a Recibir</div>,
        dataIndex: 'payroll_net',
        key: 'payroll_net',
        align: 'right',
        render: (text) => (
          <b style={{ color: 'rgb(65,130,239' }}>
            <div style={{ fontSize: 16 }}>
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            </div>
          </b>
        ),
      },
      {
        title: <div>Notas</div>,
        dataIndex: 'notes',
        key: 'notes',
        align: 'right',
        render: (text) => <div style={{ fontSize: 16 }}>{text}</div>,
      },
    ];

    const filteredColumns = columns.filter((col) => {
      if (isCondensed) {
        return [
          'name',
          'biweekly_salary',
          'benefit_production',
          'benefit_fuel',
          'overtime',
          'pago_total_extras',
          'payroll_gross',
          'deduction_attendance',
          'advance_deductions',
          'deduction_total',
          'payroll_net',
          'notes',
        ].includes(col.key);
      }
      return true;
    });

    const mergedColumns = filteredColumns.map((col) => ({
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
      }),
    }));
    return (
      <>
        <Table
          ref={ref}
          columns={mergedColumns}
          dataSource={payrollData}
          rowKey='id'
          pagination={false}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
          footer={() => (
            <div style={{ fontSize: 20, textAlign: 'right' }}>
              Total Salario Neto a Pagar:
              <b style={{ color: 'rgb(65,130,239)' }}>
                {' '}
                <FormattedUSD
                  total={payrollData.reduce(
                    (sum, record) => sum + Number(record.payroll_net),
                    0
                  )}
                />
              </b>
            </div>
          )}
        />
        <Modal
          visible={isModalVisible}
          onOk={handleModalClose}
          onCancel={handleModalClose}
          width={1200}
          footer={
            getSlipPDF && [
              <Button
                key='back'
                onClick={() => getSlipPDF(selectedEmployee, false)}
              >
                <DownloadOutlined />
                Descargar comprobante
              </Button>,
            ]
          }
        >
          <PayrollSlip
            id='payrollSlip'
            selectedEmployee={selectedEmployee}
            logoSrc={logoSrc}
            payrollWithHoursBrokenDown={payrollWithHoursBrokenDown}
          />
        </Modal>
      </>
    );
  }
);

export { ExistingPayrollTableTable };
