import {
  CommentOutlined,
  FlagFilled,
  FlagOutlined,
  LoadingOutlined,
  SearchOutlined,
  CheckSquareOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Empty, Radio, Spin, message, Table, Tooltip } from 'antd';
import axios from 'axios';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { DescriptionSearchDropdown } from './DescriptionSearchDropdown.js';
import { authContext } from '../ProvideAuth.js';
import { get_moments_from_month_name } from '../utils.js';
import { FormattedUSD } from './FormattedUSD.js';
import { AccountingAccountSelectBank } from './AccountingAccountSelectBank.js';
import { convertToIntDateFormat } from '../utils.js';
import './styles.css';
import HeroAxios from '../helpers/HeroAxios.js';
import RelationshipModalV2 from './RelationshipModalV2';
import { sourceMapping } from '../utils.js';
import TransactionCreatorModal from './Accounting/TransactionCreatorModal';

const BankTable = (props) => {
  const [bankRows, setBankRows] = useState([]);
  const [flagging, setFlagging] = useState([]); // holds the key to the row that is flagging
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const auth = useContext(authContext);
  const [accountFilterOptions, setAccountFilterOptions] = useState([]);
  const [latestUpdate, setLatestUpdate] = useState(null);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);
  const [relationshipModalVisible, setRelationshipModalVisible] =
    useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState(null);
  const [selectedRecordForRelationship, setSelectedRecordForRelationship] =
    useState(null);

  const [isTransactionModalVisible, setIsTransactionModalVisible] =
    useState(false);
  const [selectedMovement, setSelectedMovement] = useState(null);

  const fetchAccountOptions = (selectedSot) => {
    props.setLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'getAccountsOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        selectedSot: selectedSot,
      },
    })
      .then((response) => {
        props.setAccountOptions(response.data);
        props.setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching accounting accounts:', error);
      });
  };

  const openRelationshipModal = (record, relationship) => {
    setSelectedRecordForRelationship(record);
    setSelectedRelationship(relationship);
    setRelationshipModalVisible(true);
  };

  useEffect(() => {
    props.setLoading(true);
    setBankRows([]);
    setLatestUpdate(null);

    const fetchBankRows = async () => {
      const sot_id = props.selectedSot
        ? props.selectedSot.sources_of_truth_id
        : null;
      // const month = 10;
      if (!sot_id) {
        props.setLoading(false);
        return;
      }

      try {
        const response = await axios({
          method: 'post',
          url: props.API_domain + 'getSotTransactions',
          data: { sot_id },
          auth: { username: auth.email, password: auth.token },
        });
        setLatestUpdate(response.data.latest_update);
        // Transform data to match table columns
        const transformedBankRows = response.data.sot_transactions_list.map(
          (item, index) => ({
            ...item,
            key: item.id,
            Descripcion: item.description,
            Fecha: item.txn_date,
            Date: new Date(item.txn_date),
            fechaFormatted: convertToIntDateFormat(item.txn_date),
            Credito: item.amount > 0 ? Math.abs(item.amount) : null,
            Debito: item.amount < 0 ? Math.abs(item.amount) : null,
            balance: item.extra_info.balance,
            sotTxnId: item.id,
            flagged: item.flagged,
            comments: item.comments,
            committed: item.committed,
            // flagged: Math.random() >= 0.5,
          })
        );

        transformedBankRows.sort((a, b) => {
          // First compare by Date
          const dateDifference = b.Date - a.Date;
          if (dateDifference !== 0) {
            return dateDifference;
          }

          // If the Dates are equal, compare by id
          return b.sotTxnId - a.sotTxnId; // Assuming larger ids should come first
        });
        setBankRows(transformedBankRows);
        props.setCurrentData(transformedBankRows);
      } catch (error) {
        console.error('Error fetching SOT data', error);
      }

      props.setLoading(false);
      if (props.selectedSot) {
        fetchAccountOptions(props.selectedSot);
      }
    };

    fetchBankRows();
  }, [props.selectedSot, props.uploadChanged, props.submittedToggle]);

  useEffect(() => {
    getAccountFilterOptions();
  }, [props.accountOptions, bankRows]);

  useEffect(() => {
    setSelectedRowKeys(props.selectedSotTxnsInMassEdit.map((x) => x.id));
  }, [props.selectedSotTxnsInMassEdit]);

  const getAccountFilterOptions = () => {
    const uniqueAccounts = new Map();
    uniqueAccounts.set(null, { text: 'Sin cuenta asignada', value: 'none' }); // Special option for unassigned

    const accountOptionsMap = new Map();
    props.accountOptions.forEach((option) => {
      accountOptionsMap.set(option.id, option);
    });

    bankRows.forEach((item) => {
      if (item.account_id && !uniqueAccounts.has(item.account_id)) {
        const accountOption = accountOptionsMap.get(item.account_id);

        if (accountOption) {
          uniqueAccounts.set(item.account_id, {
            text: accountOption.name,
            value: item.account_id.toString(),
          });
        }
      }
    });

    // Convert the map to an array and sort it alphabetically by the 'text' property
    const sortedAccounts = Array.from(uniqueAccounts.values())
      .filter((account) => account.value !== 'none') // Exclude the special 'none' option from sorting
      .sort((a, b) => a.text.localeCompare(b.text));

    // Add the special 'none' option at the beginning of the sorted array
    const noneOption = uniqueAccounts.get(null);
    if (noneOption) {
      sortedAccounts.unshift(noneOption);
    }

    setAccountFilterOptions(sortedAccounts);
  };

  const onAccountChange = (value, record) => {
    const updatedBankRows = bankRows.map((item) => {
      if (item.key === record.key) {
        return { ...item, account_id: value, account_id_confidence: 1.0 };
      }
      return item;
    });
    setBankRows(updatedBankRows);
  };

  const updateSotTxnFlagged = (record) => {
    setFlagging([...flagging, record.key]);
    axios({
      method: 'post',
      url: props.API_domain + 'flagSotTxn',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { sot_txn_id: record.sotTxnId },
    })
      .then((response) => {
        setFlagging(flagging.filter((key) => key !== record.key));
        // Update local state for the flagged status
        const updatedBankRows = bankRows.map((item) => {
          if (item.key === record.key) {
            return { ...item, flagged: !item.flagged };
          }
          return item;
        });
        setBankRows(updatedBankRows);
      })
      .catch((error) => {
        console.error('Error flagging transaction: ', error);
      });
  };

  const onSelectedRowsChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    props.setSelectedSotTxnsInMassEdit(selectedRows);
  };

  const rowSelectionConfig = auth.adminEmail
    ? {
        selectedRowKeys, // This now tracks and updates correctly
        onChange: onSelectedRowsChange,
      }
    : null; // or undefined

  const columns = [
    {
      title: 'Descripción',
      dataIndex: 'Descripcion',
      key: 'Descripcion',
      width: 420,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DescriptionSearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.Descripcion.toLowerCase().includes(value.toLowerCase()),
      render: (descripcion) => (
        <Tooltip placement='topLeft' title={descripcion}>
          {descripcion}
        </Tooltip>
      ),
    },
    {
      title: 'Credito',
      dataIndex: 'Credito',
      key: 'Credito',
      width: 100,
      showSorterTooltip: false,
      align: 'right',
      sorter: (a, b) => parseFloat(a.Credito || 0) - parseFloat(b.Credito || 0),
      render: (credito) =>
        credito && <FormattedUSD total={parseFloat(credito)} />,
    },
    {
      title: 'Debito',
      dataIndex: 'Debito',
      key: 'Debito',
      width: 100,
      showSorterTooltip: false,
      align: 'right',
      sorter: (a, b) => parseFloat(a.Debito || 0) - parseFloat(b.Debito || 0),
      render: (debito) => debito && <FormattedUSD total={parseFloat(debito)} />,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      width: 100,
      showSorterTooltip: false,
      align: 'right',
      sorter: (a, b) => parseFloat(a.balance || 0) - parseFloat(b.balance || 0),
      render: (balance) => <FormattedUSD total={parseFloat(balance)} />,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaFormatted',
      key: 'fechaFormatted',
      width: 120,
      sorter: (a, b) => new Date(a.txn_date) - new Date(b.txn_date),
      showSorterTooltip: false,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const current_date = new Date(record.Fecha);
        current_date.setHours(current_date.getHours() + 5);
        if (typeof value == 'string') {
          let filter_dates = get_moments_from_month_name(value);
          return (
            current_date >= filter_dates[0] && current_date <= filter_dates[1]
          );
        }
        return current_date >= value[0] && current_date <= value[1];
      },
    },
    {
      title: 'Cuenta Contable ',
      dataIndex: 'account_id',
      key: 'account_id',
      width: 160,
      filters: accountFilterOptions,
      onFilter: (value, record) => {
        return value === 'none'
          ? !record.account_id
          : record.account_id?.toString() === value;
      },
      render: (_, record) => {
        return (
          <div>
            <AccountingAccountSelectBank
              API_domain={props.API_domain}
              accountOptions={props.accountOptions}
              account_id_confidence={record.account_id_confidence}
              record={record}
              onChange={onAccountChange}
              allowClear={true}
              disabled={record.committed || !auth.adminEmail}
              showThumbUp={
                record.account_id_confidence < 1 &&
                record.account_id_confidence > 0 &&
                !record.committed
              }
            />
          </div>
        );
      },
    },
    {
      title: 'Relación',
      dataIndex: 'relationship',
      key: 'relationship',
      width: 200,
      render: (relationship, record) => {
        if (relationship && relationship.length > 0) {
          return relationship
            .filter(
              (rel) =>
                rel.type_of_mm !== 'entries' &&
                rel.movement_table_name !== 'entries'
            )
            .map((rel, index) => {
              const relatedMovementSource =
                rel.type_of_mm || rel.movement_table_name;
              const beautifulName =
                sourceMapping[relatedMovementSource] || relatedMovementSource;
              return (
                <span
                  key={index}
                  style={{
                    color: 'var(--purple-dark)',
                    cursor: 'pointer',
                    // display: 'block',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    transition: 'background-color 0.3s, color 0.3s',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openRelationshipModal(record, rel);
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = 'var(--purple-light)';
                    e.target.style.color = 'white';
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = 'transparent';
                    e.target.style.color = 'var(--purple-dark)';
                  }}
                >
                  {beautifulName}
                </span>
              );
            });
        } else {
          return ''; // Or display 'No relationship' if preferred
        }
      },
      filters: [
        ...Array.from(
          new Set(
            bankRows
              .filter(
                (bankRow) =>
                  bankRow.relationship &&
                  bankRow.relationship.length > 0 &&
                  bankRow.relationship.some(
                    (rel) =>
                      rel.type_of_mm !== 'entries' &&
                      rel.movement_table_name !== 'entries'
                  )
              )
              .flatMap((bankRow) =>
                bankRow.relationship
                  .filter(
                    (rel) =>
                      rel.type_of_mm !== 'entries' &&
                      rel.movement_table_name !== 'entries'
                  )
                  .map((rel) => rel.type_of_mm || rel.movement_table_name)
              )
          )
        ).map((type) => {
          const beautifulName = sourceMapping[type] || type;
          return { text: beautifulName, value: type };
        }),
        { text: 'Sin Relación', value: 'no_relationship' }, // Beautiful name for no relationship
      ],
      onFilter: (value, record) => {
        if (value === 'no_relationship') {
          return !record.relationship || record.relationship.length === 0;
        }
        const relationship = record.relationship;
        if (relationship && relationship.length > 0) {
          return relationship.some(
            (rel) =>
              rel.type_of_mm === value || rel.movement_table_name === value
          );
        } else {
          return false;
        }
      },
    },
    ...(auth.adminEmail
      ? [
          {
            title: '🦸‍♂️',
            dataIndex: 'flagged',
            key: 'flagged',
            width: 80,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Radio.Group
                  onChange={(e) => {
                    setSelectedKeys(
                      e.target.value !== undefined ? [e.target.value] : []
                    );
                    confirm();
                  }}
                  value={selectedKeys[0]}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value={true}>
                    <FlagFilled />
                  </Radio>
                  <Radio value={false}>
                    <FlagOutlined />
                  </Radio>
                </Radio.Group>
                <Button
                  onClick={() => {
                    clearFilters();
                    confirm();
                  }}
                  size='small'
                  style={{ width: 90, marginTop: 8 }}
                >
                  Reset
                </Button>
              </div>
            ),
            filterIcon: (filtered) => (
              <FlagOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            onFilter: (value, record) => {
              if (value === true) {
                return record.flagged === true;
              } else if (value === false) {
                return record.flagged === false;
              }
              return false;
            },
            render: (_, record) =>
              record.account_id && (
                <>
                  {flagging.includes(record.key) ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          spin
                          style={{ color: 'black', marginLeft: 12 }}
                        />
                      }
                    />
                  ) : (
                    <Tooltip
                      placement='left'
                      title={
                        record.flagged ? 'Quitar duda' : 'Marcar como duda'
                      }
                    >
                      <Button
                        className='icon-button'
                        onClick={() => updateSotTxnFlagged(record)}
                        style={{ marginLeft: 5 }}
                      >
                        <>
                          {record.flagged ? <FlagFilled /> : <FlagOutlined />}{' '}
                          {record.comments && (
                            <Tooltip title={record.comments}>
                              <CommentOutlined />
                            </Tooltip>
                          )}
                        </>
                      </Button>
                    </Tooltip>
                  )}
                </>
              ),
          },
          {
            title: '🦸‍♂️',
            dataIndex: 'committed',
            key: 'committed',
            width: 80,
            filters: [
              { text: 'Committed', value: true },
              { text: 'Not Committed', value: false },
            ],
            filterMultiple: false,
            filterIcon: (filtered) => (
              <CheckSquareOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            onFilter: (value, record) => record.committed === value,
            render: (committed, record) => (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {committed ? (
                  <span
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents row click event
                      openTransactionModal(record);
                    }}
                    style={{
                      color: 'var(--purple-dark)',
                      cursor: 'pointer',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      transition: 'background-color 0.3s, color 0.3s',
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = 'var(--purple-light)';
                      e.target.style.color = 'white';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = 'transparent';
                      e.target.style.color = 'var(--purple-dark)';
                    }}
                  >
                    {'✅'}
                  </span>
                ) : (
                  ''
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    props.setCurrentData(extra['currentDataSource']);
  };

  const onRefresh = async () => {
    setIsUpdateDisabled(true);
    try {
      // Call the backend endpoint to refresh data
      await HeroAxios.post('runBankRefresh', {
        sot_id: props.selectedSot.sources_of_truth_id,
      }); // Replace with your actual endpoint
      message.success('Estamos actualizando tu banco');
      setIsUpdateDisabled(false);
      // Fetch the updated data
    } catch (error) {
      message.error('No tenemos integración con esta cuenta de banco');
      setIsUpdateDisabled(false);
    }
  };

  const openTransactionModal = (record) => {
    console.log('record', record);
    setSelectedMovement({
      movement_table_name: 'sot_transactions',
      movement_id: record.id,
    });
    setIsTransactionModalVisible(true);
  };

  return (
    <div>
      {latestUpdate ? (
        <div
          style={{
            color: 'var(--grey-dark)',
            marginLeft: 8,
            paddingTop: 16,
            textAlign: 'left',
          }}
        >
          <SyncOutlined
            style={{
              fontSize: '12px',
              cursor: isUpdateDisabled ? 'not-allowed' : 'pointer',
              marginRight: '4px',
              opacity: isUpdateDisabled ? 0.5 : 1,
              animation: isUpdateDisabled ? 'spin 1s linear infinite' : 'none',
            }}
            onClick={() => {
              if (!isUpdateDisabled) {
                onRefresh();
              }
            }}
            title={
              isUpdateDisabled
                ? 'Actualizando...'
                : 'Actualizar datos del banco'
            }
          />
          Actualización más reciente: {latestUpdate}
        </div>
      ) : null}
      <Table
        rowSelection={rowSelectionConfig} // Conditionally pass rowSelection
        dataSource={bankRows}
        columns={columns}
        pagination={{ defaultPageSize: 100 }}
        style={{ width: '100%' }}
        loading={props.loading}
        onChange={onChange}
        onRow={(record) => ({
          onClick: () => {
            console.log('record', record);
          },
        })}
        locale={{
          emptyText: (
            <Empty
              style={{ paddingTop: '20vh', paddingBottom: '20vh' }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <div>Selecciona un banco para ver sus transacciones</div>
                </>
              }
            />
          ),
        }}
      />
      {relationshipModalVisible && (
        <RelationshipModalV2
          visible={relationshipModalVisible}
          onCancel={() => setRelationshipModalVisible(false)}
          relationship={selectedRelationship}
          setRecords={setBankRows}
          selectedRecordForRelationship={selectedRecordForRelationship}
          setRelationshipModalVisible={setRelationshipModalVisible}
          API_domain={props.API_domain}
          accounting_clients_id={props.selectedSot?.accounting_client_id}
          tableOG='sot_transactions'
        />
      )}
      <TransactionCreatorModal
        isVisible={isTransactionModalVisible}
        onCancel={() => setIsTransactionModalVisible(false)}
        prepareTransactionEndpoint={'getTransactionDataForMovement'}
        transactionCreationEndpoint={'editTransaction'}
        initialPayload={{
          movement_table_name: selectedMovement?.movement_table_name,
          movement_id: selectedMovement?.movement_id,
        }}
        API_domain={props.API_domain}
        auth={auth}
        clientId={props.selectedSot?.accounting_client_id}
        onTransactionCreated={() => {
          message.success('Transacción editada exitosamente');
        }}
      />
    </div>
  );
};

export { BankTable };
