// MassEditButtonAndModalForFEs.js

import React, { useContext, useState } from 'react';
import { Button, Form, message, Modal, Select } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import AccountingAccountSelect from './Accounting/AccountingAccountSelect.js';

function MassEditButtonAndModalForFEs(props) {
  const auth = useContext(authContext);
  const [accountSubmitting, setAccountSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [updateTableOnModalClose, setUpdateTableOnModalClose] = useState(false);
  const [form] = Form.useForm();

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    if (updateTableOnModalClose) {
      props.setRefreshData(!props.refreshData);
    }
  };

  const bulkUpdateFEAccount = (new_account_id) => {
    setUpdateTableOnModalClose(true);
    if (!new_account_id) {
      message.error('Por favor seleccione una cuenta contable');
      return;
    }
    setAccountSubmitting(true);
    const data = {
      new_account_id: new_account_id,
      feIdsToUpdate: props.selectedFEs
        ? props.selectedFEs.map((x) => x.id)
        : [],
      doc_type: props.type, // 'fe_emitidas' or 'fe_recibidas'
    };
    axios({
      method: 'post',
      url: props.API_domain + 'bulkUpdateFEAccount',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setAccountSubmitting(false);
        message.success('Cuenta contable actualizada exitosamente');
        const relatedMovements =
          response.data.total_related_movements_reclassified;
        if (relatedMovements > 0) {
          message.info(
            `Se sincronizó${
              relatedMovements > 1 ? 'n' : ''
            } ${relatedMovements} movimiento${
              relatedMovements > 1 ? 's' : ''
            } relacionado${relatedMovements > 1 ? 's.' : '.'}`
          );
        }
        props.setSelectedFEs([]);
        // Optionally refresh data
      })
      .catch((error) => {
        console.error(error);
        setAccountSubmitting(false);
        message.error('Error actualizando las cuentas contables');
      });
  };

  return (
    <>
      {props.selectedFEs && props.selectedFEs.length >= 1 && (
        <Button
          onClick={() => {
            setVisible(true);
          }}
          style={{ marginRight: 8 }}
        >
          <EditOutlined />
          Editar en masa ({props.selectedFEs.length})
        </Button>
      )}
      <Modal
        title='Editar en masa'
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form}>
          <Form.Item
            name='new_account'
            label='Nueva Cuenta Contable:'
            rules={[
              {
                required: true,
                message: 'Por favor seleccione una cuenta',
              },
            ]}
          >
            <AccountingAccountSelect
              value={form.getFieldValue('new_account')}
              clientId={props.clientId}
              API_domain={props.API_domain}
              auth={auth}
              accounts={props.clientAccounts}
            />
          </Form.Item>
          <Button
            type='primary'
            block
            onClick={() =>
              bulkUpdateFEAccount(form.getFieldValue('new_account'))
            }
          >
            {accountSubmitting ? (
              <LoadingOutlined />
            ) : (
              <>Actualizar Cuentas Contables</>
            )}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default MassEditButtonAndModalForFEs;
