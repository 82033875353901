// FEbutton.js
import React, { useState } from 'react';
import { Button, Tooltip, Modal, Spin, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

function FEbutton({ numero_factura, API_domain, auth }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(''); // PDF URL state
  const [fetchingPdf, setFetchingPdf] = useState(false); // To show spinner while PDF is fetching

  const handleOpenModal = () => {
    setFetchingPdf(true); // Show loading indicator
    setModalVisible(true);

    axios({
      method: 'post',
      url: API_domain + 'getFEpdf',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        cufe: numero_factura, // Send the clicked CUFE to the backend to fetch the PDF link
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setPdfUrl(response.data.fe_pdf_url); // Assuming response.data.fe_pdf_url contains the PDF URL
        } else {
          message.error('Failed to fetch PDF link');
        }
      })
      .catch((error) => {
        console.error('Error fetching PDF link:', error);
        message.error('Error consiguiendo el enlace del PDF.');
      })
      .finally(() => {
        setFetchingPdf(false); // Stop showing spinner after fetch
      });
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setPdfUrl(''); // Reset the PDF URL
  };

  // Determine if the numero_factura is valid or invalid
  const isValid = numero_factura && numero_factura.length > 50; // Arbitrary length to check validity

  // Get the last 5 digits
  const lastFiveDigits = numero_factura
    ? '...' + numero_factura.slice(-5)
    : 'N/A';

  // Decide on the symbol to show
  const icon = isValid ? null : (
    <ExclamationCircleOutlined style={{ color: 'red' }} />
  );

  // Tooltip text
  const tooltipText = isValid ? (
    numero_factura
  ) : (
    <>
      Número de factura no corresponde a FE:
      <br /> {numero_factura}
    </>
  );

  return (
    <>
      <Tooltip title={tooltipText}>
        {numero_factura ? (
          <Button type='link' onClick={handleOpenModal} icon={icon}>
            {`Ver Factura (${lastFiveDigits})`}
          </Button>
        ) : (
          <Button type='link' disabled>
            N/A
          </Button>
        )}
      </Tooltip>

      <Modal
        title='Factura XML Details'
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width={800}
        style={{ height: 800 }}
      >
        {fetchingPdf ? (
          <Spin tip='Cargando PDF...' />
        ) : pdfUrl !== '' ? (
          <iframe
            src={pdfUrl}
            width='100%'
            height='600px'
            title='Factura PDF'
            frameBorder='0'
          />
        ) : (
          <p>No se encontró el PDF.</p>
        )}
      </Modal>
    </>
  );
}

export default FEbutton;
