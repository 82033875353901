import React, { useEffect, useState } from 'react';
import { Modal, Table, Button, Checkbox } from 'antd';
import styled from 'styled-components/macro';
import { FormattedUSD } from './../FormattedUSD.js';
import moment from 'moment';
import '../styles.css';

function TransactionConciliationModal({
  visible,
  onClose,
  data,
  onUnir,
  onDescartar,
}) {
  // State to manage the transformed data
  const [displayData, setDisplayData] = useState([]);
  const [processingIds, setProcessingIds] = useState([]);
  const [pendingActions, setPendingActions] = useState(new Set());

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 100;

  // State for selected groups
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    const transformedData = transformDataForDisplay(data);
    setDisplayData(transformedData);
  }, [data, currentPage]);

  useEffect(() => {
    // After displayData changes, select all groups by default
    const allGroupIds = displayData
      .filter((item) => item.isFirstRow)
      .map((item) => item.groupId);
    setSelectedGroups(allGroupIds);
  }, [displayData]);

  // Function to transform data
  const transformDataForDisplay = (originalData) => {
    // Sort the original data by absolute amount value (descending)
    const sortedData = [...originalData]
      .filter((item) => !pendingActions.has(item.left.id))
      .sort(
        (a, b) =>
          Math.abs(parseFloat(b.left.total)) -
          Math.abs(parseFloat(a.left.total))
      );

    // Apply pagination
    const paginatedData = sortedData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    return paginatedData.flatMap((item, index) => {
      const isEvenGroup = Math.floor(index) % 2 === 0;
      return [
        {
          key: `${currentPage}-${index}-1`,
          groupId: `${currentPage}-${index}`,
          isFirstRow: true,
          isEvenGroup,
          fecha: moment(item.left.movement_date).format('DD/MM/YYYY'),
          monto: item.left.total,
          descripcion: item.left.descripcion,
          entidad: item.left.provider_or_consumer_name,
          original: item,
        },
        {
          key: `${currentPage}-${index}-2`,
          groupId: `${currentPage}-${index}`,
          isFirstRow: false,
          isEvenGroup,
          fecha: moment(item.right.movement_date).format('DD/MM/YYYY'),
          monto: item.right.total,
          descripcion: item.right.descripcion,
          entidad: item.right.provider_or_consumer_name,
          original: item,
        },
      ];
    });
  };

  // Handle "Unir" button click for a single group
  const handleUnirClick = (record) => {
    if (processingIds.includes(record.groupId)) {
      return;
    }

    setProcessingIds((prevIds) => [...prevIds, record.groupId]);
    setPendingActions((prev) => new Set([...prev, record.original.left.id]));

    setDisplayData((prev) =>
      prev.filter((item) => item.groupId !== record.groupId)
    );

    onUnir(record.original).finally(() => {
      setProcessingIds((prevIds) =>
        prevIds.filter((id) => id !== record.groupId)
      );
      setPendingActions((prev) => {
        const newSet = new Set(prev);
        newSet.delete(record.original.left.id);
        return newSet;
      });
      // After this group is handled, also remove it from selectedGroups if present
      setSelectedGroups((prev) => prev.filter((g) => g !== record.groupId));
    });
  };

  // Handle "Discard" button click
  const handleDiscardClick = (record) => {
    if (processingIds.includes(record.groupId)) {
      return; // Already processing this record
    }

    setProcessingIds((prevIds) => [...prevIds, record.groupId]);
    onDescartar(record.original).finally(() => {
      setProcessingIds((prevIds) =>
        prevIds.filter((id) => id !== record.groupId)
      );
      // After discarding, also remove it from selectedGroups if present
      setSelectedGroups((prev) => prev.filter((g) => g !== record.groupId));
    });
  };

  // Add keyboard event handler
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!visible) return; // Only handle events when modal is visible

      if (e.key === 'Enter') {
        const firstRow = displayData.find((record) => record.isFirstRow);
        if (firstRow && !processingIds.includes(firstRow.groupId)) {
          handleUnirClick(firstRow);
        }
      } else if (e.key === 'Delete' || e.key === 'Backspace') {
        const firstRow = displayData.find((record) => record.isFirstRow);
        if (firstRow && !processingIds.includes(firstRow.groupId)) {
          handleDiscardClick(firstRow);
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [visible, displayData, processingIds]);

  // Handle checkbox changes for each group
  const handleCheckboxChange = (groupId, checked) => {
    if (checked) {
      setSelectedGroups((prev) => [...prev, groupId]);
    } else {
      setSelectedGroups((prev) => prev.filter((id) => id !== groupId));
    }
  };

  // Mass unir functionality
  const handleMassUnir = () => {
    const selectedItems = displayData
      .filter((d) => d.isFirstRow && selectedGroups.includes(d.groupId))
      .map((d) => d.original);

    setProcessingIds((prevIds) => [...prevIds, ...selectedGroups]);

    Promise.all(selectedItems.map((item) => onUnir(item))).finally(() => {
      setDisplayData((prev) =>
        prev.filter((item) => !selectedGroups.includes(item.groupId))
      );
      setProcessingIds((prevIds) =>
        prevIds.filter((id) => !selectedGroups.includes(id))
      );
      setSelectedGroups([]);
    });
  };

  const totalPages = Math.ceil(data.length / pageSize);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const selectedCount = selectedGroups.length;

  const allGroupIdsCurrentPage = displayData
    .filter((item) => item.isFirstRow)
    .map((item) => item.groupId);
  const allSelectedCurrentPage =
    allGroupIdsCurrentPage.length > 0 &&
    allGroupIdsCurrentPage.every((id) => selectedGroups.includes(id));

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedGroups((prev) => {
        const combined = new Set([...prev, ...allGroupIdsCurrentPage]);
        return Array.from(combined);
      });
    } else {
      setSelectedGroups((prev) =>
        prev.filter((id) => !allGroupIdsCurrentPage.includes(id))
      );
    }
  };

  const isMultipleSelected = selectedGroups.length > 1;

  // Define table columns with selectable header
  const columns = [
    {
      title: (
        <Checkbox
          checked={allSelectedCurrentPage}
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
      ),
      dataIndex: 'selection',
      key: 'selection',
      width: '3%',
      render: (_, record) => {
        if (!record.isFirstRow) return null;
        const isChecked = selectedGroups.includes(record.groupId);
        const isProcessing = processingIds.includes(record.groupId);
        return (
          <Checkbox
            checked={isChecked}
            disabled={isProcessing}
            onChange={(e) =>
              handleCheckboxChange(record.groupId, e.target.checked)
            }
          />
        );
      },
      onCell: (record) => ({
        rowSpan: record.isFirstRow ? 2 : 0,
      }),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: '5%',
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      key: 'monto',
      width: '5%',
      align: 'right',
      render: (amount) => <FormattedUSD total={amount} />,
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: '50%',
    },
    {
      title: 'Entidad',
      dataIndex: 'entidad',
      key: 'entidad',
      width: '30%',
    },
    {
      title: 'Acción',
      key: 'action',
      width: '10%',
      fixed: 'right',
      onCell: (record) => ({
        rowSpan: record.isFirstRow ? 2 : 0,
      }),
      render: (_, record) => {
        if (record.isFirstRow) {
          const isProcessing = processingIds.includes(record.groupId);
          return (
            <ActionButtonsContainer>
              <StyledUnirButton
                size='small'
                type='primary'
                onClick={() => handleUnirClick(record)}
                disabled={isProcessing || isMultipleSelected} // disable if multiple selected
              >
                Unir
              </StyledUnirButton>
              <StyledDiscardButton
                size='small'
                type='default'
                onClick={() => handleDiscardClick(record)}
                disabled={isProcessing || isMultipleSelected} // disable if multiple selected
              >
                X
              </StyledDiscardButton>
            </ActionButtonsContainer>
          );
        }
        return null;
      },
    },
  ];

  return (
    <StyledModal
      title='Conciliación de Transacciones'
      visible={visible}
      onCancel={onClose}
      footer={
        <FooterContainer>
          {/* Mass Unir on the left */}
          <LeftFooterContainer>
            <Button
              type='primary'
              disabled={selectedCount === 0}
              onClick={handleMassUnir}
            >
              Unir ({selectedCount})
            </Button>
          </LeftFooterContainer>

          {/* Pagination in the middle */}
          <CenterFooterContainer>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
              Anterior
            </Button>
            <PaginationInfo>
              Página {currentPage} de {totalPages}
            </PaginationInfo>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Siguiente
            </Button>
          </CenterFooterContainer>
        </FooterContainer>
      }
      width='75%'
      bodyStyle={{ padding: 0 }}
    >
      <TableContainer>
        <StyledTable
          dataSource={displayData}
          columns={columns}
          pagination={false}
          size='small'
          rowClassName={(record) =>
            `group-${record.groupId} ${
              record.isFirstRow ? 'first-row' : 'second-row'
            } ${record.isEvenGroup ? 'even-group' : 'odd-group'}`
          }
        />
      </TableContainer>
    </StyledModal>
  );
}

// Styled components

const StyledModal = styled(Modal)`
  top: 20px;
  padding: 0;
  .ant-modal-content {
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
  }
  .ant-modal-wrap {
    z-index: 1000;
  }
`;

const TableContainer = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 16px 24px 16px;
`;

const StyledTable = styled(Table)`
  .ant-table {
    font-size: 12px;
  }

  .ant-table-thead > tr > th {
    text-align: center;
    background-color: var(--grey-lightest);
    padding: 4px 8px;
    font-size: 12px;
  }

  .ant-table-cell {
    padding: 8px;
  }

  .first-row td {
    border-bottom: none;
  }

  .second-row td {
    border-top: none;
  }

  .even-group td {
    background-color: #ffffff;
  }

  .odd-group td {
    background-color: #f5f5f5;
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledUnirButton = styled(Button)`
  height: auto;
  padding: 4px 15px;
`;

const StyledDiscardButton = styled(Button)`
  margin-left: 5px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftFooterContainer = styled.div`
  margin-right: auto;
`;

const CenterFooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PaginationInfo = styled.span`
  margin: 0 10px;
`;

export default TransactionConciliationModal;
