import React, { useState, useEffect, useRef } from 'react';
import { Button, Select, message, Tooltip } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import axios from 'axios';

function AccountingAccountSelect({
  value,
  onChange,
  clientId,
  API_domain,
  auth,
  account_id_confidence,
  showThumbUp = false,
  ...restProps
}) {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectRef = useRef(null);
  // Determine confidence value
  let confidence = account_id_confidence;

  if (confidence === undefined || confidence === null) {
    confidence = 0;
  }

  // Generate a unique class name to avoid conflicts
  const selectClassName =
    'accounting-account-select-' + Math.random().toString(36).substr(2, 9);

  // Create styles based on confidence
  const styles = `
    .${selectClassName} .ant-select-selector {
      ${
        confidence == 1
          ? 'border-image: linear-gradient(to right, var(--green-dark), var(--green-light)) 1 !important; border-width: 2.2px !important;'
          : ''
      }
      ${
        confidence > 0 && confidence < 1
          ? `
        border-image: linear-gradient(
          to left,
          var(--yellow-dark) ${((1 - confidence) * 100).toFixed(0)}%,
          var(--green-light) ${((1 - confidence) * 100).toFixed(0)}%
        ) 1 !important;
        border-style: dashed !important;
        border-width: 1.8px !important;
      `
          : ''
      }
    }
  `;

  // Fetch accounts for the client
  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${API_domain}getClientAccounts`,
          {
            client_id: clientId,
          },
          {
            auth: {
              username: auth.email,
              password: auth.token,
            },
          }
        );
        setAccounts(response.data.accounts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching accounts:', error);
        message.error('Error al obtener las cuentas');
        setLoading(false);
      }
    };
    if (!restProps.accounts) {
      fetchAccounts();
    } else {
      setAccounts(restProps.accounts);
    }
  }, [API_domain, clientId, auth, restProps.accounts, account_id_confidence]);

  const calculateDropdownWidth = () => {
    if (selectRef.current) {
      const selectWidth = selectRef.current.offsetWidth;
      return selectWidth * 1.5; // Set to 1.5 times the select width, for example
    }
    return 300; // Fallback width
  };

  // Tooltip content based on confidence
  const tooltipContent = (() => {
    if (confidence == 1) return 'Confianza 100%';
    if (confidence > 0 && confidence < 1)
      return '🪄 Confianza ' + (confidence * 100).toFixed(0) + '%';
    return 'No hay confianza.';
  })();

  return (
    <>
      <style>{styles}</style>
      <div style={{ display: 'flex', width: '100%' }}>
        <Tooltip title={tooltipContent} placement='left'>
          <Select
            ref={selectRef}
            className={selectClassName}
            showSearch
            value={value}
            loading={loading}
            style={{ flex: showThumbUp ? '1' : '1 1 100%' }}
            onChange={onChange}
            optionLabelProp='label'
            dropdownStyle={{ width: `${calculateDropdownWidth()}px` }}
            dropdownMatchSelectWidth={false}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            {...restProps}
          >
            {accounts.map((account) => (
              <Select.Option
                key={account.account_id}
                value={account.account_id}
                label={account.zoho_account_name}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>{account.zoho_account_name}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: 'gray', marginLeft: 10 }}>
                      <SwapOutlined style={{ marginLeft: 4 }} />
                      {account.movement_count}
                    </span>
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Tooltip>
        {showThumbUp && (
          <Tooltip title='Subir confianza a 100%'>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onChange();
              }}
              style={{ marginLeft: 8 }}
            >
              💯
            </Button>
          </Tooltip>
        )}
      </div>
    </>
  );
}

export default AccountingAccountSelect;
