import {
  Affix,
  Button,
  Col,
  Layout,
  Row,
  message,
  Table,
  Tabs,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  LoadingOutlined,
  MailOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { IncomeStatementTable2 } from './IncomeStatementTable2.js';
import { BalanceSheetTable2 } from './BalanceSheetTable2.js';
import { AccountingTransactionsTable } from './AccountingTransactionsTable.js';
import { AccountingAccountsTable } from './AccountingAccountsTable.js';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';
import { generatePDF } from './GeneratePDF';
import 'jspdf-autotable';
import { formatDateSpanishLong } from '../../utils';
import EmailReviewModal from '../EmailComposer/EmailReviewModal.js';
import { AccountingInfographicsContainer } from './AccountingInfographicsContainer.js';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;

function AccountingFinancialStatementsView(props) {
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [hasFinancialStatements, setHasFinancialStatements] = useState();
  const [accountingClientCompany, setAccountingClientCompany] = useState(null);
  const [incomeStatementData, setIncomeStatementData] = useState(null);
  const [balanceSheetData, setBalanceSheetData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingIncomeStatement, setLoadingIncomeStatement] = useState(true);
  const [loadingBalanceSheet, setLoadingBalanceSheet] = useState(true);
  const [email, setEmail] = useState('');
  const [
    incomeStatementRecentTimePeriods,
    setIncomeStatementRecentTimePeriods,
  ] = useState([]);
  const [balanceSheetRecentTimePeriods, setBalanceSheetRecentTimePeriods] =
    useState([]);
  const [
    timePeriodsWithMostRecentTransaction,
    setTimePeriodsWithMostRecentTransaction,
  ] = useState([]);
  const auth = useContext(authContext);
  const isAdmin = auth.adminEmail;

  useEffect(() => {
    const fetchAllData = async () => {
      if (!auth.email || !auth.token) {
        return; // Ensure authentication details are available
      }

      setLoading(true); // Start loading

      try {
        // Fetch company info
        const companyResponse = await axios.post(
          `${props.API_domain}getUserAccountingClientCompanyInfo`,
          {},
          {
            auth: {
              username: auth.email,
              password: auth.token,
            },
          }
        );

        // console.log('Company Response:', companyResponse.data);

        const {
          accounting_client_id,
          accounting_client_company_id,
          name,
          ruc,
          logo_image_url,
        } = companyResponse.data;

        // console.log('Extracted Company Info:', {
        //   accounting_client_id,
        //   accounting_client_company_id,
        //   name,
        //   ruc,
        //   logo_image_url,
        // });
        // console.log(
        //   'Fetching logo for accounting_client_id:',
        //   accounting_client_id
        // );
        if (accounting_client_id) {
          let logoResponse = null;
          try {
            // Fetch logo separately
            logoResponse = await axios.post(
              `${props.API_domain}getAccountingClientLogoSource`,
              { selectedAccountingClientId: accounting_client_id },
              {
                auth: {
                  username: auth.email,
                  password: auth.token,
                },
              }
            );
          } catch (error) {
            console.error('Error fetching logo:', error);
          }

          const companyInfo = {
            accounting_client_id,
            accounting_client_company_id,
            name,
            ruc,
            logo_image_url,
            logo_base64: logoResponse
              ? logoResponse.data.logo_image_base64
              : null,
          };
          // console.log('Fetched Logo and added to company info:', companyInfo);
          setAccountingClientCompany(companyInfo);
          setHasFinancialStatements(true);

          // Fetch Income Statement Data
          try {
            const incomeResponse = await axios.post(
              `${props.API_domain}getIncomeStatementData`,
              {
                client_id:
                  accounting_client_id === 4 ? 10 : accounting_client_id,
              },
              {
                auth: {
                  username: auth.email,
                  password: auth.token,
                },
              }
            );
            const {
              incomeStatementData,
              timePeriods,
              timePeriodsWithMostRecentTransaction,
            } = incomeResponse.data;

            const recentMonthsCount = 10;
            const recentTimePeriods = timePeriods.slice(-recentMonthsCount);
            setIncomeStatementRecentTimePeriods(recentTimePeriods);
            setTimePeriodsWithMostRecentTransaction(
              timePeriodsWithMostRecentTransaction
            );

            const sliceValuesRecursively = (node, count) => ({
              ...node,
              values: node.values.slice(-count),
              children: node.children.map((child) =>
                sliceValuesRecursively(child, count)
              ),
            });

            const filteredIncomeStatementData = incomeStatementData.map(
              (category) => sliceValuesRecursively(category, recentMonthsCount)
            );

            let keyedIncomeStatementData = [];
            if (accounting_client_id === 4) {
              const obscuredIncomeStatementData = obscureIncomeStatementData(
                filteredIncomeStatementData,
                0.62
              );

              keyedIncomeStatementData = keyData(obscuredIncomeStatementData);
            } else {
              keyedIncomeStatementData = keyData(filteredIncomeStatementData);
            }
            setIncomeStatementData(keyedIncomeStatementData);
          } catch (error) {
            console.error('Error fetching income statement data:', error);
            message.error(
              'Error al obtener los datos del estado de resultados'
            );
          } finally {
            setLoadingIncomeStatement(false);
          }

          // Fetch Balance Sheet Data
          try {
            const balanceResponse = await axios.post(
              `${props.API_domain}getBalanceSheetData`,
              {
                client_id:
                  accounting_client_id === 4 ? 10 : accounting_client_id,
              },
              {
                auth: {
                  username: auth.email,
                  password: auth.token,
                },
              }
            );

            const { balanceSheetData, timePeriods } = balanceResponse.data;

            const recentMonthsCount = 10;
            const recentTimePeriods = timePeriods.slice(-recentMonthsCount);
            setBalanceSheetRecentTimePeriods(recentTimePeriods);

            const sliceValuesRecursively = (node, count) => ({
              ...node,
              values: node.values.slice(-count),
              children: node.children.map((child) =>
                sliceValuesRecursively(child, count)
              ),
            });

            const filteredBalanceSheetData = balanceSheetData.map((category) =>
              sliceValuesRecursively(category, recentMonthsCount)
            );

            let keyedBalanceSheetData = [];
            if (accounting_client_id === 4) {
              const obscuredBalanceSheetData = obscureBalanceSheetData(
                filteredBalanceSheetData,
                0.62
              );

              keyedBalanceSheetData = keyData(obscuredBalanceSheetData);
            } else {
              keyedBalanceSheetData = keyData(filteredBalanceSheetData);
            }
            setBalanceSheetData(keyedBalanceSheetData);
          } catch (error) {
            console.error('Error fetching balance sheet data:', error);
            message.error('Error al obtener los datos del balance');
          } finally {
            setLoadingBalanceSheet(false);
          }
        } else {
          setHasFinancialStatements(false);
        }
      } catch (error) {
        message.error('Error al obtener la información de la empresa contable');
        console.error('Error fetching accounting client:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchAllData();
  }, [auth.email, auth.token, props.API_domain]);

  // ===== No Change: Commented out tracking useEffect remains unchanged =====
  // useEffect(() => {
  //   const trackingData = {
  //     event: 'seeAccountingFinancialStatements',
  //     properties: {},
  //   };
  //   axios({
  //     method: 'post',
  //     url: props.API_domain + 'trackFrontEndEvent',
  //     auth: {
  //       username: auth.email,
  //       password: auth.token,
  //     },
  //     data: trackingData,
  //   })
  //     .then((response) => {
  //       // console.log('Event tracked:', response);
  //     })
  //     .catch((error) => {
  //       console.error('Error tracking event:', error);
  //     });
  // }, [auth, props.API_domain]);

  ///////////////////////////////////////// CODE TO RENDER OBSCURE DATA TO SHOW FOR HERO DEMO //////////////////////////////////////////
  const subcategoryMapping = {
    // INGRESOS: 'INCOME',
    'Ingresos por Servicios de Fisioterapia': 'Ingresos por venta de comida',
    // GASTOS: 'EXPENSES',
    // 'XIII Mes': 'Christmas Bonus',
    // 'Servicios Electricos': 'Electric Services',
    // 'Atención a empleados': 'Employee Care',
    // 'Impuestos Municipales': 'Municipal Taxes',
    // 'Cuotas o Suscripciones': 'Subscriptions',
    // Impuestos: 'Taxes',
    // 'Gastos Legales': 'Legal Expenses',
    // 'Utiles de oficina': 'Office Supplies',
    // 'Publicidad y marketing': 'Marketing Expenses',
    // 'Cargos y tasas bancarias': 'Bank Fees',
    // 'Publicidad municipal': 'Municipal Advertising',
    // 'Gastos de TI y de Internet': 'IT and Internet Expenses',
    // 'Gastos de alquiler': 'Rent Expenses',
    // 'Reparación y mantenimiento': 'Repair and Maintenance',
    // Uniformes: 'Uniforms',
    // 'Amort. Gastos Organizacionales': 'Amort. Organizational Expenses',
    // 'Honorarios Contables': 'Accounting Fees',
    // Salarios: 'Salaries',
    // 'Aseo y Limpieza': 'Cleaning Services',
    // 'Gasto de depreciación': 'Depreciation Expense',
    // 'Prestaciones laborales': 'Employee Benefits',
    // 'Honorarios Profesionales': 'Professional Fees',
    // 'Servicios de agua': 'Water Services',
    // 'Gastos Varios': 'Miscellaneous Expenses',
    'Honorarios profesionales Pablo': 'Honorarios profesionales Juan Perez',
    'Honorarios profesionales Grerys': 'Honorarios profesionales Maria Jimenez',
    // 'Servicios profesionales': 'Professional Services',
    'Honorarios profesionales Cilia': 'Honorarios profesionales Michael Jordan',
    'Insumos de fisioterapeutas': 'Insumos de producción',
    // 'UTILIDAD NETA': 'NET INCOME',
  };

  const obscureSubcategory = (subcategory) => {
    return subcategoryMapping[subcategory] || subcategory;
  };

  const obscureIncomeStatementData = (data, factor) => {
    const obscureValuesRecursively = (node) => ({
      ...node,
      subcategory: obscureSubcategory(node.subcategory),
      values: node.values.map((val) => (val * factor).toFixed(2)),
      children: node.children.map(obscureValuesRecursively),
    });

    return data.map(obscureValuesRecursively);
  };
  //////////////// halfstep
  const balanceSheetSubcategoryMapping = {
    // ACTIVOS: 'ASSETS',
    // 'Otras cuentas por cobrar': 'Other Receivables',
    // 'Gastos Organizacionales': 'Organizational Expenses',
    // 'Amort. Gastos Organ.': 'Amort. Organizational Expenses',
    // 'Cuenta por cobrar Yappy': 'Receivable Yappy',
    // 'Cuenta por cobrar Tarjeta': 'Receivable Card',
    // Efectivo: 'Cash',
    // 'Impuestos adelantados': 'Prepaid Taxes',
    // 'Cuenta por cobrar ACH': 'Receivable ACH',
    'Tarjeta Hero Salomon Cohen': 'Tarjeta Hero Michael Jordan',
    // 'Banco General': 'General Bank',
    'Tarjeta Hero Pablo Lima': 'Tarjeta Hero Juan Perez',
    // Bac: 'BAC',
    // 'Mobiliario de oficina': 'Office Furniture',
    // 'Depreciacion Mob. de Oficina': 'Deprec. Office Furniture',
    'Equipo de Fisoterapia': 'Equipo de cocina',
    'Depreciacion Equi. de Fisioterapia': 'Depreciacion Equi. de Cocina',
    'Equipo de Gym': 'Mobiliario restaurante',
    'Depreciacion Equi. de Gym': 'Depreciacion Mobiliario restaurante',
    // 'Mejoras al local arrendado': 'Improvements to Rented Premises',
    // 'Dep. Mejoras al local arrendado': 'Deprec. Improvements to Premises',
    // 'Equipo de Oficina': 'Office Equipment',
    // 'Depreciacion Equi. de Oficina': 'Deprec. Office Equipment',
    // Letreros: 'Signs',
    // 'Depreciacion Letreros': 'Deprec. Signs',
    // PASIVOS: 'LIABILITIES',
    'Honorarios por pagar Cilia': 'Honorarios por pagar Juan Perez',
    'Honorarios por pagar Salo': 'Honorarios por pagar Michael Jordan',
    'Honorarios por pagar Grerys': 'Honorarios por pagar Maria Jimenez',
    'Honorarios por pagar Pablo': 'Honorarios por pagar Linda Perez',
    'Cuenta por pagar Infinity Eq': 'Cuenta por pagar Compañía X',
    'Cuenta por pagar Cilia Amar': 'Cuenta por pagar Compañía Z',
    // 'Anticipio de clientes': 'Customer Advances',
    // 'Seguro social por pagar': 'Social Security Payable',
    'Aporte Accionista Pablo Lima': 'Aporte Accionista Peter Pan',
    'Aporte Accionista Cilia Amar': 'Aporte Accionista Juan Perez Sr.',
    // PATRIMONIO: 'EQUITY',
  };

  const obscureBalanceSheetSubcategory = (subcategory) => {
    return balanceSheetSubcategoryMapping[subcategory] || subcategory;
  };

  const obscureBalanceSheetData = (data, factor) => {
    const obscureValuesRecursively = (node) => ({
      ...node,
      subcategory: obscureBalanceSheetSubcategory(node.subcategory),
      values: node.values.map((val) => (val * factor).toFixed(2)),
      children: node.children.map(obscureValuesRecursively),
    });

    return data.map(obscureValuesRecursively);
  };

  ///////////////////////////////////////// end CODE TO RENDER OBSCURE DATA TO SHOW FOR HERO DEMO //////////////////////////////////////////

  // ===== No Change: Removed redundant useEffect for fetching financial data =====
  // The financial data fetching is now handled within the consolidated useEffect above.
  const keyData = (rawData) => {
    const generateKeys = (data, parentKey = '') => {
      return data.map((item, index) => {
        const key = parentKey ? `${parentKey}-${index + 1}` : `${index + 1}`;
        const children = item.children ? generateKeys(item.children, key) : [];
        return { ...item, key, children };
      });
    };
    return generateKeys(rawData);
  };

  const getLastDayOfMonth = (yearMonth) => {
    const [year, month] = yearMonth.split('-').map(Number);

    // Create a new date object set to the first day of the next month
    const date = new Date(year, month, 1);

    // Subtract one day to get the last day of the given month
    date.setDate(date.getDate() - 1);

    // Format the date to YYYY-MM-DD
    const lastDay = date.toISOString().split('T')[0];

    return lastDay;
  };

  const handleGeneratePDF = (outputOnlyBlob = false) => {
    return generatePDF(
      outputOnlyBlob,
      accountingClientCompany,
      incomeStatementRecentTimePeriods,
      balanceSheetRecentTimePeriods,
      balanceSheetData,
      incomeStatementData,
      props.API_domain,
      auth
    );
  };

  const tabs = [
    {
      key: '1',
      label: `Estado de Resultados`,
      children:
        accountingClientCompany && incomeStatementData ? (
          <div>
            <IncomeStatementTable2
              API_domain={props.API_domain}
              accountingClientCompany={
                accountingClientCompany.accounting_client_id
              }
              incomeStatementData={incomeStatementData}
              incomeStatementRecentTimePeriods={
                incomeStatementRecentTimePeriods
              }
              timePeriodsWithMostRecentTransaction={
                timePeriodsWithMostRecentTransaction
              }
            />
          </div>
        ) : (
          <Table loading={loadingIncomeStatement} />
        ),
    },
    {
      key: '2',
      label: `Balance`,
      children:
        accountingClientCompany && balanceSheetData ? (
          <div>
            <BalanceSheetTable2
              API_domain={props.API_domain}
              accountingClientCompany={
                accountingClientCompany.accounting_client_id
              }
              balanceSheetData={balanceSheetData}
              balanceSheetRecentTimePeriods={balanceSheetRecentTimePeriods}
              timePeriodsWithMostRecentTransaction={
                timePeriodsWithMostRecentTransaction
              }
            />
          </div>
        ) : (
          <Table loading={loadingBalanceSheet} />
        ),
    },
    ...(isAdmin
      ? [
          {
            key: '3',
            label: `Diarios Manuales 🦸`,
            children: (
              <AccountingTransactionsTable
                API_domain={props.API_domain}
                accountingClientCompany={accountingClientCompany}
              />
            ),
          },
          {
            key: '4',
            label: `Cuentas Contables 🦸`,
            children: (
              <AccountingAccountsTable
                API_domain={props.API_domain}
                accountingClientCompany={accountingClientCompany}
              />
            ),
          },
        ]
      : []),
  ];

  const showEmailModal = async () => {
    const get_accounting_client_data_response = await axios.get(
      `${props.API_domain}getDataForAccountingClient/${accountingClientCompany.accounting_client_id}`,
      {
        auth: {
          username: auth.email,
          password: auth.token,
        },
      }
    );

    const { accounting_client_name, emails } =
      get_accounting_client_data_response.data;

    try {
      const pdfBlob = await handleGeneratePDF(true);
      const accountingDate = getLastDayOfMonth(
        incomeStatementRecentTimePeriods[
          incomeStatementRecentTimePeriods.length - 1
        ]
      );
      const accountingDateLong = formatDateSpanishLong(accountingDate);

      // Prepare email data
      setEmailData({
        to: emails.join(',') + ',contabilidad@herofacturas.com' || '', // You'll need to set this based on your requirements
        subject: `Reporte Contable - ${accountingClientCompany.name} - ${accountingDateLong}`,
        body: `Hola estimados ${accountingClientCompany.name},

Adjunto pueden ver un reporte de su contabilidad al ${accountingDateLong}.
        
Hero`,
        attachments: [{ name: 'reporte_contable.pdf', content: pdfBlob }],
        metadata: {
          accounting_client_id: accountingClientCompany.accounting_client_id,
          accounting_client_name: accountingClientCompany.name,
          accounting_date: accountingDate,
          accounting_date_long: accountingDateLong,
        },
      });

      // Show the modal for email review
      setIsEmailModalVisible(true);
    } catch (error) {
      console.error('Error preparing email data:', error);
      message.error('Failed to prepare email data. Please try again.');
    }
  };

  const handleDownloadLedger = async () => {
    try {
      const response = await axios.get(
        `${props.API_domain}downloadGeneralLedger`,
        {
          responseType: 'blob',
          auth: {
            username: auth.email,
            password: auth.token,
          },
          params: {
            accounting_client_id: accountingClientCompany.accounting_client_id,
          },
        }
      );

      const blob = new Blob([response.data], {
        type: 'text/csv;charset=utf-8;',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'general_ledger.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading general ledger:', error);
      message.error('Failed to download general ledger. Please try again.');
    }
  };

  // console.log('accountingClientCompany:', accountingClientCompany);
  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        // borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      {loading ? (
        <LoadingDiv>{antIcon}</LoadingDiv>
      ) : hasFinancialStatements && accountingClientCompany ? (
        <>
          <Affix>
            <AffixDiv>
              <h1>
                {accountingClientCompany.logo_image_url && (
                  <img
                    src={accountingClientCompany.logo_image_url}
                    alt='Company Logo'
                    style={{
                      width: '100px',
                      objectFit: 'contain',
                      borderRadius: '4px',
                      marginRight: '16px',
                    }}
                  />
                )}
                {accountingClientCompany.name}
              </h1>
            </AffixDiv>
          </Affix>
          <AccountingInfographicsContainer
            API_domain={props.API_domain}
            accountingClientCompany={accountingClientCompany}
            incomeStatementData={incomeStatementData}
            incomeStatementRecentTimePeriods={incomeStatementRecentTimePeriods}
            balanceSheetData={balanceSheetData}
            balanceSheetRecentTimePeriods={balanceSheetRecentTimePeriods}
          />

          <div
            style={{
              color: 'var(--grey-darkest)',
              textAlign: 'left',
              paddingLeft: '16px',
              paddingRight: '16px',
              paddingTop: '16px',
              paddingBottom: '8px',
            }}
          >
            <Row>
              <Col xs={24} md={12} xxl={12}>
                <h2>Reportes Contables</h2>
              </Col>
              <Col xs={24} md={12} xxl={12}>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    type='secondary'
                    onClick={showEmailModal}
                    disabled={
                      !incomeStatementData || !balanceSheetData || email !== ''
                    }
                    style={{ marginRight: 10 }}
                  >
                    <MailOutlined />
                    Mandar por <b> Correo</b>
                  </Button>
                  <Button
                    onClick={() => handleGeneratePDF()}
                    type='secondary'
                    style={{ marginRight: 10 }}
                  >
                    <DownloadOutlined />
                    PDF
                  </Button>
                  {isAdmin && (
                    <Button onClick={handleDownloadLedger} type='secondary'>
                      <DownloadOutlined />
                      {/* Replace CrownOutlined with your Hero icon if available */}
                      Mayor 🦸
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <Tabs
              defaultActiveKey='1'
              style={{ width: '100%', paddingLeft: '5%' }}
            >
              {tabs.map((tab) => (
                <TabPane tab={tab.label} key={tab.key}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    {tab.children}
                  </div>
                </TabPane>
              ))}
            </Tabs>
          </div>
        </>
      ) : (
        <Row justify='center' align='middle' style={{ minHeight: '100vh' }}>
          <Col xs={22} sm={18} md={14} lg={12}>
            <Typography align='center'>
              <Title level={2}>
                ¿Estás buscando servicios mensuales de contabilidad?
              </Title>
              <Paragraph>
                Aquí podrías ver tu Estado de Resultados (P&L) y tu balance de
                situación (Balance Sheet) en tiempo real.
              </Paragraph>
              <Button
                type='primary'
                size='large'
                onClick={() => {
                  const phoneNumbers = ['50766181519', '50766759599'];
                  const selectedPhone =
                    phoneNumbers[
                      Math.floor(Math.random() * phoneNumbers.length)
                    ];
                  window.open(
                    `https://api.whatsapp.com/send?phone=${selectedPhone}&text=Soy%20usuario%20de%20Hero%20y%20quiero%20contabilidad.`,
                    '_blank'
                  );
                }}
              >
                Conversemos Ahora
              </Button>
            </Typography>
          </Col>
        </Row>
      )}
      <EmailReviewModal
        visible={isEmailModalVisible}
        onCancel={() => setIsEmailModalVisible(false)}
        initialValues={emailData}
        API_domain={props.API_domain}
      />
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
  text-align: left;
`;
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { AccountingFinancialStatementsView };
