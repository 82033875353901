import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Typography, Button, Form, Modal, Spin, Upload, message, Select } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { AccountingClientSelect } from '../Accounting/AccountingClientSelect.js';
import { authContext } from '../../ProvideAuth.js';
import { read, utils } from 'xlsx';

const { Link } = Typography;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const columnMappingEmitidos = [
  'cufe',
  'document_type',
  'operation_type',
  'emission_date',
  'auth_date',
  'receiver_id',
  'receiver_name',
  'subtotal',
  'itbms',
  'amount',
  'branch_code',
  'nature_of_operation',
  'operation_type_repeated',
  'operation_destination',
  'payment_term'
];

const columnMappingRecibidos = [
  'cufe',
  'document_type',
  'emission_date',
  'auth_date',
  'emissor_id',
  'emissor_name',
  'subtotal',
  'itbms',
  'amount',
  'branch_code',
  'nature_of_operation',
  'operation_type',
  'operation_destination',
  'payment_term'
];

const CsvUploader = ({ setCsvData, documentType }) => {
  const [files, setFiles] = useState([]);

  const uploadChanged = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const columnMapping = documentType === 'emitidos' ? columnMappingEmitidos : columnMappingRecibidos;
      const jsonData = utils.sheet_to_json(worksheet, {
        header: columnMapping,
        range: 2  // Skip first two rows
      });
      setCsvData(jsonData);
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  const onRemove = (file) => {
    setFiles(prevFiles => prevFiles.filter(f => f.uid !== file.uid));
  };

  const onChange = ({ fileList }) => {
    setFiles(fileList);
  };

  return (
    <Upload
      accept=".csv,.xlsx,.xls"
      beforeUpload={uploadChanged}
      onRemove={onRemove}
      fileList={files}
      onChange={onChange}
      maxCount={1}
    >
      <Button icon={<UploadOutlined />}>Upload CSV</Button>
    </Upload>
  );
};

function AdminDGI_Docs_Upload(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [selectedAccountingClientId, setSelectedAccountingClientId] = useState(null);
  const [documentType, setDocumentType] = useState('recibidos');

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      accounting_client_id: selectedAccountingClientId,
      csv_data: csvData,
    };
    axios({
      method: 'post',
      url: `${props.API_domain}adminUploadDGI_Docs/${documentType}`,
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        message.success(`DGI Doc Elec ${documentType} procesado con éxito`);
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        console.log(response);
        alert('Hubo un error en el procesamiento del archivo, favor revisar columnas y que su usuario tenga permisos');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Upload DGI Docs Electronicos</ButtonSpan>
      </Button>
      <Modal
        title='Upload DGI Docs Electronicos'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={onFinish}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Procesar Documento</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name="documentType"
            label="Tipo de Documento"
            rules={[{ required: true, message: 'Por favor seleccione el tipo de documento' }]}
          >
            <Select onChange={(value) => setDocumentType(value)}>
              <Option value="recibidos">Recibidos</Option>
              <Option value="emitidos">Emitidos</Option>
            </Select>
          </Form.Item>

          <AccountingClientSelect
            API_domain={props.API_domain}
            onAccountingClient={(accountingClient) => {
              setSelectedAccountingClientId(accountingClient);
            }}
          />
          <br />
          <br />
          <div>Subir el archivo tal cual se descarga de la DGI</div>
          <br />
          <br />
          <CsvUploader setCsvData={setCsvData} documentType={documentType} />
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminDGI_Docs_Upload };