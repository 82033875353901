import React, { useContext, useState } from 'react';
import { Button, Form, Modal, Select, Spin } from 'antd';
import axios from 'axios';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import AccountingAccountSelect from '../Accounting/AccountingAccountSelect'; // Adjust import path as needed

const { Option } = Select;

function AdminAutoCommit(props) {
  const auth = useContext(authContext);
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [clientOptions, setClientOptions] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const showModal = async () => {
    setVisible(true);
    setLoadingClients(true);
    try {
      const response = await axios({
        method: 'get',
        url: props.API_domain + 'getAccountingClients',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      const options = response.data.map((client) => (
        <Option key={client.id} value={client.id}>
          {client.name}
        </Option>
      ));
      setClientOptions(options);
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setLoadingClients(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      client_id: values.client_id,
      itbms_account_id: values.itbms_account_id || null,
      fe_emitidas_account_id: values.fe_emitidas_account_id || null,
      fe_recibidas_account_id: values.fe_recibidas_account_id || null,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'autoCommit',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        Modal.success({
          title: 'Auto Commit Successful',
          content: 'La acción de autoCommit se completó exitosamente.',
        });
        setVisible(false);
        setSubmitting(false);
        form.resetFields();
      })
      .catch((error) => {
        console.error('autoCommit error', error);
        Modal.error({
          title: 'Error',
          content: 'Ocurrió un error durante el proceso de auto commit.',
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Auto Commit</ButtonSpan>
      </Button>
      <Modal
        title='Auto Commit'
        visible={visible}
        onOk={form.submit}
        onCancel={handleCancel}
        centered
        confirmLoading={isSubmitting}
      >
        <Form
          form={form}
          name='autoCommitForm'
          onFinish={onFinish}
          layout='vertical'
        >
          <Form.Item
            name='client_id'
            label='Seleccione el Cliente'
            rules={[{ required: true, message: 'Por favor seleccione un cliente' }]}
          >
            {loadingClients ? (
              <Spin />
            ) : (
              <Select
                showSearch
                placeholder='Seleccione un cliente'
                optionFilterProp='children'
                onChange={(val) => setSelectedClientId(val)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {clientOptions}
              </Select>
            )}
          </Form.Item>

          <Form.Item
            name='itbms_account_id'
            label='Cuenta ITBMS (Opcional)'
          >
            {selectedClientId ? (
              <AccountingAccountSelect
                clientId={selectedClientId}
                API_domain={props.API_domain}
                auth={auth}
                placeholder='Seleccionar cuenta ITBMS'
                allowClear
              />
            ) : (
              <Select placeholder='Seleccione primero un cliente' disabled />
            )}
          </Form.Item>

          <Form.Item
            name='fe_emitidas_account_id'
            label='Cuenta para FE Emitidas No Relacionadas (Opcional)'
          >
            {selectedClientId ? (
              <AccountingAccountSelect
                clientId={selectedClientId}
                API_domain={props.API_domain}
                auth={auth}
                placeholder='Seleccionar cuenta FE emitidas'
                allowClear
              />
            ) : (
              <Select placeholder='Seleccione primero un cliente' disabled />
            )}
          </Form.Item>

          <Form.Item
            name='fe_recibidas_account_id'
            label='Cuenta para FE Recibidas No Relacionadas (Opcional)'
          >
            {selectedClientId ? (
              <AccountingAccountSelect
                clientId={selectedClientId}
                API_domain={props.API_domain}
                auth={auth}
                placeholder='Seleccionar cuenta FE recibidas'
                allowClear
              />
            ) : (
              <Select placeholder='Seleccione primero un cliente' disabled />
            )}
          </Form.Item>

          <ExplanationContainer>
            <b>Cuentas Opcionales:</b><br/>
            1. Separar el ITBMS de los gastos.<br/>
            2. La cuenta de contrapartida para las FE emitidas no relacionadas (Ejemplo: Cuentas por Cobrar).<br/>
            3. La cuenta de contrapartida para las FE recibidas no relacionadas.
          </ExplanationContainer>
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const ExplanationContainer = styled.div`
  margin-top: 16px;
  font-size: 12px;
  color: #555;
`;

export { AdminAutoCommit };
