import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';

const DefaultVisibleTooltip = (props) => {
  // Expected props:
  // - props.title: The content of the tooltip (can include HTML or React elements).
  // - props.children: The target element wrapped by the tooltip.
  // - props.defaultVisible: (optional) Whether the tooltip is visible by default. Defaults to true.

  const [visible, setVisible] = useState(props.defaultVisible ?? true); // Controlled visibility

  const closeTooltip = () => {
    setVisible(false); // Close the tooltip
  };

  return (
    <Tooltip
      title={
        <div>
          {props.title}
          {/* <Button
            size='small'
            type='link'
            onClick={closeTooltip}
            style={{ padding: 0 }}
          >
            Cerrar
          </Button> */}
        </div>
      }
      visible={visible} // Controlled visibility
      trigger='click' // Allow toggling with click
      onVisibleChange={(newVisible) => setVisible(newVisible)} // Sync visibility changes
    >
      {props.children}
    </Tooltip>
  );
};

export { DefaultVisibleTooltip };
