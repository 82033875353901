import React, { useState } from 'react';
import { Button, Popover } from 'antd';

// React Component
const EngineButton = ({
  text,
  popOverContent,
  loading = false,
  disabled = false,
  onClick = () => {},
}) => {
  const [hovered, setHovered] = useState(false);

  const buttonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 12px',
    transition: 'all 0.2s ease',
    fontSize: '14px',
    marginLeft: '8px',
    marginRight: '8px',
    border: disabled
      ? '1px solid var(--grey-normal)'
      : '1px solid var(--purple-dark)',
    background: disabled
      ? 'var(--grey-light)'
      : hovered
      ? 'var(--purple-dark)'
      : 'white',
    color: disabled
      ? 'var(--grey-darkest)'
      : hovered
      ? 'white'
      : 'var(--purple-dark)',
    cursor: disabled ? 'not-allowed' : 'pointer',
  };

  return (
    <Popover
      // placement='top'
      title={`${text} `}
      trigger='hover'
      content={popOverContent}
    >
      <Button
        disabled={disabled}
        onClick={onClick}
        style={buttonStyle}
        onMouseEnter={() => !disabled && setHovered(true)}
        onMouseLeave={() => !disabled && setHovered(false)}
        loading={loading}
      >
        {text}
      </Button>
    </Popover>
  );
};

export default EngineButton;
