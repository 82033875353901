import React, { useContext, useState, useEffect } from 'react';
import { Layout, Badge, Row, Col, Affix, Tabs } from 'antd';
import styled from 'styled-components/macro';
import { InboxOutlined, FileDoneOutlined, LoadingOutlined } from '@ant-design/icons';
import { AccountingInboxFetcher } from './AccountingInboxFetcher.js';
import { AccountingObligationsViewer } from './AccountingObligationsViewer.js';
import { authContext } from '../../ProvideAuth.js';
import { CommentModal } from './CommentModal.js';
import HeroAxios from '../../helpers/HeroAxios.js';

const { Content } = Layout;
const { TabPane } = Tabs;

function AccountingInboxView(props) {
  const auth = useContext(authContext);
  const [refresh, setRefresh] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentModalSotTxn, setCommentModalSotTxn] = useState(null);
  const [obligationsCount, setObligationsCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [activeComponent, setActiveComponent] = useState('dudas');
  const [accountingClientCompany, setAccountingClientCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch obligations and doubts counts
    const fetchCounts = async () => {
      try {
        const obligationsResponse = await HeroAxios.post('getAccountingObligationsCount', {});
        const obligationsCounts = obligationsResponse.data.counts;
        const totalDueObligations = Object.values(obligationsCounts).reduce(
          (sum, clientCounts) => sum + (clientCounts.due_obligations || 0),
          0
        );
        setObligationsCount(totalDueObligations);

        const doubtsResponse = await HeroAxios.get('getFlaggedUncommentedSotTxnsCount');
        const doubtsCounts = doubtsResponse.data.counts;
        const totalCommentsCount = Object.values(doubtsCounts).reduce(
          (sum, count) => sum + (count || 0),
          0
        );
        setCommentsCount(totalCommentsCount);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    // Fetch company info
    const fetchCompanyInfo = async () => {
      try {
        const companyResponse = await HeroAxios.post('getUserAccountingClientCompanyInfo', {});
        const {
          accounting_client_id,
          accounting_client_company_id,
          name,
          ruc,
          logo_image_url,
        } = companyResponse.data;

        const companyInfo = {
          accounting_client_id,
          accounting_client_company_id,
          name,
          ruc,
          logo_image_url,
        };

        setAccountingClientCompany(companyInfo);
      } catch (error) {
        console.error('Error fetching accounting client:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCounts();
    fetchCompanyInfo();
  }, [refresh]);

  const renderComponent = () => {
    if (activeComponent === 'dudas') {
      return (
        <AccountingInboxFetcher
          API_domain={props.API_domain}
          refresh={refresh}
          setRefresh={setRefresh}
          setShowCommentModal={setShowCommentModal}
          setCommentModalSotTxn={setCommentModalSotTxn}
        />
      );
    } else if (activeComponent === 'obligations') {
      return (
        <AccountingObligationsViewer
          API_domain={props.API_domain}
          refresh={refresh}
          setRefresh={setRefresh}
          setShowCommentModal={setShowCommentModal}
          setCommentModalSotTxn={setCommentModalSotTxn}
          accountingClientCompanyInfo={accountingClientCompany}
        />
      );
    }
  };

  const items = [
    {
      key: 'dudas',
      label: (
        <span>
          <InboxOutlined style={{ fontSize: '16px' }} />
          Dudas <StyledBadge count={commentsCount} showZero />
        </span>
      ),
      children: renderComponent(),
    },
    {
      key: 'obligations',
      label: (
        <span>
          <FileDoneOutlined style={{ fontSize: '16px' }} />
          Obligaciones <StyledBadge count={obligationsCount} showZero />
        </span>
      ),
      children: renderComponent(),
    },
  ];

  return (
    <>
      <CommentModal
        visible={showCommentModal}
        sotTxn={commentModalSotTxn}
        onClose={() => setShowCommentModal(false)}
        onSubmit={() => {
          setShowCommentModal(false);
          setRefresh(!refresh);
        }}
        API_domain={props.API_domain}
      />

      <ContentWrapper>
        {loading ? (
          <LoadingDiv>{antIcon}</LoadingDiv>
        ) : (
          <>
            <Affix>
              <AffixDiv>
                <CompanyTitle>
                  {accountingClientCompany?.logo_image_url && (
                    <img
                      src={accountingClientCompany.logo_image_url}
                      alt="Company Logo"
                      style={{
                        width: '100px',
                        objectFit: 'contain',
                        borderRadius: '0px',
                        marginRight: '2px',
                        marginBottom: '0px',
                      }}
                    />
                  )}
                  {accountingClientCompany?.name}
                </CompanyTitle>
              </AffixDiv>
            </Affix>

            <TitleDiv>
              <Row>
                <Col xs={24} md={24}>
                  <SectionTitle style={{ paddingLeft: '8px' }}>Inbox</SectionTitle>
                </Col>
              </Row>
            </TitleDiv>

            <Tabs
              activeKey={activeComponent}
              onChange={setActiveComponent}
              type="line"
              style={{ paddingLeft: '30px', paddingRight: '24px' }}
            >
              {items.map((item) => (
                <TabPane tab={item.label} key={item.key}>
                  <StyledContent>{item.children}</StyledContent>
                </TabPane>
              ))}
            </Tabs>
          </>
        )}
      </ContentWrapper>
    </>
  );
}

// Styled components
const ContentWrapper = styled(Content)`
  margin: 0;
  overflow: initial;
  border-right: solid rgb(235, 235, 235) 1px;
`;

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 18px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 0px;
  text-align: left;
  border-bottom: 1px solid var(--grey-light);
`;

const CompanyTitle = styled.h1`
  font-size: 2.5em;
  font-weight: bold;
  color: var(--grey-darkest);
  display: flex;
  align-items: center;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const TitleDiv = styled.div`
  color: var(--grey-darkest);
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const SectionTitle = styled.h2`
  font-size: 1.75em;
  font-weight: bold;
  margin: 0;
`;

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    background-color: var(--grey-light);
    color: var(--purple-dark);
    box-shadow: 0 0 0 0px var(--grey-dark);
    margin-left: 4px;
    position: relative;
    top: -1px;
  }

  display: inline-block;
`;

const StyledContent = styled.div`
  padding: 5px 16px 16px 5px;
  background-color: #ffffff;
  min-height: 280px;

  @media (max-width: 768px) {
    padding: 5px 16px 16px 5px;
  }
`;

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
  text-align: center;
`;

export { AccountingInboxView };
