import { Button, Table, Affix, Tooltip } from 'antd';
import {
  DownloadOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import React, { useContext, useState, useMemo, useEffect } from 'react';
import { FormattedUSD } from '../FormattedUSD.js';
import { AccountingFinancialStatementsModal } from './AccountingFinancialStatementsModal.js';
import * as XLSX from 'xlsx';
import { authContext } from '../../ProvideAuth.js';
import { DefaultVisibleTooltip } from '../DefaultVisibleTooltip.js';
import { formatDateSpanishMed } from '../../utils';
import axios from 'axios';

const BalanceSheetTable2 = (props) => {
  const auth = useContext(authContext);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const itemsPerPage = 5;

  // Ensure time periods are in chronological order
  const sortedTimePeriods = useMemo(() => {
    return [...props.balanceSheetRecentTimePeriods];
  }, [props.balanceSheetRecentTimePeriods]);

  const totalMonths = sortedTimePeriods.length;
  const maxStartIndex = Math.max(totalMonths - itemsPerPage, 0);

  // Set initial startIndex to display the most recent 5 months
  const [startIndex, setStartIndex] = useState(() => {
    return totalMonths > itemsPerPage ? totalMonths - itemsPerPage : 0;
  });

  // Ensure startIndex is updated if totalMonths changes
  useEffect(() => {
    setStartIndex(totalMonths > itemsPerPage ? totalMonths - itemsPerPage : 0);
  }, [totalMonths, itemsPerPage]);

  // Calculate the time periods to display based on startIndex
  const displayedTimePeriods = useMemo(() => {
    return sortedTimePeriods.slice(startIndex, startIndex + itemsPerPage);
  }, [startIndex, sortedTimePeriods, itemsPerPage]);

  // Adjust the balanceSheetData to align with the sorted time periods
  const sortedBalanceSheetData = useMemo(() => {
    const alignValues = (node) => {
      const alignedNode = { ...node };
      // Ensure the values array matches the sortedTimePeriods length
      alignedNode.values = [...node.values];
      if (alignedNode.values.length !== sortedTimePeriods.length) {
        console.warn(
          `Mismatch in number of values for account_id ${alignedNode.account_id}`
        );
      }
      return alignedNode;
    };

    return props.balanceSheetData.map((node) => alignValues(node));
  }, [props.balanceSheetData, sortedTimePeriods]);

  // Adjust the data source to include only the values for the displayed months
  const createDataSource = (nodes) => {
    const traverseNodes = (nodes, level = 1) => {
      return nodes.map((node) => {
        const slicedValues = node.values.slice(
          startIndex,
          startIndex + itemsPerPage
        );
        const newNode = {
          key: node.key,
          category: node.category,
          subcategory: node.subcategory,
          values: slicedValues,
          account_id: node.account_id,
        };
        slicedValues.forEach((value, index) => {
          newNode[`value${index + 1}`] = value;
        });

        if (node.children && node.children.length > 0) {
          newNode.children = traverseNodes(node.children, level + 1);
        }

        return newNode;
      });
    };

    return traverseNodes(nodes);
  };

  const dataSource = useMemo(
    () => createDataSource(sortedBalanceSheetData),
    [sortedBalanceSheetData, startIndex]
  );

  // Function to check if a record is a parent with children and expanded
  const isParentBold = (record) => {
    if (record.children && record.children.length > 0) {
      return expandedKeys.includes(record.key);
    }
    return false;
  };

  const handleExpand = (expanded, record) => {
    setExpandedKeys((prevKeys) => {
      if (expanded) {
        return [...prevKeys, record.key];
      } else {
        return prevKeys.filter((key) => key !== record.key);
      }
    });
  };

  const handleCollapseAll = () => {
    setExpandedKeys([]);
  };

  const handleExpandAll = () => {
    const getAllKeys = (nodes) => {
      let keys = [];
      nodes.forEach((node) => {
        keys.push(node.key);
        if (node.children) {
          keys = keys.concat(getAllKeys(node.children));
        }
      });
      return keys;
    };
    setExpandedKeys(getAllKeys(sortedBalanceSheetData));
  };

  const showModal = (record, month) => {
    setModalContent({ ...record, month });
    setIsModalVisible(true);
  };

  const createColumns = (timePeriodsWithMostRecentTransaction) => {
    const valueColumns =
      displayedTimePeriods.length > 0
        ? displayedTimePeriods.map((period, index) => {
            const mostRecentTransactionTime =
              timePeriodsWithMostRecentTransaction && period
                ? timePeriodsWithMostRecentTransaction[period]
                : null;
            const formattedTransactionTime = mostRecentTransactionTime
              ? formatDateSpanishMed(mostRecentTransactionTime)
              : 'No hay datos de transacción más reciente';

            return {
              title: (
                <DefaultVisibleTooltip
                  title={<>Actualizado el {formattedTransactionTime}</>}
                  children={
                    <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {period || `Value ${index + 1}`}
                    </div>
                  }
                  // defaultVisible = true only if this is the most recent month
                  defaultVisible={
                    period === sortedTimePeriods[sortedTimePeriods.length - 1]
                  }
                />
              ),
              dataIndex: `value${index + 1}`,
              key: `value${index + 1}`,
              render: (value, record) =>
                value !== undefined && !record.children?.length ? (
                  record.subcategory !== 'UTILIDAD NETA' ? (
                    <Button
                      type='link'
                      className='hyperlink'
                      onClick={() =>
                        showModal(record, period || `Value ${index + 1}`)
                      }
                      style={{
                        width: '100%',
                        textAlign: 'right',
                        paddingRight: 0,
                      }}
                    >
                      <div style={{ display: 'block', textAlign: 'right' }}>
                        <FormattedUSD total={value} />
                      </div>
                    </Button>
                  ) : (
                    <span
                      style={{
                        fontWeight: isParentBold(record) ? 'bold' : 'normal',
                        textAlign: 'right',
                        display: 'block',
                      }}
                    >
                      <FormattedUSD total={value} />
                    </span>
                  )
                ) : value !== undefined ? (
                  <span
                    style={{
                      fontWeight: isParentBold(record) ? 'bold' : 'normal',
                      textAlign: 'right',
                      display: 'block',
                    }}
                  >
                    <FormattedUSD total={value} />
                  </span>
                ) : (
                  ''
                ),
            };
          })
        : [];

    return [
      {
        title: 'Cuenta',
        dataIndex: 'subcategory',
        key: 'subcategory',
        width: 400,
        render: (text, record) => (
          <span
            style={{
              fontWeight: isParentBold(record) ? 'bold' : 'normal',
            }}
          >
            {text}
          </span>
        ),
      },
      ...valueColumns,
    ];
  };

  const columns = useMemo(
    () => createColumns(props.timePeriodsWithMostRecentTransaction),
    [
      sortedBalanceSheetData,
      displayedTimePeriods,
      expandedKeys,
      props.timePeriodsWithMostRecentTransaction,
    ]
  );

  const exportToExcel = () => {
    const traverseNodes = (nodes, level = 0, result = []) => {
      nodes.forEach((node) => {
        const row = {
          Categoría: ' '.repeat(level * 4) + node.subcategory,
        };
        sortedTimePeriods.forEach((period, idx) => {
          row[period || `Value ${idx + 1}`] = node.values[idx];
        });
        result.push(row);

        if (node.children && node.children.length > 0) {
          traverseNodes(node.children, level + 1, result);
        }
      });

      return result;
    };

    const flattenedData = [];

    // Separate nodes by category and add empty line between categories
    ['Assets', 'Liabilities', 'Equity'].forEach((category) => {
      const categoryNodes = sortedBalanceSheetData.filter(
        (node) => node.category === category
      );
      if (categoryNodes.length > 0) {
        flattenedData.push(...traverseNodes(categoryNodes));
        flattenedData.push({}); // Add empty line
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Balance Sheet');

    XLSX.writeFile(workbook, 'balance_sheet.xlsx');

    const trackingData = {
      event: 'exportBalanceSheetToExcel',
      properties: {},
    };
    axios({
      method: 'post',
      url: props.API_domain + 'trackFrontEndEvent',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: trackingData,
    })
      .then((response) => {
        console.log('Event tracked:', response);
      })
      .catch((error) => {
        console.error('Error tracking event:', error);
      });
  };

  // Sliding Window Handlers
  const handlePrevious = () => {
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setStartIndex((prev) => Math.min(prev + 1, maxStartIndex));
  };

  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <Affix offsetTop={0}>
        <div
          style={{
            marginBottom: '10px',
            backgroundColor: 'white',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap', // Allows wrapping on smaller screens
          }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Button style={{ margin: 4 }} onClick={handleCollapseAll}>
              <FullscreenExitOutlined />
              Colapsar
            </Button>
            <Button style={{ margin: 4 }} onClick={handleExpandAll}>
              <FullscreenOutlined />
              Expandir
            </Button>
          </div>
          <div>
            <Button
              style={{ marginLeft: 12, textAlign: 'right' }}
              onClick={exportToExcel}
            >
              <DownloadOutlined /> Excel
            </Button>
          </div>
        </div>
      </Affix>

      {/* Pagination Controls */}
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Button
          icon={<LeftOutlined />}
          onClick={handlePrevious}
          disabled={startIndex === 0}
          style={{ marginRight: '8px' }}
        >
          Más viejo
        </Button>
        <Button
          icon={<RightOutlined />}
          onClick={handleNext}
          disabled={startIndex >= maxStartIndex}
          style={{ marginLeft: '8px' }}
        >
          Más reciente
        </Button>
      </div>

      <div style={{ overflowX: 'auto' }}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          expandable={{
            expandedRowKeys: expandedKeys,
            onExpand: handleExpand,
          }}
          scroll={{ x: 'max-content' }} // Enable horizontal scrolling with dynamic width
          style={{
            width: '100%', // Make the table take full width of its container
            minWidth: '800px', // Ensure a minimum width to prevent layout issues
          }}
          rowKey='key'
        />
      </div>

      <AccountingFinancialStatementsModal
        API_domain={props.API_domain}
        accountingClientCompany={props.accountingClientCompany}
        modalContent={modalContent}
        isModalVisible={isModalVisible}
        title='Additional Information'
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => {
          setIsModalVisible(false);
          setModalContent({});
        }}
      />
    </div>
  );
};

export { BalanceSheetTable2 };
