import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import { formatDatesList } from './components/Admin/AdminZohoCorrectDateReturner.js';
import {
  verticalProviders,
  asyrProviders,
  twoOceansProviders,
  refripolarProviders,
  mcgowenProviders,
  pcaCevicheriaProviders,
  pcaBlueAdventureProviders,
  pcaGlobalLoungeProviders,
  grProviders,
  nsolarProviders,
} from './downloaderProviders';

export const tildeRemover = (stringWithTildes) => {
  return stringWithTildes.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const filterCreator = (data, column) => {
  const columnSet = Array.from(new Set(data.map((item) => item[column])));
  return columnSet.map((item) => ({
    text: item,
    value: item,
  }));
};

export const convertToIntDateFormat = (dateString) => {
  return new Intl.DateTimeFormat('es-US', {
    dateStyle: 'medium',
  }).format(
    new Date(
      dateString.split('-')[0],
      dateString.split('-')[1] - 1,
      dateString.split('-')[2]
    )
  );
};

export const tenColorArray = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(100, 150, 200, 1)',
  'rgba(200, 10, 50, 1)',
  'rgba(124, 250, 250, 1)',
  'rgba(180, 50, 150, 1)',
  'rgba(1, 250, 150, 1)',
];

export const twentyTwoColorArray = [
  '#ecede3',
  '#dea06d',
  '#955e42',
  '#58382d',
  '#1b5b16',
  '#399a16',
  '#85d41b',
  '#e6d434',
  '#e98e1e',
  '#cb4d1e',
  '#99231d',
  '#6edcd6',
  '#3b9fda',
  '#1c53ab',
  '#242479',
  '#6d2597',
  '#c141c9',
  '#ec80ca',
  '#b2bbb9',
  '#6a6f76',
  '#383940',
  '#121214',
];

export const fourtySixColorArray = [
  '  #000000',
  '#beffe0',
  '#a2d9be',
  '#91c1be',
  '#7fa8b5',
  '#628397',
  '#ead4aa',
  '#e8b796',
  '#d9ac9d',
  '#c99fa2',
  '#b08b9d',
  '#98ff6a',
  '#83de6d',
  '#7bc17f',
  '#6dab8f',
  '#578886',
  '#ffa296',
  '#f58b83',
  '#f6757a',
  '#d8677a',
  '#b6587c',
  '#ff65ed',
  '#cf53cf',
  '#ae45d1',
  '#9a3dcc',
  '#7c30c8',
  '#fffd95',
  '#d2d29c',
  '#b2b2a4',
  '#9898ab',
  '#78789e',
  '#aec2bb',
  '#97a8bd',
  '#8494c0',
  '#6975b2',
  '#4c549b',
  '#ffc337',
  '#feae34',
  '#e39a3b',
  '#be8042',
  '#9e6a43',
  '#ff964e',
  '#ff8643',
  '#ff6932',
  '#d15842',
  '#b14a50',
];

function rotateList(lst, n) {
  return lst.slice(n).concat(lst.slice(0, n));
}

export function get_last_12_months_strings() {
  const monthStrings = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const rotatedMonthStrings = rotateList(monthStrings, moment().month() + 1);
  const currentYear = moment().year();
  const lastYear = currentYear - 1;

  return rotatedMonthStrings.map((month, index) => {
    const year =
      index >= rotatedMonthStrings.length - moment().month() - 1
        ? currentYear
        : lastYear;
    return `${month} ${year}`;
  });
}

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

//Map from english month name to spanish month name
export const monthNameMap = {
  January: 'Enero',
  February: 'Febrero',
  March: 'Marzo',
  April: 'Abril',
  May: 'Mayo',
  June: 'Junio',
  July: 'Julio',
  August: 'Agosto',
  September: 'Septiembre',
  October: 'Octubre',
  November: 'Noviembre',
  December: 'Diciembre',
};
const getPast12Months = () => {
  let currentMonth = moment().startOf('month');
  const months_to_days = {};
  for (let i = 0; i < 12; i++) {
    const monthName = monthNameMap[currentMonth.format('MMMM')];
    months_to_days[monthName] = [
      moment(currentMonth).startOf('month'),
      moment(currentMonth).endOf('month'),
    ];
    currentMonth = currentMonth.subtract(1, 'month');
  }
  return months_to_days;
};
const months_to_days = getPast12Months();
export const get_moments_from_month_name = (month) => {
  return months_to_days[month];
};

export function getPreviousThreeMonths() {
  const months = [];
  let currentMonth = moment().startOf('month'); // Using moment.js

  for (let i = 0; i < 3; i++) {
    const monthName = monthNameMap[currentMonth.format('MMMM')]; // Translate to Spanish
    months.push(monthName);
    currentMonth = currentMonth.subtract(1, 'month');
  }

  return months;
}

export function getPreviousFourMonths() {
  const months = [];
  let currentMonth = moment().startOf('month'); // Using moment.js

  for (let i = 0; i < 4; i++) {
    const monthName = monthNameMap[currentMonth.format('MMMM')]; // Translate to Spanish
    months.push(monthName);
    currentMonth = currentMonth.subtract(1, 'month');
  }

  return months;
}

export const getLastYear = () => {
  const startOfLastYear = moment().subtract(1, 'year').startOf('year');
  const endOfLastYear = moment().subtract(1, 'year').endOf('year');
  return [startOfLastYear, endOfLastYear];
};

export const excelDateToJSDate = (serial) => {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor((total_seconds - hours * 60 * 60) / 60);

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
};

export const downloadBlob = (blob, name) => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
};

export const rucTypeCalculator = (ruc) => {
  if (ruc == null || ruc.length < 3) {
    return '';
  }
  if (ruc.substring(0, 1) == 'E' || ruc.substring(0, 2) == 'PE') {
    return 'E';
  }
  if (ruc.length > 10) {
    return 'J';
  }
  return 'N';
};

export const rounder = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const get_informe43_blob = (gastos) => {
  for (var r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms']) || gastos[r]['itbms'] <= 0) {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    rowData = [
      rucTypeCalculator(gasto['ruc']),
      gasto['ruc'],
      gasto['dv'],
      '"' + gasto['provider'].replaceAll('"', '""') + '"',
      gasto['receipt_number'],
      gasto['fecha'].split('/')[0] +
        gasto['fecha'].split('/')[1] +
        gasto['fecha'].split('/')[2], // Date
      '',
      '',
      +gasto['total'] - +gasto['itbms'],
      gasto['itbms'],
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // Descripcion
    ].join(',');
    csvRows.push(rowData);
  }

  var gastos_csv =
    'SEP=,\n' +
    'Tipo,RUC,DV,Proveedor,Num Factura,Fecha,Concepto(1 al 7),Local/Importacion(1 o 2),Monto,ITBMS,Descripcion(BORRAR)\n' +
    csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);

  return gastos_blob;
};

export const get_peachtree_blob = (gastos) => {
  var r;
  var numberOfDistributions = 1;
  for (r = 0; r < gastos.length; r++) {
    numberOfDistributions += 1;
    if (!isNaN(gastos[r]['itbms']) && gastos[r]['itbms'] > 0) {
      numberOfDistributions += 1;
    } else {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    rowData = [
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Date
      gasto['title'], // Reference
      numberOfDistributions, // Number of Distributions
      '', // G/L Account
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // Description
      +gasto['total'] - +gasto['itbms'],
      '', // Job ID
    ].join(',');
    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms']) {
      rowData = [
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // Date
        gasto['title'], // Reference
        numberOfDistributions, // Number of Distributions
        '', // G/L Account
        '"' + 'ITBMS de ' + gasto['descripcion'].replaceAll('"', '""') + '"', // Description
        gasto['itbms'],
        '', // Job ID
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv =
    'SEP=,\n' +
    'Date,Reference,Number of Distributions,G/L Account,Description,Amount,Job ID\n' +
    csvRows.join('\n');

  const last_expense = gastos[gastos.length - 1];
  const counter_positive_row =
    '\n' +
    [
      last_expense['fecha'].split('/')[1] +
        '/' +
        last_expense['fecha'].split('/')[2] +
        '/' +
        last_expense['fecha'].split('/')[0].substring(2, 4), // Date
      gasto['title'], // Reference
      numberOfDistributions, // Number of Distributions
      '', // G/L Account
      'Contra Positiva', // Description
      gastos.reduce((total, gasto) => total - parseFloat(gasto.total), 0), // Amount
      '', // Job ID
    ].join(',');
  gastos_csv += counter_positive_row;
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_quickbooks_blob = (gastos) => {
  var header = [
    '*BillNo',
    '*Supplier',
    '*BillDate',
    '*DueDate',
    'Terms',
    'Location',
    'Memo',
    '*Account',
    'LineDescription',
    '*LineAmount',
    'Currrency',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    rowData = [
      gasto['receipt_number'],
      '"' + gasto['provider'].replaceAll('"', '""') + '"', // Supplier
      gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0], // BillDate
      gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0], // DueDate
      '', // Terms
      '', // Location
      '', // Memo
      '"' + gasto['category'] ? gasto['category'] : '' + '"', // Account
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // LineDescription
      +gasto['total'], // LineAmount
      'USD', // Currrency
    ].join(',');

    csvRows.push(rowData);
  }

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_zoho_expenses_blob = (gastos) => {
  var header = [
    'Entry Number',
    'Expense Date',
    'Expense Account',
    'Paid Through',
    'Vendor',
    'Expense Description',
    'Currency Code',
    'Exchange Rate',
    'Expense Amount',
    'Tax Name',
    'Tax Percentage',
    'Is Inclusive Tax',
    'Is Billable',
    'Customer Name',
    'Reference#',
    'Project Name',
    'Mileage Rate',
    'Distance',
    'Start Odometer Reading',
    'End Odometer Reading',
    'Mileage Unit',
    'Mileage Type',
    'Employee Email',
    'Expense Reference ID',
    'Tax Type',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    rowData = [
      i + 1, //'Entry Number',
      gasto['fecha'], //'Expense Date',
      'Other Expenses', //'Expense Account',
      'Petty Cash', //'Paid Through',
      '"' + gasto['provider'].replaceAll('"', '""') + '"', //'Vendor',
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', //'Expense Description',
      'USD', //'Currency Code',
      1, //'Exchange Rate',
      +gasto['total'] - +gasto['itbms'], //'Expense Amount',
      'ITBMS', //'Tax Name',
      0, //'Tax Percentage',
      'TRUE', //'Is Inclusive Tax',
      'FALSE', //'Is Billable',
      '', //'Customer Name',
      gasto['receipt_number'], //'Reference#',
      '', //'Project Name',
      '', //'Mileage Rate',
      '', //'Distance',
      '', //'Start Odometer Reading',
      '', //'End Odometer Reading',
      '', //'Mileage Unit',
      'NonMileage', //'Mileage Type',
      '', //'Employee Email',
      '', //'Expense Reference ID',
      'ItemAmount', //'Tax Type',
    ].join(',');
    csvRows.push(rowData);

    // check if row has ITBMS
    if (+gasto['itbms'] > 0) {
      rowData = [
        i + 1, //'Entry Number',
        gasto['fecha'], //'Expense Date',
        'Tax Payable', //'Expense Account',
        'Petty Cash', //'Paid Through',
        '"' + gasto['provider'].replaceAll('"', '""') + '"', //'Vendor',
        '"' + 'ITBMS de ' + gasto['descripcion'].replaceAll('"', '""') + '"', //'Expense Description',
        'USD', //'Currency Code',
        1, //'Exchange Rate',
        gasto['itbms'], //'Expense Amount',
        'ITBMS', //'Tax Name',
        0, //'Tax Percentage',
        'TRUE', //'Is Inclusive Tax',
        'FALSE', //'Is Billable',
        '', //'Customer Name',
        gasto['receipt_number'], //'Reference#',
        '', //'Project Name',
        '', //'Mileage Rate',
        '', //'Distance',
        '', //'Start Odometer Reading',
        '', //'End Odometer Reading',
        '', //'Mileage Unit',
        'NonMileage', //'Mileage Type',
        '', //'Employee Email',
        '', //'Expense Reference ID',
        'ItemAmount', //'Tax Type',
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_expenses_zoho_thorough_blob = (gastos) => {
  var header = [
    'Entry Number',
    'Expense Date',
    'Expense Account',
    'Paid Through',
    'Vendor',
    'Expense Description',
    'Currency Code',
    'Exchange Rate',
    'Expense Amount',
    'Tax Name',
    'Tax Percentage',
    'Is Inclusive Tax',
    'Is Billable',
    'Customer Name',
    'Reference#',
    'Project Name',
    'Mileage Rate',
    'Distance',
    'Start Odometer Reading',
    'End Odometer Reading',
    'Mileage Unit',
    'Mileage Type',
    'Employee Email',
    'Expense Reference ID',
    'Tax Type',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    rowData = [
      i + 1, //'Entry Number',
      gasto['fecha'], //'Expense Date',
      '"' + gasto['category'] ? gasto['category'] : '' + '"', // 'Expense Account' Categoria segun Hero
      '**LLENAR CORRECTAMENTE**', //'Paid Through',
      '"' + gasto['provider'].replaceAll('"', '""') + '"', //'Vendor',
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', //'Expense Description',
      'USD', //'Currency Code',
      1, //'Exchange Rate',
      +gasto['total'], //'Expense Amount' includes ITBMS,
      'ITBMS', //'Tax Name',
      0, //'Tax Percentage',
      'TRUE', //'Is Inclusive Tax',
      'FALSE', //'Is Billable',
      '', //'Customer Name',
      gasto['receipt_number'], //'Reference#',
      '', //'Project Name',
      '', //'Mileage Rate',
      '', //'Distance',
      '', //'Start Odometer Reading',
      '', //'End Odometer Reading',
      '', //'Mileage Unit',
      'NonMileage', //'Mileage Type',
      '', //'Employee Email',
      '', //'Expense Reference ID',
      'ItemAmount', //'Tax Type',
    ].join(',');
    csvRows.push(rowData);
  }

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_zoho_manual_entries_from_csv_blob = (csvData) => {
  var header = [
    'Journal Date',
    'Reference Number',
    'Journal Number Prefix',
    'Journal Number Suffix',
    'Notes',
    'Journal Type',
    'Currency',
    'Account',
    'Description',
    'Contact Name',
    'Debit',
    'Credit',
    'Project Name',
  ].join(',');
  var csvRows = [];
  var i;
  var creditRow;
  var debitRow;
  var itbmsRow;
  var csvDataRow;
  var randomFiveDigits;
  var itbmsAccount;
  var amount;
  // each row in csvData looks like: fecha, credit_account, debit_account, notas, monto, itbms (optional)
  // for each row in csv data we want to insert 2-3 rows in the zoho csv
  for (i = 0; i < csvData.length - 1; i++) {
    randomFiveDigits = Math.floor(100000 + Math.random() * 900000);
    csvDataRow = csvData[i + 1];
    //transform csvdatarow[4] to a number with 2 decimals
    amount = parseFloat(csvDataRow[4]);
    amount = Math.round(amount * 100) / 100;

    creditRow = [
      csvDataRow[0], //'Journal Date',
      '"' + csvDataRow[3] + '"', //'Description' //'Reference Number',
      'NoNecesario', //'Journal Number Prefix',
      randomFiveDigits.toString(), //'Journal Number Suffix',
      '"' + csvDataRow[3] + '"', //'Notes',
      'both', //'Journal Type',
      'USD', //'Currency',
      '"' + csvDataRow[1] + '"', //'Account',
      '"' + csvDataRow[3] + '"', //'Description',
      '', //'Contact Name',
      0, //'Debit',
      amount, //'Credit',
      '', //'Project Name',
    ].join(',');
    csvRows.push(creditRow);
    if (csvDataRow.length > 5) {
      itbmsAccount = csvDataRow[5];
      amount = Math.round((csvDataRow[4] / 1.07) * 100) / 100;
      itbmsRow = [
        csvDataRow[0], //'Journal Date',
        '"' + csvDataRow[3] + '"', //'Description' //'Reference Number',
        'NoNecesario', //'Journal Number Prefix',
        randomFiveDigits.toString(), //'Journal Number Suffix',
        '"' + csvDataRow[3] + '"', //'Notes',
        'both', //'Journal Type',
        'USD', //'Currency',
        '"' + itbmsAccount + '"', //'Account',
        '"' + csvDataRow[3] + '"', //'Description',
        '', //'Contact Name',
        Math.round(amount * 0.07 * 100) / 100, //'Debit',
        0, //'Credit',
        '', //'Project Name',
      ].join(',');
      csvRows.push(itbmsRow);
    }
    debitRow = [
      csvDataRow[0], //'Journal Date',
      '"' + csvDataRow[3] + '"', //'Description' //'Reference Number',
      'NoNecesario', //'Journal Number Prefix',
      randomFiveDigits.toString(), //'Journal Number Suffix',
      '"' + csvDataRow[3] + '"', //'Notes',
      'both', //'Journal Type',
      'USD', //'Currency',
      '"' + csvDataRow[2] + '"', //'Account',
      '"' + csvDataRow[3] + '"', //'Description',
      '', //'Contact Name',
      amount, //'Debit',
      0, //'Credit',
      '', //'Project Name',
    ].join(',');
    csvRows.push(debitRow);
  }

  var zoho_csv = header + '\n' + csvRows.join('\n');
  // gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', zoho_csv]);
  return gastos_blob;
};

export const get_zoho_manual_entries_from_csv_blob_FE_ventas = (
  csvData,
  itbmsAccount
) => {
  var header = [
    'Journal Date',
    'Reference Number',
    'Journal Number Prefix',
    'Journal Number Suffix',
    'Notes',
    'Journal Type',
    'Currency',
    'Account',
    'Description',
    'Contact Name',
    'Debit',
    'Credit',
    'Project Name',
  ].join(',');
  var csvRows = [];
  var i;
  var creditRow;
  var debitRow;
  var itbmsRow;
  var csvDataRow;
  var randomFiveDigits;
  var itbmsAccount;
  var amount;
  // each row in csvData looks like: fecha, credit_account, debit_account, notas, monto, itbms (optional)
  // for each row in csv data we want to insert 2-3 rows in the zoho csv
  for (i = 0; i < csvData.length - 1; i++) {
    randomFiveDigits = Math.floor(100000 + Math.random() * 900000);
    csvDataRow = csvData[i + 1];
    //transform csvdatarow[4] to a number with 2 decimals
    amount = parseFloat(csvDataRow[4]);
    amount = Math.round(amount * 100) / 100;

    creditRow = [
      csvDataRow[0], //'Journal Date',
      '"' + csvDataRow[3] + '"', //'Description' //'Reference Number',
      'NoNecesario', //'Journal Number Prefix',
      randomFiveDigits.toString(), //'Journal Number Suffix',
      '"' + csvDataRow[3] + '"', //'Notes',
      'both', //'Journal Type',
      'USD', //'Currency',
      '"' + csvDataRow[1] + '"', //'Account',
      '"' + csvDataRow[3] + '"', //'Description',
      '', //'Contact Name',
      amount, //'Debit',
      0, //'Credit',
      '', //'Project Name',
    ].join(',');
    csvRows.push(creditRow);

    // ITBMS part
    if (csvDataRow.length > 5 && csvDataRow[5] > 0) {
      // check if row has ITBMS. not all rows have itbms
      // itbmsAccount = csvDataRow[5];
      amount = Math.round((csvDataRow[4] / 1.07) * 100) / 100;
      itbmsRow = [
        csvDataRow[0], //'Journal Date',
        '"' + csvDataRow[3] + '"', //'Description' //'Reference Number',
        'NoNecesario', //'Journal Number Prefix',
        randomFiveDigits.toString(), //'Journal Number Suffix',
        '"' + csvDataRow[3] + '"', //'Notes',
        'both', //'Journal Type',
        'USD', //'Currency',
        '"' + itbmsAccount + '"', //'Account',
        '"' + csvDataRow[3] + '"', //'Description',
        '', //'Contact Name',
        0, // Math.round(amount * 0.07 * 100) / 100, //'Debit',
        Math.round(amount * 0.07 * 100) / 100, //'Credit',
        ,
        '', //'Project Name',
      ].join(',');
      csvRows.push(itbmsRow);
    }
    debitRow = [
      csvDataRow[0], //'Journal Date',
      '"' + csvDataRow[3] + '"', //'Description' //'Reference Number',
      'NoNecesario', //'Journal Number Prefix',
      randomFiveDigits.toString(), //'Journal Number Suffix',
      '"' + csvDataRow[3] + '"', //'Notes',
      'both', //'Journal Type',
      'USD', //'Currency',
      '"' + csvDataRow[2] + '"', //'Account',
      '"' + csvDataRow[3] + '"', //'Description',
      '', //'Contact Name',
      0, //'Debit',
      amount, //'Credit',
      '', //'Project Name',
    ].join(',');
    csvRows.push(debitRow);
  }

  var zoho_csv = header + '\n' + csvRows.join('\n');
  // gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', zoho_csv]);
  return gastos_blob;
};

export const get_zoho_manual_entries_from_csv_blob_FE_compras = (
  csvData,
  itbmsAccount
) => {
  var header = [
    'Journal Date',
    'Reference Number',
    'Journal Number Prefix',
    'Journal Number Suffix',
    'Notes',
    'Journal Type',
    'Currency',
    'Account',
    'Description',
    'Contact Name',
    'Debit',
    'Credit',
    'Project Name',
  ].join(',');
  var csvRows = [];
  var i;
  var creditRow;
  var debitRow;
  var itbmsRow;
  var csvDataRow;
  var randomFiveDigits;
  var itbmsAccount;
  var amount;
  // each row in csvData looks like: fecha, credit_account, debit_account, notas, monto, itbms (optional)
  // for each row in csv data we want to insert 2-3 rows in the zoho csv
  for (i = 0; i < csvData.length - 1; i++) {
    randomFiveDigits = Math.floor(100000 + Math.random() * 900000);
    csvDataRow = csvData[i + 1];
    //transform csvdatarow[4] to a number with 2 decimals
    amount = parseFloat(csvDataRow[4]);
    amount = Math.round(amount * 100) / 100;

    creditRow = [
      csvDataRow[0], //'Journal Date',
      '"' + csvDataRow[3] + '"', //'Description' //'Reference Number',
      'NoNecesario', //'Journal Number Prefix',
      randomFiveDigits.toString(), //'Journal Number Suffix',
      '"' + csvDataRow[3] + '"', //'Notes',
      'both', //'Journal Type',
      'USD', //'Currency',
      '"' + csvDataRow[2] + '"', //'Account',
      '"' + csvDataRow[3] + '"', //'Description',
      '', //'Contact Name',
      0, //'Credit',
      amount, //'Debit',
      '', //'Project Name',
    ].join(',');
    csvRows.push(creditRow);
    if (csvDataRow.length > 5) {
      // itbmsAccount = csvDataRow[5];
      amount = Math.round((csvDataRow[4] / 1.07) * 100) / 100;
      itbmsRow = [
        csvDataRow[0], //'Journal Date',
        '"' + csvDataRow[3] + '"', //'Description' //'Reference Number',
        'NoNecesario', //'Journal Number Prefix',
        randomFiveDigits.toString(), //'Journal Number Suffix',
        '"' + csvDataRow[3] + '"', //'Notes',
        'both', //'Journal Type',
        'USD', //'Currency',
        '"' + itbmsAccount + '"', //'Account',
        '"' + csvDataRow[3] + '"', //'Description',
        '', //'Contact Name',
        Math.round(amount * 0.07 * 100) / 100, //'Credit',
        0, // Math.round(amount * 0.07 * 100) / 100, //'Debit',
        ,
        '', //'Project Name',
      ].join(',');
      csvRows.push(itbmsRow);
    }
    debitRow = [
      csvDataRow[0], //'Journal Date',
      '"' + csvDataRow[3] + '"', //'Description' //'Reference Number',
      'NoNecesario', //'Journal Number Prefix',
      randomFiveDigits.toString(), //'Journal Number Suffix',
      '"' + csvDataRow[3] + '"', //'Notes',
      'both', //'Journal Type',
      'USD', //'Currency',
      '"' + csvDataRow[1] + '"', //'Account',
      '"' + csvDataRow[3] + '"', //'Description',
      '', //'Contact Name',
      amount, //'Credit',
      0, //'Debit',
      '', //'Project Name',
    ].join(',');
    csvRows.push(debitRow);
  }

  var zoho_csv = header + '\n' + csvRows.join('\n');
  // gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', zoho_csv]);
  return gastos_blob;
};

export const ECBGtoZohoCovertorInput = (data, bankName) => {
  function normalizeString(str) {
    return str
      .normalize('NFD') // This decomposes the string into its canonical decomposition
      .replace(/[\u0300-\u036f]/g, '') // This removes diacritics (like accents)
      .toLowerCase();
  }

  function fixEncoding(str) {
    return str
      .replace(/Ã¡/g, 'á')
      .replace(/Ã©/g, 'é')
      .replace(/Ã­/g, 'í')
      .replace(/Ã³/g, 'ó')
      .replace(/Ãº/g, 'ú')
      .replace(/Ã/g, 'Á')
      .replace(/Ã‰/g, 'É')
      .replace(/Ã/g, 'Í')
      .replace(/Ã“/g, 'Ó')
      .replace(/Ãš/g, 'Ú');
    // Add more replacements as needed
  }

  const correctedInputHeaders = data[0].map((header) =>
    fixEncoding(header.trim())
  ); // trim() to remove extra spaces, if any

  const normalizedHeaders = correctedInputHeaders.map((header) =>
    normalizeString(header)
  );

  const colIndices = {
    fecha: normalizedHeaders.indexOf(normalizeString('Fecha')),
    descripcion: normalizedHeaders.indexOf(normalizeString('Descripción')),
    debito: normalizedHeaders.indexOf(normalizeString('Débito')),
    credito: normalizedHeaders.indexOf(normalizeString('Crédito')),
    cuenta: normalizedHeaders.indexOf(normalizeString('CUENTA')),
  };

  const outputHeaders = [
    // for output
    'FECHA',
    'CREDIT_ACCOUNT',
    'DEBIT ACCOUNT',
    'NOTAS',
    'MONTO',
    'ITBMS',
  ];

  const excelDateToJSDate = (serial) => {
    const epoch = new Date(1899, 11, 31);
    const excelDate = new Date(epoch.getTime() + (serial - 1) * 86400000);
    return `${excelDate.getMonth() + 1}/${excelDate.getDate()}/${String(
      excelDate.getFullYear()
    ).slice(-2)}`;
  };

  const csvContent = [];

  // Push the CSV outputHeaders
  csvContent.push(outputHeaders.join(','));

  // Process each row of data
  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const fecha = excelDateToJSDate(row[colIndices.fecha]);
    const descripcion = row[colIndices.descripcion];
    const cuenta = row[colIndices.cuenta];
    const debito = row[colIndices.debito];
    const credito = row[colIndices.credito];

    let monto;
    let creditAccount;
    let debitAccount;
    if (debito > 0) {
      monto = debito;
      creditAccount = bankName;
      debitAccount = cuenta;
    } else if (credito > 0) {
      monto = credito;
      creditAccount = cuenta;
      debitAccount = bankName;
    } else {
      console.log('Error: no debito ni credito');
    }

    // Construct the CSV row
    const csvRow = [
      fecha,
      fixEncoding(creditAccount),
      fixEncoding(debitAccount),
      `"${descripcion}"`,
      monto,
      '',
    ];
    csvContent.push(csvRow.join(','));
  }

  // Convert the array of strings to a single string
  const csvString = csvContent.join('\n');

  // Convert the CSV string to a Blob
  const csvBlob = new Blob([csvString], { type: 'text/csv' });
  return csvBlob;
};

export const removeBlankRowsFromVentasZohoUploader = (csvData) => {
  const excelDateToJSDate = (serial) => {
    const epoch = new Date(1899, 11, 31);
    const excelDate = new Date(epoch.getTime() + (serial - 1) * 86400000);
    return `${excelDate.getMonth() + 1}/${excelDate.getDate()}/${String(
      excelDate.getFullYear()
    ).slice(-2)}`;
  };

  // Extract header and data
  const header = csvData[0];
  const dataRows = csvData.slice(1);

  // Find the indices of the Debit, Credit, and Journal Date columns
  const debitIndex = header.indexOf('Debit');
  const creditIndex = header.indexOf('Credit');
  const fechaIndex = header.indexOf('Journal Date');

  // Filter out rows where both Debit and Credit are 0, and skip completely empty rows
  const filteredRows = dataRows
    .filter((row) => {
      // Skip completely empty rows
      if (row.every((cell) => cell === '')) return false;

      // Skip rows where both Debit and Credit are 0
      const debit = parseFloat(row[debitIndex]);
      const credit = parseFloat(row[creditIndex]);
      return !(debit === 0 && credit === 0);
    })
    .map((row) => {
      // Convert Excel date to JS date
      const excelDate = row[fechaIndex];
      const jsDate = excelDateToJSDate(parseFloat(excelDate));
      row[fechaIndex] = jsDate;
      return row;
    });

  // Prepare the CSV content
  const csvRows = [header];
  csvRows.push(...filteredRows);
  const csvContent = csvRows.map((row) => row.join(',')).join('\n');

  // Create the Blob
  const csvBlob = new Blob(['\ufeff', csvContent], {
    type: 'text/csv;charset=utf-8',
  });

  return csvBlob;
};

export const prepareZohoPayload = (csvData) => {
  console.log(csvData);
  const journals = [];

  // Loop through csvData to populate the journals array
  for (let i = 0; i < csvData.length - 1; i++) {
    const csvDataRow = csvData[i + 1];
    const journalDate = '2023-08-31'; //csvDataRow[0];
    const notes = csvDataRow[3]; // Grabbing the notes from the csvData
    let amount = parseFloat(csvDataRow[4]);
    amount = Math.round(amount * 100) / 100;

    const debitAccountId = csvDataRow[2];
    const creditAccountId = csvDataRow[1];

    const journal = {
      journal_date: journalDate,
      reference_number: notes,
      line_items: [],
      notes: notes, // Using the dynamic notes value
    };

    // Add line items to the journal object
    journal.line_items.push({
      account_id: debitAccountId,
      debit_or_credit: 'debit',
      amount: amount,
    });

    journal.line_items.push({
      account_id: creditAccountId,
      debit_or_credit: 'credit',
      amount: amount,
    });

    if (csvDataRow.length > 5) {
      const itbmsAccount = csvDataRow[5];
      amount = parseFloat(csvDataRow[4]) / 1.07;
      amount = Math.round(amount * 100) / 100;

      journal.line_items.push({
        account_id: itbmsAccount,
        debit_or_credit: 'debit',
        amount: Math.round(amount * 0.07 * 100) / 100,
      });
    }

    // Add the journal object to the journals array
    journals.push(journal);
  }

  return journals;
};

export const get_blob_from_list_of_lists = (list_of_lists) => {
  var csvRows = [];
  var i;
  var rowData;
  for (i = 0; i < list_of_lists.length; i++) {
    rowData = list_of_lists[i].map((cell) => `"${cell}"`).join(',');
    csvRows.push(rowData);
  }

  var csv_data = csvRows.join('\n');
  const blob = new Blob(['\ufeff', csv_data]);
  return blob;
};

export const get_vaqueras_blob = (gastos) => {
  var header = [
    '*BillNo',
    '*Supplier',
    '*BillDate',
    '*DueDate',
    'Terms',
    'Location',
    'Memo',
    '*Account',
    'LineDescription',
    '*LineAmount',
    'Currrency',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributionsThisExpense = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    if (gasto['itbms'] != 0) {
      numberOfDistributionsThisExpense = 2;
    } else {
      numberOfDistributionsThisExpense = 1;
    }
    rowData = [
      gasto['receipt_number'],
      '"' + gasto['provider'].replaceAll('"', '""') + '"', // Supplier
      gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0], // BillDate
      gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0], // DueDate
      '', // Terms
      '', // Location
      '', // Memo
      '"' + gasto['category'] ? gasto['category'] : '' + '"', // Account
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // LineDescription
      +gasto['total'], // LineAmount
      'USD', // Currrency
    ].join(',');

    csvRows.push(rowData);
  }

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_vaqueras_cm_blob = (gastos) => {
  //"caja menuda" en vaqueras son gastos de la tarjeta de credito corporativa de marcial
  var header = [
    '*BillNo',
    '*Supplier',
    '*BillDate',
    '*DueDate',
    'Terms',
    'Location',
    'Memo',
    '*Account',
    'LineDescription',
    '*LineAmount',
    'Currrency',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributionsThisExpense = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    if (gasto['itbms'] != 0) {
      numberOfDistributionsThisExpense = 2;
    } else {
      numberOfDistributionsThisExpense = 1;
    }
    rowData = [
      '001',
      'TARJETA DE CREDITO DAM S.A', // Supplier
      '01' +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0], // BillDate
      '01' +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0], // DueDate
      '', // Terms
      '', // Location
      '', // Memo
      '"' + gasto['category'] ? gasto['category'] : '' + '"', // Account
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // LineDescription
      +gasto['total'], // LineAmount
      'USD', // Currrency
    ].join(',');

    csvRows.push(rowData);
  }

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_general_blob = (gastos) => {
  for (var r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms']) || gastos[r]['itbms'] <= 0) {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];

  var header =
    'Responsable del reporte: ' +
    tildeRemover(gastos[0]['owner']) +
    '\n' +
    'Email de responsable: ' +
    gastos[0]['owner_email'] +
    '\n' +
    tildeRemover(gastos[0]['title']) +
    '\n' +
    'Fecha de reporte: ' +
    gastos[0]['report_date'] +
    '\n' +
    'Comentarios: ' +
    tildeRemover(gastos[0]['comments']) +
    '\n';

  var i;
  var gasto;
  var rowData;
  var total_with_itbms = 0;
  var total_of_itbms = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    total_with_itbms += parseFloat(gasto['total']);
    total_of_itbms += parseFloat(gasto['itbms']);
    rowData = [
      i + 1,
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Date
      gasto['receipt_number'],
      gasto['ruc'],
      gasto['dv'],
      '"' + gasto['provider'] + '"',
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"',
      '"' + gasto['category'] ? gasto['category'] : '' + '"', // Categoria segun Hero
      gasto['card_transaction'] ? 'Sí' : 'No',
      gasto['card_atm_withdrawal'] ? 'Sí' : 'No',
      +gasto['total'] - gasto['itbms'],
      gasto['itbms'],
      +gasto['total'],
    ].join(',');
    csvRows.push(rowData);
  }

  var total_row =
    ' , , , , , , , , , ,' +
    rounder(total_with_itbms - total_of_itbms).toString() +
    ',' +
    rounder(total_of_itbms).toString() +
    ',' +
    rounder(total_with_itbms).toString();
  var gastos_csv =
    'SEP=,\n' +
    header +
    '\n' +
    'No.,FECHA,FACTURA No.,RUC/CEDULA,DV,PROVEEDOR,DESCRIPCION,CATEGORIA,TARJETA DE HERO,RETIRO DE CAJERO,SIN ITBMS,ITBMS,VALOR TOTAL\n' +
    csvRows.join('\n') +
    '\n' +
    total_row;
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_vitae_blob = (gastos) => {
  for (var r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms']) || gastos[r]['itbms'] <= 0) {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];

  var header =
    'Responsable de la caja: ' +
    tildeRemover(gastos[0]['owner']) +
    '\n' +
    'Email de responsable: ' +
    gastos[0]['owner_email'] +
    '\n' +
    tildeRemover(gastos[0]['title']) +
    '\n' +
    'Fecha de reporte: ' +
    gastos[0]['report_date'] +
    '\n' +
    'Comentarios: ' +
    tildeRemover(gastos[0]['comments']) +
    '\n';

  var i;
  var gasto;
  var rowData;
  var total_with_itbms = 0;
  var total_of_itbms = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    total_with_itbms += parseFloat(gasto['total']);
    total_of_itbms += parseFloat(gasto['itbms']);
    rowData = [
      i + 1,
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Date
      gasto['receipt_number'],
      gasto['ruc'],
      gasto['dv'],
      '"' + gasto['provider'] + '"',
      '"' + gasto['descripcion'].split(' - ')[0] + '"',
      '"' + gasto['descripcion'].split(' - ')[1]
        ? '"' + gasto['descripcion'].split(' - ')[1] + '"' // codigo Vitae
        : '' + '"',
      '"' + gasto['category'] ? gasto['category'] : '' + '"', // Categoria segun Hero
      +gasto['total'] - gasto['itbms'],
      gasto['itbms'],
      +gasto['total'],
    ].join(',');
    csvRows.push(rowData);
  }

  var total_row =
    ' , , , , , , , , ,' +
    rounder(total_with_itbms - total_of_itbms).toString() +
    ',' +
    rounder(total_of_itbms).toString() +
    ',' +
    rounder(total_with_itbms).toString();
  var gastos_csv =
    'SEP=,\n' +
    header +
    '\n' +
    'No.,FECHA,FACTURA No.,RUC/CEDULA,DV,PROVEEDOR,DESCRIPCION,CODIGO VITAE,CATEGORIA,SIN ITBMS,ITBMS,VALOR TOTAL\n' +
    csvRows.join('\n') +
    '\n' +
    total_row;
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_shared_general_blob = (gastos) => {
  for (var r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms']) || gastos[r]['itbms'] <= 0) {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];

  var i;
  var gasto;
  var rowData;
  var total_with_itbms = 0;
  var total_of_itbms = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    total_with_itbms += parseFloat(gasto['total']);
    total_of_itbms += parseFloat(gasto['itbms']);
    rowData = [
      i + 1,
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Date
      gasto['receipt_number'],
      gasto['ruc'],
      gasto['dv'],
      '"' + gasto['provider'] + '"',
      '"' + gasto['descripcion'].replace('"', '""') + '"',
      '"' + gasto['category'] ? gasto['category'] : '' + '"', // Categoria segun Hero
      gasto['card_transaction'] ? 'Sí' : 'No',
      gasto['card_atm_withdrawal'] ? 'Sí' : 'No',
      +gasto['total'] - gasto['itbms'],
      gasto['itbms'],
      +gasto['total'],
    ].join(',');
    csvRows.push(rowData);
  }

  var total_row =
    ' , , , , , , , , ,' +
    rounder(total_with_itbms - total_of_itbms).toString() +
    ',' +
    rounder(total_of_itbms).toString() +
    ',' +
    rounder(total_with_itbms).toString();
  var gastos_csv =
    'SEP=,\n' +
    'No.,FECHA,FACTURA No.,RUC/CEDULA,DV,PROVEEDOR,DESCRIPCION,CATEGORIA,TARJETA DE HERO,RETIRO EN CAJERO,SIN ITBMS,ITBMS,VALOR TOTAL\n' +
    csvRows.join('\n') +
    '\n' +
    total_row;
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_expenses_downloader_blob = (gastos) => {
  const includePaymentMethod = gastos.some((gasto) => gasto['payment_method']);

  const headers = [
    'No.,FECHA,GASTANTE,EQUIPO,FACTURA No.,RUC/CEDULA,DV,PROVEEDOR,DESCRIPCION,CATEGORIA,TARJETA DE HERO,RETIRO DE CAJERO,SIN ITBMS,ITBMS,VALOR TOTAL',
  ];
  if (includePaymentMethod) headers[0] += ',METODO DE PAGO';

  const gastos_rows = gastos.map(function (gasto, i) {
    const row = [
      i + 1,
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4),
      gasto['gastante'],
      gasto['team'],
      gasto['receipt_number'],
      gasto['ruc'],
      gasto['dv'],
      '"' + gasto['provider'] + '"',
      '"' + gasto['descripcion'].replace(/"/g, '""') + '"', // Ensure all quotes are escaped
      gasto['category']
        ? '"' + gasto['category'].replace(/"/g, '""') + '"'
        : '', // Properly escape category and enclose in quotes
      gasto['card_transaction'] ? 'Sí' : 'No',
      gasto['card_atm_withdrawal'] ? 'Sí' : 'No',
      (+gasto['total'] - gasto['itbms']).toFixed(2), // Formatting to two decimals if needed
      gasto['itbms'],
      (+gasto['total']).toFixed(2), // Formatting to two decimals if needed
    ];
    if (includePaymentMethod) row.push(gasto['payment_method'] || '');
    return row.join(',');
  });

  var gastos_csv =
    'SEP=,\n' + headers.join('\n') + '\n' + gastos_rows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_grupo_residencial_blob_01 = (gastos) => {
  for (var r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms']) || gastos[r]['itbms'] <= 0) {
      gastos[r]['itbms'] = 0;
    }
  }
  const CxP = gastos[0]['team'].includes('GR Property')
    ? '20104.01001'
    : gastos[0]['team'].includes('Inmobiliaria residencial')
    ? '01-2204.000'
    : '01-20104.01001';
  // populate csv rows
  var csvRows = [];
  console.log(gastos);
  var gasto;
  var rowData;
  var itbmsText;
  var total_with_itbms = 0;
  var total_of_itbms = 0;
  for (var i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    total_with_itbms += parseFloat(gasto['total']);
    total_of_itbms += parseFloat(gasto['itbms']);
    if (gasto['itbms'] > 0) {
      itbmsText = 'ITBMS';
    } else {
      itbmsText = 'SIN0%';
    }
    rowData = [
      'APD', // A
      ' ', // B
      ' ', // C
      ' ', // D
      ' ', // E
      ' ', // F
      gasto['descripcion'].split(' ', 1)[0], // G
      CxP, // H
      itbmsText, // I
      ' ', // J
      ' ', // K
      +gasto['total'], // L
      +gasto['itbms'], // M
      ' ', // N
      ' ', // O
      gasto['descripcion']
        .split(' ')
        .splice(1)
        .join(' ')
        .substring(0, 30)
        .replace('\n', ' '), // P
    ].join(',');
    csvRows.push(rowData);
  }

  var headerID = 'API'; //see Andy Sosa's picture denoting what each column is
  var headerVendor = tildeRemover(
    gastos[0]['owner'].split('-')[1].trim()
  ).toUpperCase();
  // var headerDescription = 'Import Invoice';
  var headerDescription = gastos[0]['title'];
  var headerAmount = rounder(total_with_itbms).toString();
  var headerTax = rounder(total_of_itbms).toString();
  var headerWhiteSpace = ' ';
  var headerComments = tildeRemover(gastos[0]['comments']);
  var headerDate =
    gastos[0]['report_date'].split('/')[1] +
    '/' +
    gastos[0]['report_date'].split('/')[2] +
    '/' +
    gastos[0]['report_date'].split('/')[0].substring(2, 4);

  var header = [
    headerID, //A
    headerVendor, //B
    headerDescription, //C
    headerComments, //D
    headerAmount, //E
    headerTax, //F
    headerWhiteSpace, //G
    headerWhiteSpace, //H
    headerDate, //I
    headerDate, //J
    headerWhiteSpace, //K
    headerDate, //L
  ].join(',');

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_grupo_residencial_blob_02 = (gastos) => {
  for (var r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms']) || gastos[r]['itbms'] <= 0) {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];

  var gasto;
  var rowData;
  var itbmsText;
  var total_with_itbms = 0;
  var total_of_itbms = 0;
  for (var i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    total_with_itbms += parseFloat(gasto['total']);
    total_of_itbms += parseFloat(gasto['itbms']);
    if (gasto['itbms'] > 0) {
      itbmsText = 'ITBMS';
    } else {
      itbmsText = 'SIN0%';
    }
    rowData = [
      'APD',
      ' ',
      ' ',
      gasto['descripcion'].split(' ', 2)[0],
      gasto['descripcion'].split(' ', 2)[1],
      '99',
      ' ',
      ' ',
      itbmsText,
      ' ',
      ' ',
      +gasto['total'],
      +gasto['itbms'],
      ' ',
      ' ',
      gasto['descripcion'].split(' ').splice(2).join(' ').substring(0, 30),
    ].join(',');
    csvRows.push(rowData);
  }

  var headerID = 'API'; //see Andy Sosa's picture denoting what each column is
  var headerVendor = tildeRemover(
    gastos[0]['owner'].split('-')[1].trim()
  ).toUpperCase();
  // var headerDescription = 'Import Invoice';
  var headerDescription = gastos[0]['title'];
  var headerAmount = rounder(total_with_itbms).toString();
  var headerTax = rounder(total_of_itbms).toString();
  var headerWhiteSpace = ' ';
  var headerComments = tildeRemover(gastos[0]['comments']);
  var headerDate =
    gastos[0]['report_date'].split('/')[1] +
    '/' +
    gastos[0]['report_date'].split('/')[2] +
    '/' +
    gastos[0]['report_date'].split('/')[0].substring(2, 4);

  var header = [
    headerID,
    headerVendor,
    headerDescription, //C
    headerComments, //D
    headerAmount,
    headerTax,
    headerWhiteSpace,
    headerWhiteSpace,
    headerDate,
    headerDate,
    headerWhiteSpace,
    headerDate,
  ].join(',');

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_grupo_residencial_blob_cards = (gastos) => {
  var csvRows = [];
  var gasto;
  var rowData;
  var itbmsText;
  var grProviderName;
  var expense_date;
  for (var i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    expense_date =
      gasto['fecha'].split('/')[1] +
      '/' +
      gasto['fecha'].split('/')[2] +
      '/' +
      gasto['fecha'].split('/')[0].substring(2, 4);
    grProviderName = grProviders[gasto['ruc']] || 'PROVEEDOR DESCONOCIDO';

    if (gasto['itbms'] > 0) {
      itbmsText = 'ITBMS';
    } else {
      itbmsText = 'SIN0%';
    }

    // API row
    rowData = [
      'API',
      grProviderName,
      gasto['receipt_number'],
      '',
      parseFloat(gasto['total']),
      gasto['itbms'],
      '',
      '',
      expense_date,
      expense_date,
      '',
      expense_date,
      '',
      '',
      '',
      '',
    ].join(',');

    csvRows.push(rowData);

    // APD row
    const accountNumber = gasto['descripcion'].split(' ')[0];
    const descriptionRemainder = gasto['descripcion']
      .split(' ')
      .slice(1)
      .join(' ');

    rowData = [
      'APD',
      '',
      '',
      '',
      '',
      '',
      accountNumber,
      '01-20104.01001',
      itbmsText,
      '',
      '',
      parseFloat(gasto['total']),
      gasto['itbms'],
      '',
      '',
      descriptionRemainder,
    ].join(',');

    csvRows.push(rowData);
  }

  var gastos_csv = 'SEP=,\n' + csvRows.join('\n');
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_riga_blob = (gastos, API_domain) => {
  var zip = new JSZip();
  for (var r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms']) || gastos[r]['itbms'] <= 0) {
      gastos[r]['itbms'] = 0;
    }
  }
  gastos.sort(function (a, b) {
    return a.id - b.id;
  });

  // populate csv rows
  var csvRows = [];
  // Sort gastos by insertion date
  // inside forloop download image of expense?
  // create zip

  var gasto;
  var rowData;
  var total_with_itbms = 0;
  var total_of_itbms = 0;
  var itemsProcessed = 0;
  for (var i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    total_with_itbms += parseFloat(gasto['total']);
    total_of_itbms += parseFloat(gasto['itbms']);
    rowData = [
      i + 1,
      gasto['fecha'],
      gasto['receipt_number'],
      ' ',
      ' ',
      '"' + gasto['provider'].replaceAll('"', '""') + '"',
      gasto['ruc'],
      gasto['dv'],
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"',
      +gasto['total'],
    ].join(',');
    csvRows.push(rowData);
  }
  var header = [
    'Numero',
    'Fecha',
    'Factura',
    'Actividad',
    'Insumo',
    'Proveedor',
    'RUC',
    'DV',
    'Descripcion',
    'Monto',
  ].join(',');

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  zip.file('gastos.csv', gastos_blob);

  gastos.forEach(function (gasto, index, array) {
    fetch(API_domain + 'downloadImage?url=' + gasto['factura']).then((r) => {
      var file_ending =
        r.headers.get('content-type') == 'application/pdf' ? '.pdf' : '.jpg';
      let filename =
        index +
        1 +
        ' ' +
        gasto['descripcion'].replaceAll('/', ' ') +
        file_ending;
      zip.file(filename, r.blob(), { binary: true });
      itemsProcessed++;
      if (itemsProcessed == array.length) {
        zip.generateAsync({ type: 'blob' }).then(
          function (blob) {
            saveAs(blob, 'heroFacturas.zip');
          },
          function (err) {
            console.log('Error is ', err);
          }
        );
      }
    });
  });
  return 'Nada';
};

export const get_concretex_blob = (gastos) => {
  var r;
  var numberOfDistributions = 0;
  for (r = 0; r < gastos.length; r++) {
    numberOfDistributions += 1;
    if (!isNaN(gastos[r]['itbms']) && gastos[r]['itbms'] > 0) {
      numberOfDistributions += 1;
    } else {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    rowData = [
      'CAJA MENUDA',
      gasto['title'],
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Date
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Date
      '2000',
      numberOfDistributions,
      '1',
      gasto['title'],
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // Description
      '', // G/L Account
      +gasto['total'] - +gasto['itbms'],
      +gasto['total'] - +gasto['itbms'],
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms']) {
      rowData = [
        'CAJA MENUDA',
        gasto['title'],
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // Date
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // Date
        '2000',
        numberOfDistributions,
        '1',
        gasto['title'],
        '"' + 'ITBMS de ' + gasto['descripcion'].replaceAll('"', '""') + '"', // Description
        '', // G/L Account
        +gasto['itbms'],
        +gasto['itbms'],
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_vertical_blob = (gastos) => {
  var providers = verticalProviders;

  var r;
  var numberOfDistributions = 0;
  for (r = 0; r < gastos.length; r++) {
    numberOfDistributions += 1;
    if (!isNaN(gastos[r]['itbms']) && gastos[r]['itbms'] > 0) {
      numberOfDistributions += 1;
    } else {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributionsThisExpense;
  var dueDate;
  var header = [
    'Vendor ID',
    'Vendor Name',
    'Invoice/CM #',
    'Date',
    'Due Date',
    'Accounts Payable Account',
    'Number of Distributions',
    'Invoice/CM Distribution',
    'Description',
    'G/L Account',
    'Amount',
    'Job ID',
    'Transaction Period',
    'Transaction Number',
  ].join(',');

  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    dueDate = addDays(gasto['fecha'], 30);
    var vendorId =
      gasto['ruc'] in providers
        ? providers[gasto['ruc']]
        : 'Proveedores No Recur';
    if (gasto['itbms']) {
      numberOfDistributionsThisExpense = 2;
    } else {
      numberOfDistributionsThisExpense = 1;
    }
    rowData = [
      // '"' + gasto['provider'].replace('"', '""') + '"',
      '"' + vendorId + '"', // 'VENDOR ID',
      ' ',
      gasto['receipt_number'],
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Date
      dueDate.split('/')[0] +
        '/' +
        dueDate.split('/')[1] +
        '/' +
        dueDate.split('/')[2].substring(2, 4), // due Date
      gasto['team'].split(' - ', 2)[1].trim(), // Accounts Payable Account
      numberOfDistributionsThisExpense,
      '1',
      /\d/.test(gasto['descripcion'].substring(0, 2))
        ? '"' + gasto['descripcion'].split(' ').splice(1).join(' ') + '"'
        : '"' + gasto['descripcion'] + '"', // Descripcion
      '"' + gasto['category'].includes(' ')
        ? gasto['category'].split(' ')[0]
        : gasto['category'] + '"', // Categoria segun Hero
      +gasto['total'] - +gasto['itbms'],
      /\d/.test(gasto['descripcion'].substring(0, 2))
        ? '"' +
          gasto['team'].split(' - ')[0] +
          ',' +
          gasto['descripcion'].split(' ', 2)[0].substring(0, 2) +
          '-' +
          gasto['descripcion'].split(' ', 2)[0].substring(2, 4) +
          '-' +
          gasto['descripcion'].split(' ', 2)[0].substring(4, 6) +
          ',' +
          gasto['descripcion'].split(' ', 2)[0].split('-')[1] +
          '"'
        : gasto['team'].split(' - ')[0],
      gasto['fecha'].split('/')[1], // Transaction Period
      i + 1, // Transaction Number
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms']) {
      rowData = [
        '"' + vendorId + '"', // 'VENDOR ID',
        ' ',
        gasto['receipt_number'],
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // Date
        dueDate.split('/')[0] +
          '/' +
          dueDate.split('/')[1] +
          '/' +
          dueDate.split('/')[2].substring(2, 4), // due Date
        gasto['team'].split(' - ', 2)[1].trim(), // Accounts Payable Account
        numberOfDistributionsThisExpense,
        '1',
        /\d/.test(gasto['descripcion'].substring(0, 2))
          ? '"' + gasto['descripcion'].split(' ').splice(1).join(' ') + '"'
          : '"' + gasto['descripcion'] + '"', // Descripcion
        '20201', // G/L Account
        +gasto['itbms'],
        /\d/.test(gasto['descripcion'].substring(0, 2))
          ? '"' +
            gasto['team'].split(' - ')[0] +
            ',' +
            gasto['descripcion'].split(' ', 2)[0].substring(0, 2) +
            '-' +
            gasto['descripcion'].split(' ', 2)[0].substring(2, 4) +
            '-' +
            gasto['descripcion'].split(' ', 2)[0].substring(4, 6) +
            ',' +
            gasto['descripcion'].split(' ', 2)[0].split('-')[1] +
            '"'
          : gasto['team'].split(' - ')[0],
        gasto['fecha'].split('/')[1], // Transaction Period
        i + 1, // Transaction Number
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_feduro_blob = (gastos) => {
  var csvRows = [];
  var itbmsRows = [];
  var header = [
    'Línea',
    'Campo2',
    'CO',
    'CTA',
    'SCTA',
    'DEP',
    'REG',
    'CAS',
    'CLTVEH',
    'EMP',
    'Campo11',
    'Campo12',
    'Campo13',
    'Campo14',
    'Campo15',
    'Campo16',
    'Débito',
    'Crédito',
    'Débito',
    'Crédito',
    'Campo21',
    'PROVEEDOR',
    'DESCRIPCION REF1',
    'REFERENCIA',
    'CAUSA',
    'FACTURA',
    'F. FACT',
  ].join(',');
  const departamentos = ['2102', '510', '511', '520', '521', 'CPG35', 'CL30'];
  const casas = ['PG', 'W1', 'CB', 'M5', 'H42'];
  csvRows.push(header);
  var department;
  var casa;
  var count = 1;
  var totalDebito = 0;
  for (var i = 0; i < gastos.length; i++) {
    var gasto = gastos[i];
    // department = gasto['gastante'].split(' - ')[0];
    department = '-';
    casa = '-';
    var debitoValue = +gasto['total'] - +gasto['itbms'];
    totalDebito += +gasto['total'];
    // look for each department in departamentos in description, if found make the department the one found
    for (var j = 0; j < departamentos.length; j++) {
      if (gasto['descripcion'].includes(departamentos[j])) {
        department = departamentos[j];
      }
    }
    // look for each casa in casas in description, if found make the department the one found
    for (var j = 0; j < casas.length; j++) {
      if (gasto['descripcion'].includes(casas[j])) {
        casa = casas[j];
      }
    }
    var row = [
      count, // Línea
      '', // Campo2
      '01', // CO
      gasto['category'] ? gasto['category'].split(' - ')[1] : '', // CTA
      '-', // SCTA
      department, // DEP
      department == '2102' || casa == 'H42' ? '1' : '-', // REG this should equal 1 if casa is H42 or department is 2102
      casa, // CAS
      '-', // CLTVEH
      '', // EMP
      '', // Campo11
      '', // Campo12
      '', // Campo13
      '', // Campo14
      '', // Campo15
      '', // Campo16
      debitoValue, // Débito
      '', // Crédito
      debitoValue, // Débito (second)
      '', // Crédito (second)
      '', // Campo21
      gasto['provider'] || '', // PROVEEDOR
      gasto['descripcion'] || '', // DESCRIPCION REF1
      'RUC.:' + gasto['ruc'], // REFERENCIA
      '', // CAUSA
      gasto['receipt_number'] || '', // FACTURA
      gasto['fecha'] || '', // F. FACT
    ].join(',');

    csvRows.push(row);
    count++;

    // check if row has ITBMS
    if (gasto['itbms']) {
      var itbmsRow = [
        count, // Línea
        '', // Campo2
        '01', // CO
        '01421', // CTA
        '-', // SCTA
        '-', // DEP
        '-', // REG
        '-', // CAS
        '-', // CLTVEH
        '', // EMP
        '', // Campo11
        '', // Campo12
        '', // Campo13
        '', // Campo14
        '', // Campo15
        '', // Campo16
        gasto['itbms'] || 0, // Débito
        '', // Crédito
        gasto['itbms'] || 0, // Débito (second)
        '', // Crédito (second)
        '', // Campo21
        gasto['provider'] || '', // PROVEEDOR
        'ITBMS', // DESCRIPCION REF1
        '', // REFERENCIA
        '', // CAUSA
        gasto['receipt_number'] || '', // FACTURA
        gasto['fecha'] || '', // F. FACT
      ].join(',');

      itbmsRows.push(itbmsRow);
      count++;
    }
  }

  var totalRow = [
    '', // Línea
    '', // Campo2
    '01', // CO
    '02195', // CTA
    '-', // SCTA
    '-', // DEP
    '-', // REG
    '-', // CAS
    '-', // CLTVEH
    '', // EMP
    '', // Campo11
    '', // Campo12
    '', // Campo13
    '', // Campo14
    '', // Campo15
    '', // Campo16
    0, // Débito
    totalDebito, // Crédito
    0, // Débito (second)
    totalDebito, // Crédito (second)
    '', // Campo21
    '', // PROVEEDOR
    '', // DESCRIPCION REF1
    '', // REFERENCIA
    '', // CAUSA
    '', // FACTURA
    '', // F. FACT
  ].join(',');

  // Combine main rows and ITBMS rows
  var allRows = csvRows.concat(itbmsRows);
  allRows.push(totalRow);

  var gastos_csv = allRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_barrio_blob = (gastos) => {
  var r;
  for (r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms'])) {
      gastos[r]['itbms'] = 0;
    }
  }
  var header = [
    'Numero de cuenta',
    'Cuenta mayor',
    'Debito',
    'Credito',
    'Comentarios',
    'Grupo Impositivo',
    'Norma de Reparto',
  ].join(',');
  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    rowData = [
      '"' + gasto['category'] ? gasto['category'] : '' + '"',
      '',
      +gasto['total'] - +gasto['itbms'],
      '',
      '"' +
        gasto['descripcion'].replaceAll('"', '""') +
        ' Factura: ' +
        gasto['receipt_number'] +
        ' Proveedor: ' +
        gasto['provider'].replaceAll('"', '""') +
        ' RUC: ' +
        gasto['ruc'] +
        '"',
      '',
      gasto['team'],
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] > 0) {
      rowData = [
        '203002',
        '',
        +gasto['itbms'],
        '',
        '"ITBMS de ' +
          gasto['descripcion'].replaceAll('"', '""') +
          ' Factura: ' +
          gasto['receipt_number'] +
          ' Proveedor: ' +
          gasto['provider'].replaceAll('"', '""') +
          ' RUC: ' +
          gasto['ruc'] +
          '"',
        'ITBMS',
        gasto['team'],
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_acelmar_blob = (gastos) => {
  var r;
  for (r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms'])) {
      gastos[r]['itbms'] = 0;
    }
  }
  var header = [
    'Cuenta de mayor/Código',
    'Cuenta de mayor/Nombre',
    'Debito',
    'Credito',
    'Comentarios',
    'Referencia',
    'Proyecto',
    'Proveedor',
    'Razón Social',
    'Concepto',
    'Tipo de Pago',
    'RUC',
    'DV',
    'Tipo de Anexo',
    'Concepto 72',
    'Concepto 94',
    'Tipo de Persona 72',
    'Tipo de Persona 94',
    'Compra de Bienes y Servicios',
  ].join(',');
  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    rowData = [
      '',
      '',
      +gasto['total'],
      '',
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"',
      gasto['receipt_number'],
      '',
      '',
      '"' + gasto['provider'].replaceAll('"', '""') + '"',
      '',
      '',
      gasto['ruc'],
      gasto['dv'],
      '',
      '',
      '',
      '',
      '',
      '',
    ].join(',');

    csvRows.push(rowData);
  }

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_asyr_blob = (gastos) => {
  var providers = asyrProviders;
  var header1 = Array.from({ length: 12 }, (_, i) => i + 1).join(',');

  var header2 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'DESCRIPTION',
    'GL ACCOUNT',
    'AMOUNT',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributionsThisExpense = 1;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    let provider =
      gasto['ruc'] in providers
        ? providers[gasto['ruc']]
        : 'Vendor id pendiente: ' + gasto['provider'];
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + provider.replaceAll('"', '""') + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE INVOICE',
        gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
        '2000000', // 'ACCOUNTS PAYABLE',
        numberOfDistributionsThisExpense, // '# DISTRIBUTION',
        'G', // 'DESCRIPTION',
        '', // 'GL ACCOUNT',
        +gasto['total'], // 'AMOUNT',
      ].join(',');
    } else {
      rowData = [
        '"' + provider.replaceAll('"', '""') + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE INVOICE',
        gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
        '2000000', // 'ACCOUNTS PAYABLE',
        numberOfDistributionsThisExpense, // '# DISTRIBUTION',
        'E', // 'DESCRIPTION',
        '', // 'GL ACCOUNT',
        +gasto['total'], // 'AMOUNT',
      ].join(',');
    }

    csvRows.push(rowData);
  }

  var gastos_csv = 'SEP=,\n' + header2 + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_two_oceans_blob = (gastos) => {
  var header1 = Array.from({ length: 12 }, (_, i) => i + 1).join(',');
  let providers = twoOceansProviders;
  var header2 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'GL ACCOUNT',
    'AMOUNT',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributionsThisExpense = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    let provider =
      gasto['ruc'] in providers
        ? providers[gasto['ruc']]
        : 'Vendor id pendiente: ' + gasto['provider'];
    if (gasto['itbms'] != 0) {
      numberOfDistributionsThisExpense = 2;
    } else {
      numberOfDistributionsThisExpense = 1;
    }
    rowData = [
      '"' + provider.replaceAll('"', '""') + '"', // 'VENDOR ID',
      gasto['receipt_number'], // 'INVOICE #',
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
      '202', // 'ACCOUNTS PAYABLE',
      numberOfDistributionsThisExpense, // '# DISTRIBUTION',
      gasto['category'] ? gasto['category'].split('-')[1] : '', // 'GL ACCOUNT',
      +gasto['total'] - +gasto['itbms'], // 'AMOUNT',
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + provider.replaceAll('"', '""') + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
        '202', // 'ACCOUNTS PAYABLE',
        numberOfDistributionsThisExpense, // '# DISTRIBUTION',
        '654', // 'GL ACCOUNT',
        +gasto['itbms'], // 'AMOUNT',
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = 'SEP=,\n' + header2 + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_flt_blob = (gastos) => {
  var header1 = Array.from({ length: 12 }, (_, i) => i + 1).join(',');

  var header2 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'DESCRIPTION',
    'GL ACCOUNT',
    'AMOUNT',
    'JOB ID',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributionsThisExpense = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    if (gasto['itbms'] != 0) {
      numberOfDistributionsThisExpense = 2;
    } else {
      numberOfDistributionsThisExpense = 1;
    }
    rowData = [
      '"' + gasto['team'] + '"', // 'VENDOR ID',
      gasto['receipt_number'], // 'INVOICE #',
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
      '11302', // 'ACCOUNTS PAYABLE',
      numberOfDistributionsThisExpense, // '# DISTRIBUTION',
      '"' +
        gasto['provider'].replaceAll('"', '""') +
        ' ' +
        gasto['ruc'].replaceAll('"', '""') +
        ' ' +
        gasto['descripcion'].replaceAll('"', '""') +
        '"',
      gasto['category'].split('-')[1], // 'GL ACCOUNT',
      +gasto['total'] - +gasto['itbms'], // 'AMOUNT',
      '', // 'JOB ID'
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + gasto['team'] + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
        '11302', // 'ACCOUNTS PAYABLE',
        numberOfDistributionsThisExpense, // '# DISTRIBUTION',
        '"ITBMS ' +
          gasto['provider'].replaceAll('"', '""') +
          ' ' +
          gasto['ruc'].replaceAll('"', '""') +
          ' ' +
          gasto['descripcion'].replaceAll('"', '""') +
          '"',
        '21209', // 'GL ACCOUNT',
        +gasto['itbms'], // 'AMOUNT',
        '', // 'JOB ID'
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = 'SEP=,\n' + header2 + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_ph_puntarena_blob = (gastos) => {
  var header1 = Array.from({ length: 12 }, (_, i) => i + 1).join(',');

  var header2 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'DESCRIPTION',
    'GL ACCOUNT',
    'AMOUNT',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributionsThisExpense = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    if (gasto['itbms'] != 0) {
      numberOfDistributionsThisExpense = 2;
    } else {
      numberOfDistributionsThisExpense = 1;
    }
    rowData = [
      '"' + gasto['provider'].replaceAll('"', '""') + '"', // 'VENDOR ID',
      gasto['receipt_number'], // 'INVOICE #',
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
      '2110', // 'ACCOUNTS PAYABLE',
      numberOfDistributionsThisExpense, // '# DISTRIBUTION',
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // 'DESCRIPTION',
      gasto['category'].split(' ')[0], // 'GL ACCOUNT',
      +gasto['total'] - +gasto['itbms'], // 'AMOUNT',
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + gasto['provider'].replaceAll('"', '""') + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
        '2110', // 'ACCOUNTS PAYABLE',
        numberOfDistributionsThisExpense, // '# DISTRIBUTION',
        'ITBMS',
        '6550', // 'GL ACCOUNT',
        +gasto['itbms'], // 'AMOUNT',
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = 'SEP=,\n' + header2 + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_ph_buenaventura_blob = (gastos) => {
  var header1 = Array.from({ length: 12 }, (_, i) => i + 1).join(',');

  var header2 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'DESCRIPTION',
    'GL ACCOUNT',
    'AMOUNT',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributionsThisExpense = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    if (gasto['itbms'] != 0) {
      numberOfDistributionsThisExpense = 2;
    } else {
      numberOfDistributionsThisExpense = 1;
    }
    rowData = [
      '"' + gasto['provider'].replaceAll('"', '""') + '"', // 'VENDOR ID',
      gasto['receipt_number'], // 'INVOICE #',
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
      '2110', // 'ACCOUNTS PAYABLE',
      numberOfDistributionsThisExpense, // '# DISTRIBUTION',
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // 'DESCRIPTION',
      gasto['category'].split(' ')[0], // 'GL ACCOUNT',
      +gasto['total'] - +gasto['itbms'], // 'AMOUNT',
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + gasto['provider'].replaceAll('"', '""') + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
        '2110', // 'ACCOUNTS PAYABLE',
        numberOfDistributionsThisExpense, // '# DISTRIBUTION',
        'ITBMS',
        '02-1000', // 'GL ACCOUNT',
        +gasto['itbms'], // 'AMOUNT',
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = 'SEP=,\n' + header2 + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_medicinas_generales_blob = (gastos) => {
  var r;
  var numberOfDistributions = 1;
  for (r = 0; r < gastos.length; r++) {
    numberOfDistributions += 1;
    if (!isNaN(gastos[r]['itbms']) && gastos[r]['itbms'] > 0) {
      numberOfDistributions += 1;
    } else {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    rowData = [
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Date
      gasto['title'], // Reference
      numberOfDistributions, // Number of Distributions
      '', // G/L Account
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // Description
      +gasto['total'] - +gasto['itbms'],
      '', // Job ID
    ].join(',');
    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms']) {
      rowData = [
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // Date
        gasto['title'], // Reference
        numberOfDistributions, // Number of Distributions
        '', // G/L Account
        '"' + 'ITBMS de ' + gasto['descripcion'].replaceAll('"', '""') + '"', // Description
        gasto['itbms'],
        '', // Job ID
      ].join(',');
      csvRows.push(rowData);
    }
  }

  // populate csv rows
  var gastos_csv =
    'SEP=,\n' +
    'Date,Reference,Number of Distributions,G/L Account,Description,Amount,Job ID\n' +
    csvRows.join('\n');

  const last_expense = gastos[gastos.length - 1];
  const counter_positive_row =
    '\n' +
    [
      last_expense['fecha'].split('/')[1] +
        '/' +
        last_expense['fecha'].split('/')[2] +
        '/' +
        last_expense['fecha'].split('/')[0].substring(2, 4), // Date
      gasto['title'], // Reference
      numberOfDistributions, // Number of Distributions
      '', // G/L Account
      'Contra Positiva', // Description
      gastos.reduce((total, gasto) => total - parseFloat(gasto.total), 0), // Amount
      '', // Job ID
    ].join(',');
  gastos_csv += counter_positive_row;
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_refripolar_blob = (gastos) => {
  let providers = refripolarProviders;

  var header = [
    '*BillNo',
    '*Supplier',
    '*BillDate',
    '*DueDate',
    'Terms',
    'Location',
    'Memo',
    '*Account',
    'LineDescription',
    'Tax',
    '*LineAmount',
    'Currency',
    'Tax Code',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    let provider =
      gasto['ruc'] in providers
        ? providers[gasto['ruc']]
        : 'Proveedor pendiente: ' + gasto['provider'];
    rowData = [
      gasto['receipt_number'],
      '"' + provider.replaceAll('"', '""') + '"', // Supplier
      gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0], // BillDate
      gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0], // DueDate
      '', // Terms
      '', // Location
      '', // Memo
      '"' + gasto['category'] ? gasto['category'] : '' + '"', // Account
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // LineDescription
      +gasto['itbms'], // Tax
      +gasto['total'], // LineAmount
      'USD', // Currrency
      '7%', // Tax Code
    ].join(',');

    csvRows.push(rowData);
  }

  var gastos_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_bahia_blob = (gastos) => {
  for (var r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms']) || gastos[r]['itbms'] <= 0) {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    //'No. Cuenta,Nombre de Cuenta,Codigo,Descripcion,Documento,Fecha,Proveedor,Monto,ITBMS,Total,ID Oficina,ID Area
    rowData = [
      gasto['category'].split('-')[1], // No. Cuenta
      gasto['category'].split('-')[0], // Nombre de Cuenta
      '', // Codigo
      '"' +
        gasto['provider'].replaceAll('"', '""') +
        ' ' +
        gasto['ruc'].replaceAll('"', '""') +
        ' ' +
        gasto['dv'] +
        ' ' +
        gasto['descripcion'].replaceAll('"', '""') +
        '"', // Descripcion
      gasto['receipt_number'], // Documento
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Fecha
      '"' + gasto['provider'].replaceAll('"', '""') + '"', // Proveedor
      +gasto['total'] - +gasto['itbms'], // Monto
      +gasto['itbms'], // ITBMS
      +gasto['total'], // Total
      '', // ID Oficina
      '', // ID Area
    ].join(',');
    csvRows.push(rowData);
  }

  var gastos_csv =
    'SEP=,\n' +
    'No. Cuenta,Nombre de Cuenta,Codigo,Descripcion,Documento,Fecha,Proveedor,Monto,ITBMS,Total,ID Oficina,ID Area\n' +
    csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);

  return gastos_blob;
};

export const get_mcgowen_sage50_blob = (gastos) => {
  let providers = mcgowenProviders;
  var header1 = Array.from({ length: 12 }, (_, i) => i + 1).join(',');
  var header2 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'Description',
    'GL ACCOUNT',
    'AMOUNT',
    'Job ID',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributionsThisExpense = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    let provider =
      gasto['ruc'] in providers
        ? providers[gasto['ruc']]
        : 'Vendor id pendiente: ' + gasto['provider'];
    if (gasto['itbms'] != 0) {
      numberOfDistributionsThisExpense = 2;
    } else {
      numberOfDistributionsThisExpense = 1;
    }
    rowData = [
      '"' + provider.replaceAll('"', '""') + '"', // 'VENDOR ID',
      gasto['receipt_number'], // 'INVOICE #',
      gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
      gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
      gasto['team'].split('-')[1], // 'ACCOUNTS PAYABLE',
      numberOfDistributionsThisExpense, // '# DISTRIBUTION',
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"', // Description
      gasto['category'] ? gasto['category'].split('-')[1] : '', // 'GL ACCOUNT',
      +gasto['total'] - +gasto['itbms'], // 'AMOUNT',
      '', // 'JOB ID',
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + provider.replaceAll('"', '""') + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
        gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
        gasto['team'].split('-')[1], // 'ACCOUNTS PAYABLE',
        numberOfDistributionsThisExpense, // '# DISTRIBUTION',
        '"' + 'ITBMS de ' + gasto['descripcion'].replaceAll('"', '""') + '"', // Description
        '214001', // 'GL ACCOUNT',
        +gasto['itbms'], // 'AMOUNT',
        '', // 'JOB ID',
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = 'SEP=,\n' + header2 + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_mcgowen_blob = (gastos) => {
  let providers = mcgowenProviders;

  // populate csv rows
  var csvRows = [];

  var i;
  var gasto;
  var rowData;
  var total_with_itbms = 0;
  var total_of_itbms = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    let provider =
      gasto['ruc'] in providers
        ? providers[gasto['ruc']]
        : 'Vendor id pendiente: ' + gasto['provider'];

    rowData = [
      gasto['category'] ? gasto['category'].split('-')[1] : '',
      '101',
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"',
      gasto['receipt_number'],
      gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Date
      '',
      '1',
      '1',
      +gasto['total'] - gasto['itbms'],
      gasto['itbms'],
      '"' + provider.replaceAll('"', '""') + '"', // 'VENDOR ID',
      gasto['ruc'],
      gasto['dv'],
      '',
      '',
    ].join(',');
    csvRows.push(rowData);
  }

  var gastos_csv =
    'SEP=,\n' +
    // 'No.,FECHA,FACTURA No.,RUC/CEDULA,DV,PROVEEDOR,DESCRIPCION,NO. EQUIPO,EQUIPO,NO. CATEGORIA,CATEGORIA,TARJETA DE HERO,SIN ITBMS,ITBMS,VALOR TOTAL\n' +
    'No. Cuenta,No. Transaccion,Descripcion,Documento,Fecha,No. Cuenta Proveedor,No. Oficina,No. Area,Monto,Itbms,Nombre del Proveedor,No. Identificacion,DV,Tipo de Persona,No. Caso\n' +
    csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_mcgowen_eskema_blob = (gastos) => {
  for (var r = 0; r < gastos.length; r++) {
    if (isNaN(gastos[r]['itbms']) || gastos[r]['itbms'] <= 0) {
      gastos[r]['itbms'] = 0;
    }
  }

  // populate csv rows
  var csvRows = [];

  var i;
  var gasto;
  var rowData;
  var total_with_itbms = 0;
  var total_of_itbms = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    total_with_itbms += parseFloat(gasto['total']);
    total_of_itbms += parseFloat(gasto['itbms']);
    rowData = [
      // i + 1,
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // Date
      gasto['receipt_number'],
      gasto['ruc'],
      gasto['dv'],
      '"' + gasto['provider'] + '"',
      '"' + gasto['descripcion'].replaceAll('"', '""') + '"',
      // gasto['team'] ? gasto['team'].split('-')[1] : '',
      // gasto['team'] ? gasto['team'].split('-')[0] : '',
      gasto['category'] ? gasto['category'].split('-')[1] : '',
      // '"' + gasto['category'] ? gasto['category'].split('-')[0] : '' + '"', // Categoria segun Hero
      // gasto['card_transaction'] ? 'Sí' : 'No',
      +gasto['total'] - gasto['itbms'],
      gasto['itbms'],
      // +gasto['total'],
    ].join(',');
    csvRows.push(rowData);
  }

  var gastos_csv =
    'SEP=,\n' +
    // 'No.,FECHA,FACTURA No.,RUC/CEDULA,DV,PROVEEDOR,DESCRIPCION,NO. EQUIPO,EQUIPO,NO. CATEGORIA,CATEGORIA,TARJETA DE HERO,SIN ITBMS,ITBMS,VALOR TOTAL\n' +
    'FECHA,FACTURA No.,RUC/CEDULA,DV,PROVEEDOR,DESCRIPCION,NO. CATEGORIA,SIN ITBMS,ITBMS\n' +
    csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_pgc_blob = (gastos) => {
  var header1 = Array.from({ length: 12 }, (_, i) => i + 1).join(',');

  var header2 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'Description',
    'GL ACCOUNT',
    'AMOUNT',
    'Job ID',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributions = 0;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    if (gasto['itbms'] != 0) {
      numberOfDistributions += 2;
    } else {
      numberOfDistributions += 1;
    }
  }
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    var vendorId =
      gasto['team'] && gasto['team'].split('- ').length >= 3
        ? gasto['team'].split('- ')[2]
        : '';
    var today = new Date();
    var expenseDate =
      gasto['fecha'].split('/')[2] +
      '/' +
      gasto['fecha'].split('/')[1] +
      '/' +
      gasto['fecha'].split('/')[0].substring(2, 4); // Date
    var invoiceNum =
      String(today.getMonth() + 1).padStart(2, '0') +
      String(today.getDate()).padStart(2, '0') +
      String(today.getFullYear()) +
      ' - CM ' +
      gasto['report_id'];
    var date =
      String(today.getMonth() + 1).padStart(2, '0') +
      '/' +
      String(today.getDate()).padStart(2, '0') +
      '/' +
      today.getFullYear();
    var description =
      gasto['provider'] +
      ' ' +
      gasto['ruc'] +
      ' DV ' +
      gasto['dv'] +
      ' FACT.' +
      gasto['receipt_number'] +
      ' ' +
      gasto['descripcion'] +
      ' ' +
      expenseDate;
    var category = gasto['category'] ? gasto['category'].split('_')[1] : '';
    var jobId =
      gasto['descripcion'] && gasto['descripcion'].split('_').length >= 2
        ? gasto['descripcion'].split('_')[1] + ',45% a 50% Costo'
        : '';
    rowData = [
      '"' + vendorId + '"', // 'VENDOR ID',
      invoiceNum, // 'INVOICE #',
      date, // 'DATE',
      date, // 'DATE DUE',
      '20001', // 'ACCOUNTS PAYABLE',
      numberOfDistributions, // '# DISTRIBUTION',
      '"' + description.replaceAll('"', '""') + '"', // Description
      '"' + category + '"', // 'GL ACCOUNT',
      +gasto['total'] - +gasto['itbms'], // 'AMOUNT',
      '"' + jobId + '"', // 'JOB ID',
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + vendorId + '"', // 'VENDOR ID',
        invoiceNum, // 'INVOICE #',
        date, // 'DATE',
        date, // 'DATE DUE',
        '20001', // 'ACCOUNTS PAYABLE',
        numberOfDistributions, // '# DISTRIBUTION',
        '"' + 'ITBMS de ' + description.replaceAll('"', '""') + '"', // Description
        '20170', // 'GL ACCOUNT',
        +gasto['itbms'], // 'AMOUNT',
        '"' + jobId + '"', // 'JOB ID',
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = header2 + '\r\n' + csvRows.join('\r\n') + '\r\n';
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_pca_cevicheria_blob = (gastos) => {
  let providers = pcaCevicheriaProviders;
  var header1 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'Description',
    'GL ACCOUNT',
    'AMOUNT',
    'Job ID',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributions = 0;

  const cevichOneOrTwo = gastos[0]['team'].includes('1') ? '1' : '2';

  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    if (gasto['itbms'] != 0) {
      numberOfDistributions = 2;
    } else {
      numberOfDistributions = 1;
    }
    // vendorId is obtained by looking for gasto['ruc'] in providers. if not found, return 'Proveedor Puente'
    var vendorId =
      gasto['ruc'] in providers
        ? providers[gasto['ruc']]
        : 'Proveedores No Recur';
    var date =
      gasto['fecha'].split('/')[1] +
      '/' +
      gasto['fecha'].split('/')[2] +
      '/' +
      gasto['fecha'].split('/')[0].substring(2, 4); // Date
    var description = gasto['descripcion'];
    var category = gasto['category'] ? gasto['category'].split('-')[1] : '';
    rowData = [
      '"' + vendorId + '"', // 'VENDOR ID',
      gasto['receipt_number'], // 'INVOICE #',
      date, // 'DATE',
      date, // 'DATE DUE',
      '2011100', // 'ACCOUNTS PAYABLE',
      numberOfDistributions, // '# DISTRIBUTION',
      '"' + description.replaceAll('"', '""') + '"', // Description
      '"' + category + '"', // 'GL ACCOUNT',
      +gasto['total'] - +gasto['itbms'], // 'AMOUNT',
      '"' + category + '-' + cevichOneOrTwo + '"', // 'JOB ID',
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + vendorId + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        date, // 'DATE',
        date, // 'DATE DUE',
        '2011100', // 'ACCOUNTS PAYABLE',
        numberOfDistributions, // '# DISTRIBUTION',
        '"' + 'ITBMS de ' + description.replaceAll('"', '""') + '"', // Description
        '2041130', // 'GL ACCOUNT',
        +gasto['itbms'], // 'AMOUNT',
        '', // 'JOB ID',
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = header1 + '\r\n' + csvRows.join('\r\n') + '\r\n';
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_pca_blueadventure_blob = (gastos) => {
  let providers = pcaBlueAdventureProviders;
  var header1 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'Description',
    'GL ACCOUNT',
    'AMOUNT',
    'Job ID',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var jobId;
  var numberOfDistributions = 0;

  // const cevichOneOrTwo = gastos[0]['team'].includes('1') ? '1' : '2';

  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    if (gasto['itbms'] != 0) {
      numberOfDistributions = 2;
    } else {
      numberOfDistributions = 1;
    }
    const jobIdExtra = gasto['category'].includes('Pescado')
      ? '-1'
      : gasto['category'].includes('Bebidas')
      ? '-2'
      : gasto['category'].includes('Comida')
      ? '-3'
      : gasto['category'].includes('Pescado Procesado')
      ? '-4'
      : '';

    // vendorId is obtained by looking for gasto['ruc'] in providers. if not found, return 'Proveedor Puente'
    var vendorId =
      gasto['ruc'] in providers
        ? providers[gasto['ruc']]
        : 'Proveedores No Recur';
    var date =
      gasto['fecha'].split('/')[1] +
      '/' +
      gasto['fecha'].split('/')[2] +
      '/' +
      gasto['fecha'].split('/')[0].substring(2, 4); // Date
    var description = gasto['descripcion'];
    var category = gasto['category']
      ? gasto['category'].substring(gasto['category'].indexOf('-') + 1).trim()
      : '';
    jobId = category.includes('5011100') ? category + jobIdExtra : '';
    rowData = [
      '"' + vendorId + '"', // 'VENDOR ID',
      gasto['receipt_number'], // 'INVOICE #',
      date, // 'DATE',
      date, // 'DATE DUE',
      '2011100', // 'ACCOUNTS PAYABLE',
      numberOfDistributions, // '# DISTRIBUTION',
      '"' + description.replaceAll('"', '""') + '"', // Description
      '"' + category + '"', // 'GL ACCOUNT',
      +gasto['total'] - +gasto['itbms'], // 'AMOUNT',
      '"' + jobId + '"', // 'JOB ID',
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + vendorId + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        date, // 'DATE',
        date, // 'DATE DUE',
        '2011100', // 'ACCOUNTS PAYABLE',
        numberOfDistributions, // '# DISTRIBUTION',
        '"' + 'ITBMS de ' + description.replaceAll('"', '""') + '"', // Description
        '2041130', // 'GL ACCOUNT',
        +gasto['itbms'], // 'AMOUNT',
        '', // 'JOB ID',
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = header1 + '\r\n' + csvRows.join('\r\n') + '\r\n';
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_pca_globallounge_blob = (gastos) => {
  let providers = pcaGlobalLoungeProviders;
  var header1 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'Description',
    'GL ACCOUNT',
    'AMOUNT',
    'Job ID',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributions = 0;
  var jobId;

  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    if (gasto['itbms'] != 0) {
      numberOfDistributions = 2;
    } else {
      numberOfDistributions = 1;
    }

    // vendorId is obtained by looking for gasto['ruc'] in providers. if not found, return 'Proveedor No Recur'
    var vendorId =
      gasto['ruc'] in providers
        ? providers[gasto['ruc']]
        : 'Proveedores No Recur';
    var date =
      gasto['fecha'].split('/')[1] +
      '/' +
      gasto['fecha'].split('/')[2] +
      '/' +
      gasto['fecha'].split('/')[0].substring(2, 4); // Date
    var description = gasto['descripcion'];
    var category = gasto['category'] ? gasto['category'].split('-')[1] : '';
    const jobIdExtra = gasto['category'].includes('Comidas-6110002')
      ? '-1'
      : gasto['category'].includes('Bebidas Alco')
      ? '-2'
      : gasto['category'].includes('bebidas sin Alcohol')
      ? '-3'
      : gasto['category'].includes('Servicios de Limpieza')
      ? '-1'
      : gasto['category'].includes('Suministros de Limpieza')
      ? '-2'
      : gasto['category'].includes('Honorarios Contabilidad')
      ? '-1'
      : gasto['category'].includes('Honorarios Legales')
      ? '-2'
      : '';
    jobId = jobIdExtra ? category + jobIdExtra : '';
    rowData = [
      '"' + vendorId + '"', // 'VENDOR ID',
      gasto['receipt_number'], // 'INVOICE #',
      date, // 'DATE',
      date, // 'DATE DUE',
      '2011100', // 'ACCOUNTS PAYABLE',
      numberOfDistributions, // '# DISTRIBUTION',
      '"' + description.replaceAll('"', '""') + '"', // Description
      '"' + category + '"', // 'GL ACCOUNT',
      +gasto['total'] - +gasto['itbms'], // 'AMOUNT',
      '', // 'JOB ID',
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + vendorId + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        date, // 'DATE',
        date, // 'DATE DUE',
        '2011100', // 'ACCOUNTS PAYABLE',
        numberOfDistributions, // '# DISTRIBUTION',
        '"' + 'ITBMS de ' + description.replaceAll('"', '""') + '"', // Description
        '2041130', // 'GL ACCOUNT',
        +gasto['itbms'], // 'AMOUNT',
        '', // 'JOB ID',
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = header1 + '\r\n' + csvRows.join('\r\n') + '\r\n';
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_nsolar_blob = (gastos) => {
  var header1 = Array.from({ length: 12 }, (_, i) => i + 1).join(',');
  let providers = nsolarProviders;
  var header2 = [
    'VENDOR ID',
    'INVOICE/CM #',
    'DATE',
    'DATE DUE',
    'ACCOUNTS PAYABLE ACCOUNT',
    'NUMBER OF DISTRIBUTIONS',
    'Description',
    'GL ACCOUNT',
    'AMOUNT',
    'Job ID',
  ].join(',');

  // populate csv rows
  var csvRows = [];
  var i;
  var gasto;
  var rowData;
  var numberOfDistributionsThisExpense = 0;
  var description;
  for (i = 0; i < gastos.length; i++) {
    gasto = gastos[i];
    let provider =
      gasto['ruc'] in providers
        ? providers[gasto['ruc']]
        : 'Vendor id pendiente: ' + gasto['provider'];
    if (gasto['itbms'] != 0) {
      numberOfDistributionsThisExpense = 2;
    } else {
      numberOfDistributionsThisExpense = 1;
    }
    description = gasto['descripcion'];
    rowData = [
      '"' + provider.replaceAll('"', '""') + '"', // 'VENDOR ID',
      gasto['receipt_number'], // 'INVOICE #',
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
      gasto['fecha'].split('/')[1] +
        '/' +
        gasto['fecha'].split('/')[2] +
        '/' +
        gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
      '212100', // 'ACCOUNTS PAYABLE',
      numberOfDistributionsThisExpense, // '# DISTRIBUTION',
      '"' + description.replaceAll('"', '""') + '"', // Description
      gasto['category'] ? gasto['category'].split('-')[1] : '', // 'GL ACCOUNT',
      +gasto['total'] - +gasto['itbms'], // 'AMOUNT',
      '', // 'JOB ID',
    ].join(',');

    csvRows.push(rowData);

    // check if row has ITBMS
    if (gasto['itbms'] != 0) {
      rowData = [
        '"' + provider.replaceAll('"', '""') + '"', // 'VENDOR ID',
        gasto['receipt_number'], // 'INVOICE #',
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE',
        gasto['fecha'].split('/')[1] +
          '/' +
          gasto['fecha'].split('/')[2] +
          '/' +
          gasto['fecha'].split('/')[0].substring(2, 4), // 'DATE DUE',
        '212100', // 'ACCOUNTS PAYABLE',
        numberOfDistributionsThisExpense, // '# DISTRIBUTION',
        '"' + 'ITBMS de ' + description.replaceAll('"', '""') + '"', // Description
        '611-405', // 'GL ACCOUNT'
        +gasto['itbms'], // 'AMOUNT'
        '', // 'JOB ID'
      ].join(',');
      csvRows.push(rowData);
    }
  }

  var gastos_csv = 'SEP=,\n' + header2 + '\n' + csvRows.join('\n');
  gastos_csv = tildeRemover(gastos_csv);
  const gastos_blob = new Blob(['\ufeff', gastos_csv]);
  return gastos_blob;
};

export const get_account_statement_blob = (statement_rows) => {
  var header = ['FECHA', 'DESCRIPCION', 'DEBITO', 'CREDITO', 'BALANCE'].join(
    ','
  );
  // populate csv rows
  var csvRows = [];
  var i;
  var statement_row;
  var rowData;
  for (i = 0; i < statement_rows.length; i++) {
    statement_row = statement_rows[i];
    rowData = [
      statement_row['date'], // 'DATE',
      '"' + statement_row['description'] + '"', // 'DESCRIPTION',
      statement_row['debit'] != '0.00' ? statement_row['debit'] : '', // 'DEBIT',
      statement_row['credit'] != '0.00' ? statement_row['credit'] : '', // 'CREDIT',
      statement_row['balance'], // 'BALANCE',
    ].join(',');

    csvRows.push(rowData);
  }

  var statement_csv = 'SEP=,\n' + header + '\n' + csvRows.join('\n');
  statement_csv = tildeRemover(statement_csv);
  const statement_blob = new Blob(['\ufeff', statement_csv]);
  return statement_blob;
};

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF'.split('');
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const formatPhoneNumber = (input) => {
  let countryFlag;
  let phoneNumber;

  if (input.startsWith('whatsapp:+507')) {
    countryFlag = '🇵🇦';
    phoneNumber = input.replace('whatsapp:+507', '');
  } else if (input.startsWith('whatsapp:+57')) {
    countryFlag = '🇨🇴';
    phoneNumber = input.replace('whatsapp:+57', '');
  } else if (input.startsWith('whatsapp:+1')) {
    countryFlag = '🇺🇸';
    phoneNumber = input.replace('whatsapp:+1', '');
  } else if (input.startsWith('whatsapp:+34')) {
    countryFlag = '🇪🇸';
    phoneNumber = input.replace('whatsapp:+34', '');
  } else if (input.startsWith('whatsapp:+55')) {
    countryFlag = '🇧🇷';
    phoneNumber = input.replace('whatsapp:+55', '');
  } else if (input.startsWith('whatsapp:+971')) {
    countryFlag = '🇦🇪';
    phoneNumber = input.replace('whatsapp:+971', '');
  } else if (input.startsWith('whatsapp:+58')) {
    countryFlag = '🇻🇪';
    phoneNumber = input.replace('whatsapp:+58', '');
  } else if (input.startsWith('whatsapp:+39')) {
    countryFlag = '🇮🇹';
    phoneNumber = input.replace('whatsapp:+39', '');
  } else if (input.startsWith('whatsapp:+44')) {
    countryFlag = '🇬🇧';
    phoneNumber = input.replace('whatsapp:+44', '');
  } else {
    return 'Número inválido';
  }

  return countryFlag + ' ' + phoneNumber;
};

export const matchExpensesAndCSV = (expenseList, csvData) => {
  function wrapInQuotes(cell) {
    return `"${cell.replace(/"/g, '""')}"`;
  }

  function normalizeString(str) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  function stringsAreSimilar(str1, str2) {
    const normStr1 = normalizeString(str1);
    const normStr2 = normalizeString(str2);
    return normStr1.includes(normStr2) || normStr2.includes(normStr1);
  }

  function getNormalizedIndex(arr, target) {
    return arr.findIndex((header) => stringsAreSimilar(header, target));
  }

  function excelDateToJSDate(excelDate) {
    const date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
    return date.toLocaleDateString();
  }

  function formatDate(dateString) {
    if (!dateString) {
      return ''; // or any other default value or throw an error
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  function generateDateKeys(amount, date) {
    const daysMs = 24 * 60 * 60 * 1000; // One day in milliseconds
    const dateDifferencePermittedMs = daysMs * 1; // Two days difference permitted
    const jsDate = new Date(date);

    const olderDateBoundary = new Date(
      jsDate.getTime() - dateDifferencePermittedMs
    );
    const laterDateBoundary = new Date(
      jsDate.getTime() + dateDifferencePermittedMs
    );

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    return [
      `${amount}-${formatDate(jsDate)}`,
      `${amount}-${formatDate(olderDateBoundary)}`,
      `${amount}-${formatDate(laterDateBoundary)}`,
    ];
  }

  const expenseMap = new Map();
  expenseList.forEach((expense) => {
    const absAmount = Math.abs(parseFloat(expense.total));
    const insertedDate = formatDate(expense.fecha); // Formatting inserted date
    const category = expense.category; // Assuming 'category' is the field name for category in expense object

    const key = `${absAmount}-${insertedDate}`;

    if (!expenseMap.has(key)) {
      expenseMap.set(key, []);
    }
    expenseMap
      .get(key)
      .push({ id: expense.id, category: category, used: false });
  });

  // console.log('Expense Map:', expenseMap); // Debugging the mapping

  const csvHeader = csvData[0];
  let csvRows = csvData.slice(1);

  const debitIndex =
    getNormalizedIndex(csvHeader, 'Debito') !== -1
      ? getNormalizedIndex(csvHeader, 'Debito')
      : getNormalizedIndex(csvHeader, 'cargos');
  const fechaIndex =
    getNormalizedIndex(csvHeader, 'Fecha') !== -1
      ? getNormalizedIndex(csvHeader, 'Fecha')
      : getNormalizedIndex(csvHeader, 'Fecha transacción');
  // console.log('fechaIndex:', fechaIndex);
  // Pre-process Fecha column with your function formatDatesList
  const unformattedDates = csvRows.map((row) => row[fechaIndex]);
  // console.log('Unformatted Dates:', unformattedDates);
  const formattedDates = formatDatesList(unformattedDates);
  // console.log('Formatted Dates:', formattedDates);
  csvRows = csvRows.map((row, index) => {
    // console.log(formattedDates[index]);
    row[fechaIndex] = formattedDates[index];
    return row;
  });

  let newCsvHeader = [...csvHeader, 'Expense ID', 'Categoria en Hero'];
  const expenseIDIndex = newCsvHeader.length - 2;
  const categoryIndex = newCsvHeader.length - 1; // Index for the new Category column
  const newCsvRows = csvRows.map((row) => {
    const descIndex = getNormalizedIndex(csvHeader, 'Descripcion');
    if (descIndex !== -1) {
      row[descIndex] = wrapInQuotes(row[descIndex]); // Escaping Descripcion
    }

    const debitAmountStr = row[debitIndex];

    // Log the Debito for each row for debugging
    // console.log(`Debito for current row: ${debitAmountStr}`);

    // Ignore the row if Debito is not a number
    if (isNaN(parseFloat(debitAmountStr))) {
      // console.log(`Skipping row because Debito is not a number.`);
      return [...row, ''];
    }

    const debitAmount = parseFloat(debitAmountStr);
    const absDebitAmount = Math.abs(debitAmount);
    const fecha = row[fechaIndex]; // Formatting Fecha
    const dateKeys = generateDateKeys(absDebitAmount, fecha);

    let chosenExpenseId = '';
    let chosenCategory = ''; // Variable to store the chosen category

    for (const key of dateKeys) {
      const matchingExpenseObjs = expenseMap.get(key) || [];
      for (let obj of matchingExpenseObjs) {
        if (!obj.used) {
          // console.log('Matching Expense:', obj);
          chosenExpenseId = obj.id;
          chosenCategory = obj.category; // Get the category of the matching expense
          obj.used = true;
          break;
        }
      }
      if (chosenExpenseId) break; // Exit the loop if a match was found
    }

    row[expenseIDIndex] = chosenExpenseId; // Set the Expense ID
    row[categoryIndex] = chosenCategory; // Set the Category

    return row;
  });

  const newCsvContent = [newCsvHeader, ...newCsvRows]
    .map((row) => row.join(','))
    .join('\n');

  const csvBlob = new Blob(['\ufeff', newCsvContent], {
    type: 'text/csv;charset=utf-8',
  });

  return csvBlob;
};

export const dateTransformer = (yyyy_mm_dd) => {
  let date = yyyy_mm_dd.split('-');
  return date[1] + '/' + date[2] + '/' + date[0][2] + date[0][3];
};

export const formatDateSpanishLong = (dateInput) => {
  const date = dateInput instanceof Date ? dateInput : new Date(dateInput); // Ensure date is a Date object

  if (isNaN(date)) {
    throw new Error('Invalid date');
  }

  const day = date.getUTCDate(); // Use getUTCDate() to get the day in UTC
  const monthNames = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];
  const month = monthNames[date.getUTCMonth()]; // Use getUTCMonth() to get the month in UTC
  const year = date.getUTCFullYear(); // Use getUTCFullYear() to get the year in UTC
  return `${day} de ${month} de ${year}`;
};

export const formatDateSpanishMed = (dateInput) => {
  // i want dates to be like 4 nov 2024, 4:53 pm
  const date = dateInput instanceof Date ? dateInput : new Date(dateInput); // Ensure date is a Date object

  if (isNaN(date)) {
    throw new Error('Invalid date');
  }

  const day = date.getUTCDate(); // Use getUTCDate() to get the day in UTC
  const monthNames = [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic',
  ];

  const month = monthNames[date.getUTCMonth()]; // Use getUTCMonth() to get the month in UTC
  const year = date.getUTCFullYear(); // Use getUTCFullYear() to get the year in UTC
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  return `${day} ${month} ${year}, ${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const calculateISRforThisPayroll = (
  total_comp_subject_to_isr_this_year_so_far,
  total_isr_deducted_this_year_so_far,
  payroll_payable_date,
  comp_subject_to_isr_this_payroll,
  comp_subject_to_ss_this_payroll,
  benefit_representation_expenses
) => {
  // Calculate the remaining payrolls in the year
  const date = new Date(payroll_payable_date);
  const currentMonth = date.getMonth() + 1; // getMonth is zero-indexed
  const remainingMonths = 12 - currentMonth;
  const remainingPayrollsThisMonth = date.getDate() <= 15 ? 2 : 1;
  let remainingPayrollsThisYear =
    remainingPayrollsThisMonth + remainingMonths * 2;

  // Add the remaining decimoTercerMesPayrolls in the year
  const decimoTercerMesDates = [
    new Date(date.getFullYear(), 3, 15), // April 15th
    new Date(date.getFullYear(), 7, 15), // August 15th
    new Date(date.getFullYear(), 11, 15), // December 15th
  ];
  const remainingDecimoTercerMesPayrolls = decimoTercerMesDates.filter(
    (d) => d >= date
  ).length;
  remainingPayrollsThisYear += remainingDecimoTercerMesPayrolls;
  // console.log(
  //   'remainingDecimoTercerMesPayrolls:',
  //   remainingDecimoTercerMesPayrolls
  // );
  // Calculate total compensation for the year
  const estimatedFutureRegularCompensation =
    comp_subject_to_isr_this_payroll *
    (remainingPayrollsThisYear - remainingDecimoTercerMesPayrolls);
  const estimatedFutureDecimoTercerMesCompensation =
    (2 / 3) *
    comp_subject_to_ss_this_payroll *
    remainingDecimoTercerMesPayrolls;
  const estimatedTotalCompensationThisYear =
    total_comp_subject_to_isr_this_year_so_far +
    estimatedFutureRegularCompensation +
    estimatedFutureDecimoTercerMesCompensation;

  // Calculate annual ISR based on the total compensation
  let estimatedTotalAnnualISR;
  if (estimatedTotalCompensationThisYear < 11000) {
    estimatedTotalAnnualISR = 0;
  } else if (
    estimatedTotalCompensationThisYear >= 11000 &&
    estimatedTotalCompensationThisYear < 50000
  ) {
    estimatedTotalAnnualISR =
      0.15 * (estimatedTotalCompensationThisYear - 11000);
  } else {
    estimatedTotalAnnualISR =
      0.15 * 39000 + 0.25 * (estimatedTotalCompensationThisYear - 50000);
  }

  // Use the provided accumulated ISR as face value
  const accumulatedISR = total_isr_deducted_this_year_so_far;

  // Calculate the ISR to be deducted for this payroll
  const totalRemainingISR = estimatedTotalAnnualISR - accumulatedISR;
  const isrForThisPayrollFromRepresentationExpenses =
    parseFloat(benefit_representation_expenses) * 0.1;
  const isrForThisPayroll =
    parseFloat((totalRemainingISR / remainingPayrollsThisYear).toFixed(2)) +
    isrForThisPayrollFromRepresentationExpenses;

  // if (estimatedTotalAnnualISR > 0) {
  //   console.log(
  //     'total_comp_subject_to_isr_this_year_so_far:',
  //     total_comp_subject_to_isr_this_year_so_far
  //   );
  //   console.log(
  //     'total_isr_deducted_this_year_so_far:',
  //     total_isr_deducted_this_year_so_far
  //   );
  //   console.log(
  //     'estimatedTotalCompensationThisYear:',
  //     estimatedTotalCompensationThisYear
  //   );
  //   console.log('totalRemainingISR:', totalRemainingISR);
  //   console.log('estimatedTotalAnnualISR:', estimatedTotalAnnualISR);
  //   console.log('remainingPayrollsThisYear:', remainingPayrollsThisYear);
  //   console.log('isrForThisPayroll:', isrForThisPayroll);
  // }

  return {
    total_comp_subject_to_isr_this_year_so_far,
    total_isr_deducted_this_year_so_far,
    estimatedTotalCompensationThisYear,
    estimatedTotalAnnualISR,
    totalRemainingISR,
    remainingPayrollsThisYear,
    isrForThisPayrollFromRepresentationExpenses,
    isrForThisPayroll,
  };
};

export const sourceMapping = {
  bot_expenses: '📱',
  dgi_docs_emitidos_rows: '🧾E',
  dgi_docs_recibidos_rows: '🧾R',
  card: '💳',
  payroll: '👥',
  bank: '🏦',
  cashflow: '☎️',
};
