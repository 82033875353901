// FETable.js

import { useContext, useState, useEffect, useMemo } from 'react';
import {
  Button,
  Col,
  Input,
  Layout,
  Table,
  message,
  Row,
  Modal,
  notification,
  Space,
  Checkbox,
} from 'antd';
import {
  SearchOutlined,
  DownloadOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons';
import styled from 'styled-components/macro';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import moment from 'moment';
import { convertToIntDateFormat } from '../utils.js';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { get_moments_from_month_name } from '../utils.js';
import AccountingAccountSelect from './Accounting/AccountingAccountSelect';
import HeroAxios from '../helpers/HeroAxios.js';
import * as XLSX from 'xlsx'; // Import XLSX for CSV export
import FEbuttonAndModal from './FEbuttonAndModal';
import MassEditButtonAndModalForFEs from './MassEditButtonAndModalForFEs';
import { Refresh } from './Refresh.js';
import RelationshipModalV2 from './RelationshipModalV2';
import { sourceMapping } from '../utils.js';
import TransactionCreatorModal from './Accounting/TransactionCreatorModal';
import EngineButton from './EngineButton.js';

const { Content } = Layout;

function FETable(props) {
  const [fes, setFes] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedFEs, setSelectedFEs] = useState([]);
  const [isCommitModalVisible, setIsCommitModalVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [includeItbms, setIncludeItbms] = useState(false);
  const [selectedItbmsAccount, setSelectedItbmsAccount] = useState(null);
  const [latestUpdateFEsRecibidas, setLatestUpdateFEsRecibidas] =
    useState(null);
  const [latestUpdateFEsEmitidas, setLatestUpdateFEsEmitidas] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [autoClassifyLoading, setAutoClassifyLoading] = useState(false);

  const [relationshipModalVisible, setRelationshipModalVisible] =
    useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState(null);
  const [selectedRecordForRelationship, setSelectedRecordForRelationship] =
    useState(null);
  const [isTransactionModalVisible, setIsTransactionModalVisible] =
    useState(false);
  const [selectedMovement, setSelectedMovement] = useState(null);

  const auth = useContext(authContext);

  const [currentFilters, setCurrentFilters] = useState({
    document_type: null,
    emission_date: null,
    cufe: null,
    emissor: null,
    receiver: null,
    committed: null,
    related: null,
  });

  const fetchFEs = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'getFEs',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        docs_type: props.type,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const transformedData = response.data.fe_list.map((item) => ({
            ...item,
            committed: item.committed !== undefined ? item.committed : false,
            related: item.related !== undefined ? item.related : false,
            related_url: item.related_url || null,
          }));
          setFes(transformedData);
          setFilteredData(transformedData);
          setLatestUpdateFEsEmitidas(response.data.latest_update_emitidos);
          setLatestUpdateFEsRecibidas(response.data.latest_update_recibidos);
        } else {
          message.error('Failed to fetch fes');
        }
      })
      .catch((error) => {
        console.error('Error fetching fes:', error);
        message.error('Error consiguiendo las facturas electrónicas');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFEs();
  }, [props.API_domain, props.type, auth.email, auth.token, refreshData]);

  // Function to handle account change
  const handleAccountChange = (record, value = record.account_id) => {
    // Call the backend to update the record
    axios({
      method: 'post',
      url: props.API_domain + 'updateFEAccount',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        doc_type: props.type,
        doc_id: record.id,
        account_id: value,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          message.success('Cuenta actualizada correctamente');
          const relatedMovements =
            response.data.amount_of_related_movements_reclassified;
          if (relatedMovements > 0) {
            message.info(
              `Se sincronizó${
                relatedMovements > 1 ? 'n' : ''
              } ${relatedMovements} movimiento${
                relatedMovements > 1 ? 's' : ''
              } relacionado${relatedMovements > 1 ? 's.' : '.'}`
            );
          }
          // Update the fes state to reflect the change
          setFes((prevFes) =>
            prevFes.map((item) =>
              item.id === record.id
                ? { ...item, account_id: value, account_id_confidence: 1 }
                : item
            )
          );
        } else {
          message.error('Error al actualizar la cuenta');
        }
      })
      .catch((error) => {
        console.error('Error updating account:', error);
        message.error('Error al actualizar la cuenta');
      });
  };

  // Compute unique document_type values for filters
  const documentTypeFilters = useMemo(() => {
    const documentTypesSet = new Set();
    fes.forEach((fe) => {
      if (fe.document_type) {
        documentTypesSet.add(fe.document_type);
      }
    });
    const filters = Array.from(documentTypesSet).map((id) => ({
      text: id.toString(),
      value: id.toString(),
    }));
    return filters;
  }, [fes]);

  const openRelationshipModal = (record, relationship) => {
    setSelectedRecordForRelationship(record);
    setSelectedRelationship(relationship);
    setRelationshipModalVisible(true);
  };

  const columns = [
    {
      title: 'CUFE',
      dataIndex: 'cufe',
      key: 'cufe',
      width: 240,
      render: (text, record) => (
        <FEbuttonAndModal
          numero_factura={text}
          API_domain={props.API_domain}
          auth={auth}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Buscar CUFE`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type='primary'
              onClick={confirm}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={clearFilters} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const searchText = value.toLowerCase();
        return record.cufe.toLowerCase().includes(searchText);
      },
      filteredValue: currentFilters.cufe || null,
    },
    ...(props.type === 'fe_recibidas'
      ? [
          {
            title: 'Emisor',
            key: 'emissor',
            width: 400,
            render: (text, record) => (
              <div>
                <div>{record.emissor_name}</div>
                <div style={{ fontSize: '12px', color: '#888' }}>
                  {record.emissor_id}
                </div>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Buscar emisor o RUC`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={confirm}
                  style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type='primary'
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Buscar
                  </Button>
                  <Button
                    onClick={clearFilters}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            onFilter: (value, record) => {
              const searchText = value.toLowerCase();
              const emissorName = record.emissor_name
                ? record.emissor_name.toLowerCase()
                : '';
              const emissorId = record.emissor_id
                ? record.emissor_id.toString()
                : '';
              return (
                emissorName.includes(searchText) ||
                emissorId.includes(searchText)
              );
            },
            filteredValue: currentFilters.emissor || null,
          },
        ]
      : []),
    ...(props.type === 'fe_emitidas'
      ? [
          {
            title: 'Receptor',
            key: 'receiver',
            width: 360,
            render: (text, record) => (
              <div>
                <div>{record.receiver_name}</div>
                <div style={{ fontSize: '12px', color: '#888' }}>
                  {record.receiver_id !== 'missing' ? record.receiver_id : ''}
                </div>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Buscar receptor o RUC`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={confirm}
                  style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type='primary'
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Buscar
                  </Button>
                  <Button
                    onClick={clearFilters}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            onFilter: (value, record) => {
              const searchText = value.toLowerCase();
              const receiverName = record.receiver_name
                ? record.receiver_name.toLowerCase()
                : '';
              const receiverId = record.receiver_id
                ? record.receiver_id.toString()
                : '';
              return (
                receiverName.includes(searchText) ||
                receiverId.includes(searchText)
              );
            },
            filteredValue: currentFilters.receiver || null,
          },
        ]
      : []),
    {
      title: 'Fecha de Emisión',
      dataIndex: 'emission_date',
      key: 'emission_date',
      width: 240,
      sorter: (a, b) =>
        moment(a.emission_date).unix() - moment(b.emission_date).unix(),
      render: (text) =>
        convertToIntDateFormat(moment(text).format('YYYY-MM-DD')),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const emissionDate = moment(record.emission_date);

        if (Array.isArray(value) && value.length === 2) {
          const [start, end] = value;
          return emissionDate.isBetween(start, end, 'day', '[]');
        } else if (typeof value === 'string') {
          const filterDates = get_moments_from_month_name(value);
          return emissionDate.isBetween(
            filterDates[0],
            filterDates[1],
            'day',
            '[]'
          );
        }

        return false;
      },
      filteredValue: currentFilters.emission_date || null, // Bind filteredValue
    },
    ...(auth.adminEmail
      ? [
          {
            title: 'Tipo de documento 🦸‍♂️',
            dataIndex: 'document_type',
            key: 'document_type',
            width: 160,
            filters: documentTypeFilters,
            onFilter: (value, record) =>
              record.document_type.toString() === value,
            render: (text) => text,
            filteredValue: currentFilters.document_type || null,
          },
        ]
      : []),
    {
      title: 'ITBMS',
      dataIndex: 'itbms',
      key: 'itbms',
      render: (value) =>
        value !== undefined ? `$${value.toFixed(2)}` : '$0.00',
      sorter: (a, b) => (a.itbms || 0) - (b.itbms || 0),
      align: 'right',
      filters: Array.from(new Set(fes.map((fe) => fe.itbms)))
        .sort((a, b) => a - b)
        .map((itbms) => ({
          text: `$${itbms.toFixed(2)}`,
          value: itbms,
        })),
      onFilter: (value, record) => record.itbms === value,
      filteredValue: currentFilters.itbms || null,
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => (
        <div style={{ textAlign: 'right' }}>
          {value !== undefined ? `$${value.toFixed(2)}` : '$0.00'}
        </div>
      ),
      sorter: (a, b) => (a.amount || 0) - (b.amount || 0),
      align: 'right',
      filters: Array.from(new Set(fes.map((fe) => fe.amount)))
        .sort((a, b) => a - b)
        .map((amount) => ({
          text: `$${amount.toFixed(2)}`,
          value: amount,
        })),
      onFilter: (value, record) => record.amount === value,
      filteredValue: currentFilters.amount || null,
    },
    {
      title: 'Cuenta',
      dataIndex: 'account_id',
      key: 'account_id',
      width: 400,
      render: (text, record) => (
        <div onClick={(e) => e.stopPropagation()}>
          <AccountingAccountSelect
            value={record.account_id}
            account_id_confidence={record.account_id_confidence}
            onChange={(value) => handleAccountChange(record, value)}
            clientId={props.clientId}
            API_domain={props.API_domain}
            auth={auth}
            accounts={props.clientAccounts}
            disabled={record.committed || !auth.adminEmail}
            showThumbUp={
              record.account_id_confidence < 1 &&
              record.account_id_confidence > 0 &&
              !record.committed
            }
          />
        </div>
      ),
    },
    {
      title: 'Relación',
      dataIndex: 'relationship',
      key: 'relationship',
      width: 200,
      render: (relationship, record) => {
        if (relationship && relationship.length > 0) {
          return relationship
            .filter(
              (rel) =>
                rel.type_of_mm !== 'entries' &&
                rel.movement_table_name !== 'entries'
            )
            .map((rel, index) => {
              const relatedMovementSource =
                rel.type_of_mm || rel.movement_table_name;
              const beautifulName =
                sourceMapping[relatedMovementSource] || relatedMovementSource;
              return (
                <span
                  key={index}
                  style={{
                    color: 'var(--purple-dark)',
                    cursor: 'pointer',
                    // display: 'block',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    transition: 'background-color 0.3s, color 0.3s',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openRelationshipModal(record, rel);
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = 'var(--purple-light)';
                    e.target.style.color = 'white';
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = 'transparent';
                    e.target.style.color = 'var(--purple-dark)';
                  }}
                >
                  {beautifulName}
                </span>
              );
            });
        } else {
          return ''; // Or display 'No relationship' if preferred
        }
      },
      filters: [
        ...Array.from(
          new Set(
            fes
              .filter(
                (fe) =>
                  fe.relationship &&
                  fe.relationship.length > 0 &&
                  fe.relationship.some(
                    (rel) =>
                      rel.type_of_mm !== 'entries' &&
                      rel.movement_table_name !== 'entries'
                  )
              )
              .flatMap((fe) =>
                fe.relationship
                  .filter(
                    (rel) =>
                      rel.type_of_mm !== 'entries' &&
                      rel.movement_table_name !== 'entries'
                  )
                  .map((rel) => rel.type_of_mm || rel.movement_table_name)
              )
          )
        ).map((type) => {
          const beautifulName = sourceMapping[type] || type;
          return { text: beautifulName, value: type };
        }),
        { text: ' ', value: 'no_relationship' },
      ],
      onFilter: (value, record) => {
        if (value === 'no_relationship') {
          return !record.relationship || record.relationship.length === 0;
        }
        const relationship = record.relationship;
        if (relationship && relationship.length > 0) {
          return relationship.some(
            (rel) =>
              rel.type_of_mm === value || rel.movement_table_name === value
          );
        } else {
          return false;
        }
      },
      filteredValue: currentFilters.relationship || null,
    },
    ...(auth.adminEmail
      ? [
          {
            title: '🦸‍♂️',
            dataIndex: 'committed',
            key: 'committed',
            width: 80,
            filters: [
              { text: 'Committed', value: true },
              { text: 'Not Committed', value: false },
            ],
            filterMultiple: false,
            onFilter: (value, record) => record.committed === value,
            filterIcon: (filtered) => (
              <CheckSquareOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            render: (committed, record) => (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {committed ? (
                  <span
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents row click event
                      openTransactionModal(record);
                    }}
                    style={{
                      color: 'var(--purple-dark)',
                      cursor: 'pointer',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      transition: 'background-color 0.3s, color 0.3s',
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = 'var(--purple-light)';
                      e.target.style.color = 'white';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = 'transparent';
                      e.target.style.color = 'var(--purple-dark)';
                    }}
                  >
                    {'✅'}
                  </span>
                ) : (
                  ''
                )}
              </span>
            ),
            filteredValue: currentFilters.committed || null,
          },
        ]
      : []),
  ];

  // Function to export table data to CSV
  const exportToCSV = () => {
    if (!filteredData || filteredData.length === 0) {
      message.warning('No hay datos para exportar.');
      return;
    }

    // Define headers based on columns
    const headers = ['CUFE'];

    if (props.type === 'fe_recibidas') {
      headers.push('Emisor Nombre', 'Emisor RUC');
    } else if (props.type === 'fe_emitidas') {
      headers.push('Receptor Nombre', 'Receptor RUC');
    }

    headers.push('Fecha de Emisión', 'Tipo de documento', 'ITBMS', 'Total');

    // Map data to rows
    const data = filteredData.map((item) => {
      const row = [item.cufe];

      if (props.type === 'fe_recibidas') {
        row.push(item.emissor_name, item.emissor_id);
      } else if (props.type === 'fe_emitidas') {
        row.push(
          item.receiver_name,
          item.receiver_id !== 'missing' ? item.receiver_id : ''
        );
      }

      row.push(moment(item.emission_date).format('YYYY-MM-DD'));
      row.push(item.document_type);
      row.push(item.itbms !== undefined ? `${item.itbms.toFixed(2)}` : '0.00');
      row.push(
        item.amount !== undefined ? `${item.amount.toFixed(2)}` : '0.00'
      );

      return row;
    });

    // Combine headers and data
    const csvData = [headers, ...data];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(csvData);

    // Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'FEs');

    // Generate a binary string representation of the workbook
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });

    // Create a link to download the Blob
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'FEs.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedFEs(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleCommit = () => {
    if (selectedFEs.length === 0) {
      message.warning('No hay facturas seleccionadas para confirmar.');
      return;
    }
    setIsCommitModalVisible(true);
  };

  const handleCommitConfirm = () => {
    if (!selectedAccount) {
      message.warning('Por favor seleccione una cuenta.');
      return;
    }

    if (includeItbms && !selectedItbmsAccount) {
      message.warning('Por favor seleccione una cuenta de ITBMS.');
      return;
    }

    axios({
      method: 'post',
      url: props.API_domain + 'commitFEs',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        fe_ids: selectedFEs.map((fe) => fe.id),
        account_id: selectedAccount,
        itbms_account_id: includeItbms ? selectedItbmsAccount : null,
        include_itbms: includeItbms,
        doc_type: props.type,
        client_id: props.clientId,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          message.success('Facturas confirmadas correctamente.');
          // Optionally update the fes state or refetch data
          setSelectedRowKeys([]);
          setSelectedFEs([]);
          setIsCommitModalVisible(false);
          setSelectedAccount(null);
          setIncludeItbms(false);
          setSelectedItbmsAccount(null);
        } else {
          message.error('Error al confirmar las facturas.');
        }
      })
      .catch((error) => {
        console.error('Error committing FEs:', error);
        message.error('Error al confirmar las facturas.');
      });
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    setCurrentFilters(filters);
    if (extra && extra.currentDataSource) {
      setFilteredData(extra.currentDataSource);
    } else {
      setFilteredData(fes);
    }
  };

  const openTransactionModal = (record) => {
    const movement_table_name =
      props.type === 'fe_recibidas'
        ? 'dgi_docs_recibidos_rows'
        : 'dgi_docs_emitidos_rows';
    setSelectedMovement({
      movement_table_name: movement_table_name, // e.g., 'dgi_docs_emitidos_row' or 'dgi_docs_recibidos_row'
      movement_id: record.id,
    });
    setIsTransactionModalVisible(true);
  };

  const handleAutoClassify = () => {
    setAutoClassifyLoading(true);

    HeroAxios({
      method: 'post',
      url: 'autoClassifyFes',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        client_id: props.clientId,
      },
    })
      .then((response) => {
        setAutoClassifyLoading(false);
        if (response.status === 200 && response.data.success) {
          notification.success({
            message: 'Autoclasificación completada',
            description: `Se clasificaron ${response.data.number_classified} / ${response.data.number_expenses_to_classify} facturas electrónicas.`,
            placement: 'topRight',
          });
          message.success('Facturas electrónicas clasificadas correctamente.');
          fetchFEs();
        }
      })
      .catch((error) => {
        setAutoClassifyLoading(false);
        console.error('Error auto-classifying expenses:', error);

        // Check if the error response exists
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          // Handle specific error messages from the backend
          if (
            error.response.data.error ===
            'Not enough fes with accounts to use the model'
          ) {
            message.error(
              'Necesitas al menos 10 facturas electrónicas clasificadas manualmente para usar el modelo.'
            );
          } else {
            message.error(
              'Error al clasificar las facturas electrónicas: ' +
                error.response.data.error
            );
          }
        } else {
          // Handle generic errors
          message.error(
            'Error al clasificar las facturas electrónicas: ' + error.message
          );
        }
      });
  };

  return (
    <Content
      style={{
        overflow: 'initial',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv style={{ maxWidth: '1400px', margin: 'auto' }}>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <Refresh
              onClick={() => {
                fetchFEs();
              }}
              spin={loading}
            />
            <div
              style={{
                color: 'var(--grey-dark)',
                marginLeft: 16,
                paddingTop: 24,
              }}
            >
              {latestUpdateFEsRecibidas || latestUpdateFEsEmitidas ? (
                <span style={{ marginLeft: 16 }}>
                  Actualización más reciente:{' '}
                  {props.type === 'fe_recibidas'
                    ? latestUpdateFEsRecibidas
                    : latestUpdateFEsEmitidas}
                </span>
              ) : null}
            </div>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {auth.adminEmail && (
              <>
                <EngineButton
                  text='Autoclasificar 🪄 🦸‍♂️'
                  disabled={autoClassifyLoading}
                  loading={autoClassifyLoading}
                  onClick={handleAutoClassify}
                  popOverContent={
                    <>
                      <div>
                        Se autoclasificarán todos los gastos que no tengan
                        cuenta contable asignada.
                      </div>
                      <br></br>
                      <div>
                        Se necesitan al menos 10 facturas electrónicas con
                        cuenta contable asignada para que la magia funcione 🪄.
                      </div>
                      <br></br>
                      <div>
                        Se usará de referencia:
                        <ol>
                          <li>Todas las cuentas contables</li>
                          <li>
                            Todas las facturas electrónicas que ya tienen cuenta
                            contable
                          </li>
                        </ol>
                      </div>
                      <div>⌛ Demorará unos segundos.</div>
                      <br></br>
                      <div>
                        Cuidado: por ahora se usan todos las facturas
                        electrónicas categorizados, sin importar la confianza en
                        la clasificación.
                      </div>
                      <br></br>
                      <div style={{ fontWeight: 500 }}>
                        Si una FE está relacionada a un gasto de bot
                        clasificado, se usará la cuenta contable del bot.
                      </div>
                    </>
                  }
                />
                <MassEditButtonAndModalForFEs
                  API_domain={props.API_domain}
                  selectedFEs={selectedFEs}
                  setSelectedFEs={setSelectedFEs}
                  clientAccounts={props.clientAccounts}
                  type={props.type}
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}
                />
                <Button
                  type='primary'
                  onClick={handleCommit}
                  disabled={selectedFEs.length === 0}
                  style={{ marginRight: 8 }}
                >
                  Commit 🦸‍♂️
                </Button>
              </>
            )}
            <Button
              type='secondary'
              icon={<DownloadOutlined />}
              onClick={exportToCSV}
            >
              Excel
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={fes}
          rowKey='id'
          loading={loading}
          pagination={{ pageSize: 100 }}
          rowSelection={auth.adminEmail ? rowSelection : null}
          style={{ margin: 'auto' }}
          onChange={onTableChange}
          onRow={(record) => ({
            onClick: () => {
              console.log('Row clicked:', record);
            },
            style: { cursor: 'pointer' }, // Change cursor to pointer
          })}
        />
      </AffixDiv>
      <Modal
        title='Seleccione una cuenta para confirmar'
        visible={isCommitModalVisible}
        onOk={handleCommitConfirm}
        onCancel={() => {
          setIsCommitModalVisible(false);
          setSelectedAccount(null);
          setIncludeItbms(false);
          setSelectedItbmsAccount(null);
        }}
      >
        <div style={{ marginBottom: 16 }}>
          <div>Cuenta:</div>
          <AccountingAccountSelect
            value={selectedAccount}
            onChange={(value) => setSelectedAccount(value)}
            clientId={props.clientId}
            API_domain={props.API_domain}
            auth={auth}
            accounts={props.clientAccounts}
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          <Checkbox
            checked={includeItbms}
            onChange={(e) => setIncludeItbms(e.target.checked)}
          >
            ITBMS
          </Checkbox>
        </div>
        {includeItbms && (
          <div>
            <div>Cuenta de ITBMS:</div>
            <AccountingAccountSelect
              value={selectedItbmsAccount}
              onChange={(value) => setSelectedItbmsAccount(value)}
              clientId={props.clientId}
              API_domain={props.API_domain}
              auth={auth}
              accounts={props.clientAccounts}
            />
          </div>
        )}
      </Modal>
      {relationshipModalVisible && (
        <RelationshipModalV2
          visible={relationshipModalVisible}
          onCancel={() => setRelationshipModalVisible(false)}
          relationship={selectedRelationship}
          setRecords={setFes}
          selectedRecordForRelationship={selectedRecordForRelationship}
          setRelationshipModalVisible={setRelationshipModalVisible}
          API_domain={props.API_domain}
          accounting_clients_id={props.clientId}
          tableOG={
            props.type === 'fe_recibidas'
              ? 'dgi_docs_recibidos_rows'
              : 'dgi_docs_emitidos_rows'
          }
        />
      )}
      <TransactionCreatorModal
        isVisible={isTransactionModalVisible}
        onCancel={() => setIsTransactionModalVisible(false)}
        prepareTransactionEndpoint={'getTransactionDataForMovement'}
        transactionCreationEndpoint={'editTransaction'}
        initialPayload={{
          movement_table_name: selectedMovement?.movement_table_name,
          movement_id: selectedMovement?.movement_id,
        }}
        API_domain={props.API_domain}
        auth={auth}
        clientId={props.clientId}
        onTransactionCreated={() => {
          message.success('Transacción editada exitosamente');
          // Optionally, refresh data here
        }}
      />
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

export { FETable };
